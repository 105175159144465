import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {Dialog, Table, TableBody, TableRow, TextField} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import {isEmpty, isNumber} from "lodash";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {get, getErrorMessage, PATH_GET_OLD_ORDERS} from "../../common/backend";
import {toast} from "react-hot-toast";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import {addToCart, findItemFromOldOrder, nearestCount} from "../../common/cart";
import {useTranslation} from "react-i18next";
import {HourglassFull} from "@mui/icons-material";
import {useAuth} from "../../common/auth/AuthContext";

const OldOrders = ({availableItems, sku}) => {
    const {t} = useTranslation()
    const {user, authorized} = useAuth()
    const [open, setOpen] = useState(false)

    const [orders, setOrders] = useState(null)
    const items = useRef([])

    useEffect(() => {
        if (!authorized || !isNumber(user.print_id) || isEmpty(availableItems)) {
            return
        }
        get(PATH_GET_OLD_ORDERS, res => {
                if (res.data.length > 0) {
                    res.data.forEach((it, i) => {
                        items.current[i] = findItemFromOldOrder(it, availableItems)
                    })
                    setOrders(res.data)
                }
            }, res => toast.error(getErrorMessage(res)), {catalog_number: sku}
        )
        return () => {
            items.current = []
            setOrders(null)
        };
    }, [authorized, availableItems])

    const closeWindow = () => {
        setOpen(false)
        items.current = []
    }

    const openWindow = () => {
        setOpen(true)
    }

    const updateCount = (e, i) => {
        let newCount = parseInt(e.target.value)
        if (isNaN(newCount)) {
            return
        }
        let target = items.current[i]
        if (!target) {
            return
        }
        if (target.box_count) {
            newCount = nearestCount(newCount, target.box_count)
        }
        target.buy_count = newCount
        e.target.value = newCount
    }

    const add = i => {
        const target = items.current[i]
        if (!target) {
            toast.error(t('item_not_found_name'))
            return;
        }
        if (!target.buy_count) {
            toast.error(t('choose_count'))
            return
        }
        addToCart([target], false)
        toast.success(t('done'))
    }

    if (orders === null) {
        return [<div key='empty'/>]
    }

    return [
        <div key='button' onClick={openWindow} aria-disabled={(user || {print_id: null}).print_id !== null}
             style={{cursor: 'pointer', marginLeft: '0px'}} className="row card--order-btn">
            <HourglassFull sx={{color: 'white', margin: 'auto', width: '50px'}}/>
            <div style={{width: '120px'}} className="card--order-btn--btn">{t('old_orders')}</div>
        </div>,
        <Dialog key='dialog' onClose={closeWindow} open={open}>
            <DialogTitle>{t('old_orders')}</DialogTitle>
            <DialogContent>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell>{t('order_number')}</TableCell>
                            <TableCell>{t('catalog_numbersizecolor')}</TableCell>
                            <TableCell>{t('old_order_quantity')}</TableCell>
                            <TableCell>{t('new_quantity')}</TableCell>
                            <TableCell>{t('add_to_cart2')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(orders || []).map((it, i) =>
                            <TableRow key={it.order_number + it.art}>
                                <TableCell>{it.order_number}</TableCell>
                                <TableCell>{it.art}{isEmpty(it.color) ? '' : '/' + it.color}</TableCell>
                                <TableCell>{it.count || 0}</TableCell>
                                <TableCell>
                                    <TextField disabled={items[i] === null} onBlur={e => updateCount(e, i)}
                                               style={{width: '65px'}} inputProps={{min: 0}} type='number'
                                               placeholder={t('qty')}
                                               variant='standard'/>
                                </TableCell>
                                <TableCell>
                                    <Button disabled={items[i] === null} onClick={() => add(i)}
                                            variant='contained'>
                                        {t('add')}
                                    </Button>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </DialogContent>
            <DialogActions>
                <Button onClick={closeWindow}>{t('close')}</Button>
            </DialogActions>
        </Dialog>]
}
export default OldOrders