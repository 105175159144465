import {useEffect, useRef, useState} from "react";
import {get, PATH_CATEGORIES_ALL} from "../../../common/backend";
import {endsWith, groupBy, isEmpty, last, sortBy} from "lodash";
import LeftCategory from "./LeftCategory";
import ItemsSidebar from "./ItemsSidebar";
import {useTranslation} from "react-i18next";
import {useTheme} from "../../../common/ThemeProvider";
import {useNavigate, useParams} from "react-router";
import {getLastCategory, substringAfterLast} from "../../../common/commons";
import Breadcrumbs from "./Breadcrumbs";
import {useCurrentCategory} from "../context/CurrentCategoryContext";

const LeftMenu = ({currentPage}) => {
    const [categories, setCategories] = useState(null)
    /**
     * @type {[number,(number) => void]}
     */
    const currentCategory = useCurrentCategory().current
    let initialFactory = parseInt(substringAfterLast(window.location.href, '-'))
    if (initialFactory !== 1 && initialFactory !== 2) {
        initialFactory = 1;
    }

    const [factory, setFactoryState] = useState(initialFactory)
    const categorySet = useRef(false)

    const prevFactory = useRef(factory)
    const refs = useRef({})
    const {t} = useTranslation()
    const {updateTheme} = useTheme()
    const navigate = useNavigate()
    const categoryLink = useParams().cat_link
    const productSKU = useParams().sku

    useEffect(() => {
        if (categories === null) {
            return
        }
        let newFactory = null
        if (categoryLink === '1' || categoryLink === '2') {
            newFactory = parseInt(categoryLink)
        }
        if (endsWith(window.location.href, '/c') || endsWith(window.location.href, '/c/') || newFactory !== null) {
            newFactory ??= 1
            setFactoryState(newFactory)

            let category = categories[newFactory].find(it => it.link === getLastCategory(newFactory))
            category ??= categories[newFactory][0]
            console.log("jgjg")
            navigate('/c/' + category.link)
        }
    }, [categories, categoryLink])

    useEffect(() => {
        // noinspection JSIgnoredPromiseFromCall
        get(PATH_CATEGORIES_ALL, res => {
            const categoriesByFactory = groupBy(res.data, 'factory')
            if (categoryLink && categoriesByFactory['1'].findIndex(it => it.link === categoryLink || it.children.some(c => c.link === categoryLink)) === -1) {
                setFactoryState(2)
            }
            categoriesByFactory['1'] = sortBy(categoriesByFactory['1'], 'order')
            categoriesByFactory['2'] = sortBy(categoriesByFactory['2'], 'order')
            setCategories(categoriesByFactory)
        })
    }, [])

    useEffect(() => {
        updateTheme(factory)
    }, [factory])

    useEffect(() => {
        if ((!window.location.href.includes('/c/') && !productSKU) || isEmpty(categories)) {
            return
        }
        console.log(factory)
        console.log(prevFactory.current)
        if (factory !== prevFactory.current) {
            prevFactory.current = factory
            let category = categories[factory].find(it => it.link === getLastCategory(factory))
            category ??= categories[factory][0]
            console.log("sss")
            navigate('/c/' + category.link)
        }
    }, [factory, categories])

    useEffect(() => {
        if (categories === null || isEmpty(currentCategory) || isEmpty(refs.current) || Object.values(refs.current).some(it => it === null)) {
            return;
        }
        if (currentPage !== 'Product' || categorySet.current) {
            return
        }
        categorySet.current = true

        const parentCID = parseInt((Array.isArray(currentCategory) ? currentCategory : Object.keys(currentCategory))[0])
        const cID = parseInt(last(Array.isArray(currentCategory) ? currentCategory : Object.keys(currentCategory)))
        const newFactory = categories[1].some(it => it.id === cID || it.id === parentCID) ? 1 : 2;
        if (parentCID !== cID) {
            refs.current[parentCID].open()
        }

        //Changing factory at the left table causes navigation to the first (or last used) category at that factory
        //But if user is on product page, after loading product data from API currentCategory will be updated
        //Thus when updating current category form product`s page we don`t want redirect to category
        if ('noNavigate' in currentCategory) {
            prevFactory.current = newFactory
        }
        setFactory(newFactory)
    }, [currentCategory, currentPage, categories, refs.current])

    const closeAll = (except) => {
        Object.values(refs.current).forEach(it => {
            if (it.id !== except.id && it.id !== except.parent) {
                it.close()
            }
        })
    }

    const setFactory = (newFactory) => {
        if (newFactory === factory) {
            return
        }
        if (endsWith(window.location.href, '/c')) {
            const category = categories[newFactory][0]
            console.log('fac')
            navigate('/c/' + category.link)
        }
        setFactoryState(newFactory)
    }

    const handleLinkClick = () => {
        document.querySelector('.mob-only.mob-menu.menu-toggle').click()
        document.querySelector('#root').scrollIntoView({behavior: 'smooth', block: 'start'})
    }

    return [
        <Breadcrumbs key='breadcrumbs' currentPage={currentPage} categories={categories}/>,
        <aside key='main' className='col-12 col-xl-3 sidebar'>
            <h3 className='sidebar-title'>{t('products')}</h3>
            <div className='sidebar-choise'>
                <div className='sidebar-choise--btns flex'>
                    <span className={'sidebar-choise--btn f-1 ' + (factory === 1 ? 'active' : '')}
                          data-current-tab='factory_1' onClick={() => setFactory(1)}>
                        {t('factory')} 1
                    </span>
                    <span className={'sidebar-choise--btn f-2 ' + (factory === 2 ? 'active' : '')}
                          data-current-tab='factory_2' onClick={() => setFactory(2)}>
                        {t('factory')} 2
                    </span>
                </div>
                <div className='sidebar-content'>
                    <div className={'sidebar-content--tab ' + (factory === 1 ? 'active' : '')} id='factory_1'>
                        <nav className='sidebar-nav'>
                            <ul>
                                {((categories || {1: []})[1] || []).sort((f, s) => s.order - f.order).map(it =>
                                    <LeftCategory closeAll={closeAll}
                                                  ref={ref => refs.current[it.id] = ref}
                                                  key={it.id.toString()}
                                                  category={it}
                                                  onClick={() => handleLinkClick()}
                                    />)}
                            </ul>
                        </nav>
                    </div>
                    <div className={'sidebar-content--tab ' + (factory === 2 ? 'active' : '')} id='factory_2'>
                        <nav className='sidebar-nav'>
                            <ul>
                                {((categories || {2: []})[2] || []).sort((f, s) => s.order - f.order).map(it =>
                                    <LeftCategory closeAll={closeAll}
                                                  ref={ref => refs.current[it.id] = ref}
                                                  key={it.id}
                                                  category={it}
                                                  onClick={() => handleLinkClick()}
                                    />)}
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
            <ItemsSidebar handleLinkClick={() => handleLinkClick()}/>
        </aside>
    ]
}
export default LeftMenu
