import * as React from "react";
import {useEffect, useState} from "react";
import {get, PATH_BACKUP_DATE} from "../../common/backend";
import Tooltip from "@mui/material/Tooltip";
import {AddToDrive} from "@mui/icons-material";
import {IconButton} from "@mui/material";

const BackupDate = () => {
    const [backupDate, setBackup] = useState(/** @type string */ null)

    const animation = () => backupDate === null ? {
        animation: "spin 2s linear infinite",
        "@keyframes spin": {
            "100%": {
                transform: "rotate(360deg)",
            },
            "0%": {
                transform: "rotate(0deg)",
            },
        },
    } : {}

    const red = () => {
        if (backupDate === null) {
            return '#bdbdbd'
        }
        const updated_at = new Date(backupDate);
        const now = new Date();
        if (now.getMonth() !== updated_at.getMonth() || now.getFullYear() !== updated_at.getFullYear()) {
            return 'red'
        }
        return '#bdbdbd'
    }

    useEffect(() => {
        get(PATH_BACKUP_DATE, res => setBackup(res.data.value))
    }, [])

    return <Tooltip title={`Backup done at: ${backupDate}`}>
        <IconButton sx={{
            backgroundColor: red(),
            color: 'white',
            ':hover': {backgroundColor: red()},
            marginRight: '5px',
            borderRadius: 2
        }}>
            <AddToDrive sx={animation()}/>
        </IconButton>
    </Tooltip>
}
export default BackupDate;
