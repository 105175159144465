import * as React from "react";
import {useEffect, useState} from "react";
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    InputLabel,
    List,
    ListItem,
    Paper,
    Select,
    Switch,
    Table,
    TableBody,
    TableContainer,
    TableRow,
    TextField
} from "@mui/material";
import {
    attachmentsAddress,
    get,
    getErrorMessage,
    PATH_GET_ALL_PRODUCTS,
    PATH_IMPORT,
    PATH_PRODUCT_SET_NEW,
    post
} from "../../../common/backend";
import TableCell from "@mui/material/TableCell";
import TablePagination from "@mui/material/TablePagination";
import Box from "@mui/material/Box";
import TableSortLabel from "@mui/material/TableSortLabel";
import {visuallyHidden} from "@mui/utils";
import TableHead from "@mui/material/TableHead";
import IconButton from "@mui/material/IconButton";
import {Link} from "react-router-dom";
import {Edit, Image, Refresh, Send, Upload} from "@mui/icons-material";
import $ from "jquery";
import {toast} from "react-hot-toast";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import UpdatePackage from "./package/UpdatePackage";
import {useLoader} from "../../../common/Loader";
import {useAuth} from "../../../common/auth/AuthContext";
import {getAdminPageTitle, wrapTooltip} from "../../../common/commons";
import ImportErrorModal from "./ImportErrorModal";
import {uniqBy} from "lodash";

const ProductsPage = () => {
    document.title = getAdminPageTitle('Products')

    const {setLoading} = useLoader()
    const [products, setProducts] = useState(null)
    const [allProducts, setAllProducts] = useState(null)

    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('Code');
    const [page, setPage] = useState(0);

    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [search, setSearch] = useState('')
    const [noImage, setNoImage] = useState(false)
    const [showHidden, setShowHidden] = useState(false)
    const [filterFactory, setFilterFactory] = useState(0)

    /**
     * @typedef {{text, filename:string}} ImportError
     * @type {[ImportError, (value: ImportError)=>void]}
     */
    const [importErrorText, setImportError] = useState(null)

    const {user} = useAuth()

    const loadProducts = () => {
        setLoading(true)
        get(PATH_GET_ALL_PRODUCTS + '?columns=show,factory,name,code,catalog_number,attachments,new,one_weight,box_net,box_gross,box_size,box_count,order_number', res => {
            setProducts(uniqBy(res.data.data, (it) => {
                it.selected = false;
                it.new = it.new || '0'
                it.images = it.attachments.filter(it => it.type === 'img')
                return it.catalog_number;
            }).sort((a, b) => new Date(b.created_at) - new Date(a.created_at)))
            setAllProducts(res.data.data)
        }).finally(() => setLoading(false))
    }

    const handleImport = (event) => {
        setLoading(true)
        const formData = new FormData()
        const file = event.target.files.item(0)
        formData.append('file', file)
        post(PATH_IMPORT, res => {
            loadProducts()
            const data = res.data.data;
            toast.success(`In file: ${data.total}, new: ${data.created}, updated: ${data.updated}, removed: ${data.removed}`, {duration: 5000})
        }, e => {
            let messages = getErrorMessage(e)
            if ('response' in e && 'data' in e.response && 'error' in e.response.data) {
                messages = e.response.data.error.messages.map((it, i) => <ListItem key={i}>{it}</ListItem>)
            }
            setImportError({
                text: <List>{messages}</List>,
                filename: file.name
            })
            setLoading(false)
        }, formData).finally(() => document.getElementById('import-file').value = null)
    };

    const setNew = duration => {
        const selected = getPageItems().filter(it => it.selected !== false);
        if (selected.length === 0) {
            toast.error('No products selected');
            return;
        }
        setLoading(true)
        post(PATH_PRODUCT_SET_NEW,
            res => {
                selected.forEach(it => it.new = res.data.data || '0')
                if (res.data.data == null) {
                    toast.success('Products isn`t new anymore');
                    return
                }
                toast.success('Products marked as new till ' + (new Date(res.data.data)).toISOString().split('T')[0]);
            },
            res => toast.error('Error: ' + res.data.error.message), {
                'duration': duration,
                'catalog_numbers': selected.map(it => it.catalog_number)
            }).finally(() => setLoading(false))
    };

    const getPageItems = () => {
        return (products || [])
            .filter(it =>
                (
                    it.name.toLowerCase().indexOf(search.toLowerCase()) !== -1
                    || it.catalog_number.toLowerCase().indexOf(search.toLowerCase()) !== -1)
                && (!noImage || it.images.length === 0)
                && (showHidden || it.show)
                && (filterFactory === 0 || it.factory === filterFactory)
            )
            .sort(getComparator(order, orderBy))
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    };

    //table
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const descendingComparator = (a, b, orderBy) => {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    };

    function getComparator(order, orderBy) {
        orderBy = orderBy
            .toLowerCase()
            .replace(' ', '_')
            .replace('image', 'images')
            .replace('new_(days left)', 'new')
            .replace('hidden', 'show')
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const createSortHandler = (property) => (event) => {
        handleRequestSort(event, property);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - products.length) : 0;
    //table end

    const importButton = () => {
        if (user.hasPerm('product import')) {
            return <Button endIcon={<Upload fontSize='large'/>}
                           onClick={_ => $('#import-file').click()} aria-label='Import'
                           color='primary'
                           variant='contained'
                           sx={{float: 'right', marginTop: '10px', marginRight: '10px'}}>
                Import
            </Button>
        }
        return wrapTooltip('No permission',
            <Button endIcon={<Upload fontSize='large'/>}
                    disabled
                    color='primary'
                    variant='contained'
                    sx={{float: 'right', marginTop: '10px', marginRight: '10px'}}>
                Import
            </Button>)
    }

    const markAsNewButton = () => {
        const form = disabled => <FormControl disabled={disabled} sx={{minWidth: '120px', marginTop: '8px'}}
                                              variant="standard">
            <InputLabel id="mark-new-label">Mark New</InputLabel>
            <Select
                labelId="mark-new-label"
                label="Mark New"
                onChange={e => setNew(e.target.value)}
                value='-1'
            >
                <MenuItem value='-1'>Duration</MenuItem>
                <MenuItem value={0}>None</MenuItem>
                <MenuItem value={6}>6 month</MenuItem>
                <MenuItem value={12}>12 month</MenuItem>
                <MenuItem value={18}>18 month</MenuItem>
                <MenuItem value={24}>24 month</MenuItem>
            </Select>
        </FormControl>
        if (user.hasPerm('product mark as new')) {
            return form(false)
        }
        return wrapTooltip('No permission', form(true))
    }

    const updatePackageButton = () => {
        if (user.hasPerm('product rimet print import')) {
            return <UpdatePackage refreshItems={loadProducts}
                                  items={allProducts}/>
        }
        return wrapTooltip('No permission',
            <Button disabled endIcon={<Refresh fontSize='large'/>} color='primary' variant='contained'
                    sx={{float: 'right', marginTop: '10px', marginRight: '10px'}}>
                Package
            </Button>
        )
    }

    useEffect(() => {
        if (products === null) {
            loadProducts()
        }
    }, []);

    return <Box sx={{width: '100%', marginTop: '20px'}}>
        <ImportErrorModal data={importErrorText} close={() => setImportError(null)}/>
        <Paper sx={{maxWidth: '70%', width: '100%', mb: 2, margin: 'auto'}}>
            <TableContainer style={{fontFamily: '"Roboto","Helvetica","Arial",sans-serif'}}>
                <TextField label='Search' placeholder='Name/Code/Catalog Number' onChange={e => {
                    setPage(0);
                    setSearch(e.target.value);
                }} sx={{marginTop: '8px', marginLeft: '8px', width: 'fit-content'}}
                           variant='outlined'>
                </TextField>
                <FormControl>
                    <FormControlLabel sx={{margin: 'auto', marginLeft: '10px', marginRight: '10px'}}
                                      control={<Switch checked={noImage} onChange={() => setNoImage(!noImage)}/>}
                                      label='Only items without image'/>
                    <FormControlLabel sx={{margin: 'auto', marginLeft: '10px', marginRight: '10px'}}
                                      control={<Switch checked={showHidden}
                                                       onChange={() => setShowHidden(!showHidden)}/>}
                                      label='Show hidden'/>
                </FormControl>
                <FormControl sx={{minWidth: '120px', marginTop: '8px', marginLeft: '10px', marginRight: '10px'}}
                             variant="standard">
                    <InputLabel id="mark-new-label">Factory</InputLabel>
                    <Select label="Factory"
                            onChange={(e) => setFilterFactory(e.target.value)}
                            value={filterFactory}
                    >
                        <MenuItem value={0}>All</MenuItem>
                        <MenuItem value={1}>1</MenuItem>
                        <MenuItem value={2}>2</MenuItem>
                    </Select>
                </FormControl>
                {markAsNewButton()}
                <input onChange={handleImport} hidden id='import-file' type="file" accept='.xlsx'/>
                {updatePackageButton()}
                {importButton()}
                <Table sx={{minWidth: 800, tableLayout: 'fixed'}}
                       aria-labelledby="tableTitle"
                       size='small'>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{width: '55px'}}/>
                            <TableCell sx={{width: '40px'}}>
                                Hidden
                            </TableCell>
                            {['Factory', 'New (Days Left)', 'Image', 'Catalog Number', 'Name'].map((headCell, index) => {
                                let align = 'right'
                                if (['Name', 'Image', 'Catalog Number'].indexOf(headCell) !== -1) {
                                    align = 'left'
                                }
                                if (headCell === 'New (Days Left)') {
                                    align = 'center'
                                }

                                const style = {}
                                if (['Factory', 'New (Days Left)'].includes(headCell)) {
                                    style.width = '125px'
                                }
                                if (['Catalog Number', 'Name'].includes(headCell)) {
                                    style.width = '185px'
                                }
                                if (headCell === 'Image') {
                                    style.width = '160px'
                                }

                                return <TableCell
                                    key={`head-${index}`}
                                    align={align}
                                    sx={style}
                                    padding='normal'
                                    sortDirection={orderBy === headCell ? order : false}
                                >
                                    {headCell !== 'Image' ? <TableSortLabel
                                        active={orderBy === headCell}
                                        direction={orderBy === headCell ? order : 'asc'}
                                        onClick={createSortHandler(headCell)}
                                    >
                                        {headCell}
                                        {orderBy === headCell ? (
                                            <Box component="span" sx={visuallyHidden}>
                                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                            </Box>
                                        ) : null}
                                    </TableSortLabel> : headCell}
                                </TableCell>
                            })}
                            <TableCell sx={{width: '40px'}}>
                                Edit
                            </TableCell>
                            <TableCell sx={{width: '40px'}}>
                                GoTo
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {getPageItems().map(row => {
                            return (
                                <TableRow
                                    tabIndex={-1}
                                    key={`product-${row.code}`}
                                >
                                    <TableCell>
                                        <Checkbox defaultChecked={row.selected} id={'selected-' + row.code}
                                                  onChange={() => row.selected = !row.selected}/>
                                    </TableCell>
                                    <TableCell align='center'>
                                        {row.show ? '-' : '+'}
                                    </TableCell>
                                    <TableCell align='center'>
                                        {row.factory}
                                    </TableCell>
                                    <TableCell align='center'>
                                        {row.new !== '0' ? Math.ceil(Math.abs(new Date() - new Date(row.new)) / (1000 * 60 * 60 * 24)) : 'none'}
                                    </TableCell>
                                    <TableCell align='left'>
                                        {row.images.length === 0 ? <Image fontSize='large'/> :
                                            <img alt='' style={{maxHeight: '50px'}}
                                                 src={`${attachmentsAddress}/${row.images[0].id}/full.jpg`}/>}
                                    </TableCell>
                                    <TableCell
                                        component="th"
                                        scope="row"
                                        padding="none"
                                        align="left"
                                    >
                                        {row.catalog_number}
                                    </TableCell>
                                    <TableCell sx={{maxWidth: '10%'}} align="left">{row.name}</TableCell>
                                    <TableCell align="left">
                                        <IconButton
                                            to={`/admin/products/${row.catalog_number}`}
                                            component={Link}>
                                            <Edit color='primary'/>
                                        </IconButton>
                                    </TableCell>
                                    <TableCell align="left">
                                        <IconButton
                                            target='_blank'
                                            to={`/${row.catalog_number}`}
                                            component={Link}
                                        >
                                            <Send color="primary"/>
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            );
                        })
                        }
                        {emptyRows > 0 && (
                            <TableRow
                                style={{
                                    height: (33) * emptyRows,
                                }}
                            >
                                <TableCell colSpan={6}/>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 25, 50, 100, {value: -1, label: 'All'}]}
                component="div"
                count={(products || []).length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    </Box>
}
export default ProductsPage
