import * as React from "react";
import {forwardRef, useImperativeHandle, useState} from "react";
import {nearestCount} from "../../../common/cart";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import {AttachMoney} from "@mui/icons-material";
import {Checkbox, TableRow} from "@mui/material";
import TableCell from "@mui/material/TableCell";
import $ from "jquery";
import {isNumber, max, round, upperCase} from "lodash";

const Item = forwardRef((props, ref) => {
    const {columns, item, setSelect, setSupplierPriceItem, t} = props
    if (!('selected' in item)) {
        item.selected = false;
    }
    if (!('skip' in item)) {
        item.skip = false;
    }
    if (!('buy_count' in item)) {
        item.buy_count = 0;
    }
    //можно ли выбрать количество товара без выбора цветов
    const canSelect = item.schema === null;

    const [selected, setSelectedReal] = useState(false);

    const setSelected = val => {
        if (!canSelect) {
            return
        }
        if (val) {
            const inp = $('#count-' + item.code)
            const current = parseInt(inp.val())
            let newVal = current
            if (current === 0) {
                if (item.box_count) {
                    newVal = max([nearestCount(val, item.box_count), 1])
                } else {
                    newVal = 1
                }
            }
            if (current !== newVal) {
                item.buy_count = newVal
                inp.val(newVal)
            }
        }
        item.selected = val
        setSelectedReal(val)
    }

    useImperativeHandle(ref, () => ({setSelected}))

    return <TableRow>
        {columns.map(([name, value]) => {
            let val = null
            let inCart = false
            props.cartItems.forEach(itemInCart => {
                if (item.code === itemInCart.code) {
                    inCart = true
                }
            })
            const sx = {}
            if (name === 'quantity') {
                if (item.schema === null) {
                    val = <input id={'count-' + item.code} onBlur={e => {
                        const val = parseInt(e.target.value)
                        if (val === 0) {
                            return
                        }
                        if (item.box_count) {
                            e.target.value = max([nearestCount(val, item.box_count), 1])
                        }
                        setSelected(true)
                        item.buy_count = e.target.value
                    }} onKeyUp={e => {
                        if (e.code === 'Enter' || e.keyCode === 13) {
                            e.target.blur()
                        }
                    }} style={{
                        width: '55px',
                        fontFamily: 'Montserrat Regular !important',
                        color: 'rgba(0, 0, 0, 0.87)'
                    }} defaultValue={0} type='number' min='1'/>
                } else {
                    val = <div style={{border: '0'}} onClick={() => setSelect(item)} key='select_open'
                               className="param--value param--value_second param--btn">
                        <button className={inCart ? "in-cart" : ""}>{upperCase(t('select'))}</button>
                    </div>
                }
            }
            if (name === 'supplier_price') {
                val = <Tooltip title='Supplier Price'>
                    <IconButton tabIndex={-1} onClick={() => setSupplierPriceItem(item)} sx={{
                        color: item.supplier_price.length > 0 ? 'Green' : 'Gray'
                    }}>
                        <AttachMoney/>
                    </IconButton>
                </Tooltip>
            }
            if (canSelect && name === 'select' && val === null) {
                sx.width = '8px'
                val = <Checkbox tabIndex={-1} checked={selected} onChange={e => setSelected(e.target.checked)}/>
            }
            if (!canSelect && name === 'select') {
                return null
            }
            if (val === null) {
                val = parseInt(item[value]) === 0 || item[value] === '' ? '-' : item[value]
                if (isNumber(val)){
                    val = round(val,2)
                }
            }
            if (name === "goods_name") {
                val = t(val, {ns: 'name'})
            }

            return <TableCell align='center' sx={sx} key={`${item.code}-${name}`}>
                {val}
            </TableCell>
        })}
    </TableRow>
})
export default Item