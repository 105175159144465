import {getAdminPageTitle} from "../../../common/commons";
import {Loader, useLoader} from "../../../common/Loader";
import {Fab, Grid, Paper, Table, TableBody, TableContainer, TableRow, TextField} from "@mui/material";
import {Save} from "@mui/icons-material";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import {useDeliveryData} from "../../../desktop/common/context/DeliveryDataContext";
import Typography from "@mui/material/Typography";
import {useLayoutEffect, useState} from "react";
import {clone, isEmpty, isNumber} from "lodash";
import {isPureMathExpression} from "../../../common/cart";
import {toast} from "react-hot-toast";
import {PATH_SETTINGS_SET, post} from "../../../common/backend";

const DeliveryFormula = () => {
    document.title = getAdminPageTitle('Delivery')

    const {setLoading} = useLoader()

    const {currency, volumeFormula, grossWeightFormula} = useDeliveryData()
    const [variables, setVariables] = useState({volume: 1, gross: 1})
    const [formulas, setFormulas] = useState({volume: volumeFormula, gross: grossWeightFormula})

    useLayoutEffect(() => {
        setFormulas({volume: volumeFormula, gross: grossWeightFormula})
    }, [currency]);

    const save = () => {
        if (!isNumber(getResult(formulas.volume))) {
            toast.error('Volume formula error')
            return
        }
        if (!isNumber(getResult(formulas.gross))) {
            toast.error('Gross weight formula error')
            return
        }
        setLoading(true)
        post(PATH_SETTINGS_SET, _ => {
            toast.success('Done')
        }, res => {
            toast.error(res)
        }, {
            delivery_by_volume: formulas.volume,
            delivery_by_weight: formulas.gross
        }).finally(() => setLoading(false))
    }

    const setVar = (type, value) => {
        const newVar = clone(variables)
        newVar[type] = value
        setVariables(newVar)
    }
    const setFormula = (type, value) => {
        const newFormulas = clone(formulas)
        newFormulas[type] = value
        setFormulas(newFormulas)
    }

    if (currency <= 0) {
        return <Loader/>
    }

    const getResult = (formula) => {
        const unused = []
        if (!formula.includes('currency')) {
            unused.push('currency')
        }
        if (!formula.includes('gross') && !formula.includes('volume')) {
            unused.push('gross', 'volume')
        }
        formula = formula
            .replace('currency', currency.toString())
            .replace('gross', variables.gross.toString())
            .replace('volume', variables.volume.toString())
        if (!isPureMathExpression(formula)) {
            return 'Formula error'
        }
        if (!isEmpty(unused)) {
            return <span>
                {eval(formula)}
                <p style={{color: 'red'}}>WARNING unused: {unused.join(', ')}</p>
       </span>
        }
        return eval(formula)
    }

    return <>
        <Grid direction='row' container spacing={2} justifyContent='center'>
            <Grid item>
                <TableContainer sx={{textAlign: 'center', marginTop: '10px'}} component={Paper}>
                    <Table sx={{maxWidth: '300px', textAlign: 'center'}}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Variable</TableCell>
                                <TableCell>Value</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    <Typography>Currency</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography>{currency}</Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography>Volume (cbm):</Typography>
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        onChange={e => setVar('volume', parseFloat(e.target.value) || e.target.value)}
                                        value={variables.volume} inputProps={{step: 0.1}} variant='standard'
                                        type='number' min='1'
                                        sx={{width: '50px'}}/>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography>Gross Weight (kg):</Typography>
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        onChange={e => setVar('gross', parseFloat(e.target.value) || e.target.value)}
                                        value={variables.gross} inputProps={{step: 0.1}}
                                        variant='standard' type='number' min='1'
                                        sx={{width: '50px'}}/>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Grid item>
                <TableContainer sx={{textAlign: 'center', marginTop: '10px'}} component={Paper}>
                    <Table sx={{maxWidth: '550px', margin: 'auto'}}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Type</TableCell>
                                <TableCell>Value</TableCell>
                                <TableCell>Result</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    Formula By Volume
                                </TableCell>
                                <TableCell>
                                    <TextField sx={{width: '350px'}}
                                               onChange={e => setFormula('volume', e.target.value)}
                                               variant='standard' value={formulas.volume}/>
                                </TableCell>
                                <TableCell>
                                    {getResult(formulas.volume)}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    Formula By Gross Weight
                                </TableCell>
                                <TableCell>
                                    <TextField onChange={e => setFormula('gross', e.target.value)} sx={{width: '350px'}}
                                               variant='standard' size='small'
                                               value={formulas.gross}/>
                                </TableCell>
                                <TableCell>
                                    {getResult(formulas.gross)}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
        <Fab sx={{
            position: "fixed",
            bottom: (theme) => theme.spacing(2),
            right: (theme) => theme.spacing(2)
        }} onClick={save} color="primary">
            <Save/>
        </Fab>
    </>
}

export default DeliveryFormula