import {useEffect, useState} from "react";
import {useLoader} from "../../../common/Loader";
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import {get, PATH_GET_IMPORT_HISTORY} from "../../../common/backend";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import {getAdminPageTitle} from "../../../common/commons";

const PriceImportHistory = () => {
    document.title = getAdminPageTitle('Price Import History')

    const {setLoading} = useLoader()
    const [list, setList] = useState(null)

    const [updated, setUpdated] = useState(null)

    useEffect(() => {
        if (list === null) {
            get(PATH_GET_IMPORT_HISTORY, res => setList(res.data)).finally(() => setLoading(false))
        }
    }, [])

    const getValue = (item, key) => {
        let data = item[key.toLowerCase().replaceAll(' ', '_')]
        if (key === 'updated') {
            data = <Button onClick={() => setUpdated(item[key])}>Show ({Object.keys(item[key]).length})</Button>
        }
        return <TableCell key={key + item.id}>{data}</TableCell>
    }

    return [
        <Dialog fullWidth open={updated !== null} key='detail_dialog' onClose={() => setUpdated(null)}>
            <DialogContent>
                <Table>
                    <TableHead><TableRow><TableCell>Name</TableCell><TableCell>Supplier
                        Number</TableCell></TableRow></TableHead>
                    <TableBody>
                        {Object.entries(updated || {}).map(([k, v]) => <TableRow
                            key={k + v}><TableCell>{k}</TableCell><TableCell>{v}</TableCell></TableRow>)}
                    </TableBody>
                </Table>
            </DialogContent>
            <DialogActions><Button onClick={() => setUpdated(null)}>Close</Button></DialogActions>
        </Dialog>,
        <TableContainer sx={{maxWidth: '600px', margin: 'auto'}} key='table' component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        {['Same', 'Null Price', 'Updated', 'Loaded At'].map(it => <TableCell key={it}>{it}</TableCell>)}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {(list || []).map(it =>
                        <TableRow key={it.id}>
                            {['same', 'null_price', 'updated', 'created_at'].map(val => getValue(it, val))}
                        </TableRow>)}
                </TableBody>
            </Table>
        </TableContainer>
    ];
}
export default PriceImportHistory
