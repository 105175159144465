import TableItem from "./TableItem";
import {calcTotalSize, productGetFullName} from "../../common/cart";
import {useTranslation} from "react-i18next";
import {Checkbox, Table, TableBody, TableFooter, TableRow} from "@mui/material";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import {useState} from "react";
import {DeleteForever} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import {toast} from "react-hot-toast";
import {clone, isEmpty, range} from "lodash";

const FactoryTable = ({items, factory, onChange, editable}) => {
    const {t} = useTranslation()
    items = items.filter(it => it.factory === factory)

    const [remove, setRemove] = useState({})
    const [removeAll, setRemoveAll] = useState(false)

    const setToRemove = (item, value) => {
        const key = item.code + productGetFullName(item)
        const newRemove = clone(remove)
        if (value) {
            newRemove[key] = item
        } else {
            setRemoveAll(false)
            delete newRemove[key]
        }
        setRemove(newRemove)
    }

    const setToRemoveAll = val => {
        if (!editable) {
            return
        }
        setRemoveAll(val)
        if (val) {
            setRemove(Object.fromEntries(items.map(it => [it.code + productGetFullName(it), it])))
        } else {
            setRemove({})
        }
    }

    const removeItems = () => {
        if (removeAll) {
            onChange(items, true)
            setRemove({})
            setRemoveAll(false)
            return;
        }
        if (isEmpty(remove)) {
            toast.error(t('cart_no_items_selected'))
            return
        }
        onChange(Object.values(remove), true)
        setRemove({})
        setRemoveAll(false)
        toast.success(t('done'))
    }

    const params = ['index', 'image', 'catalog number', 'name', 'number of packages', 'buy count']
    const totalSize = calcTotalSize(items)
    params.push('measure unit', 'total net weight, kg', 'total gross weight, kg', 'total volume, cbm')
    return <div className="col-12 card--information overflow-auto whitesided-table">
        <div>
            <Table>
                <TableHead style={{backgroundColor: 'rgb(236, 236, 236)'}}>
                    <TableRow>
                        {params.map(it => <TableCell className='param--title_second' align='center'
                                                     key={it}>{t(it.replace('index', '№'))}</TableCell>)}
                        <TableCell align='center'>
                            {editable &&
                                <Checkbox checked={removeAll} onChange={e => setToRemoveAll(e.target.checked)}/>}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {items.map((it, i) => <TableItem key={it.code + productGetFullName(it)} params={params}
                                                     onChange={onChange} item={it}
                                                     index={i + 1} editable={editable}
                                                     checkbox={<Checkbox className='remove-checkbox-p'
                                                                         onChange={e => setToRemove(it, e.target.checked)}
                                                                         checked={(it.code + productGetFullName(it)) in remove}/>}/>)}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        {range(0, params.length - 4).map(it => <TableCell key={it}/>)}
                        <TableCell className='default-font' align='center'>{t('total')}</TableCell>
                        {['net_weight', 'gross_weight', 'volume'].map(it =>
                            <TableCell className='default-font' key={it} align='center'>{totalSize[it]}</TableCell>)}
                        <TableCell>
                            {editable ? <IconButton className='remove-item-button' onClick={removeItems}>
                                <DeleteForever color='red'/>
                            </IconButton> : null}
                        </TableCell>
                    </TableRow>
                </TableFooter>
            </Table>
        </div>
    </div>
}
export default FactoryTable
