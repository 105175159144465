import {isEmpty} from "lodash";
import {Dialog, DialogContent, DialogTitle} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";

const ImportErrorModal = ({data, close}) => {
    if (isEmpty(data)) {
        return ''
    }
    return <Dialog open={true}>
        <DialogTitle>
            Can`t import file {data.filename}
        </DialogTitle>
        <DialogContent>
            {data.text}
        </DialogContent>
        <DialogActions>
            <Button onClick={close}>Close</Button>
        </DialogActions>
    </Dialog>
}
export default ImportErrorModal