import {useLoader} from "../../../common/Loader";
import Container from "@mui/material/Container";
import {Box, Checkbox, CssBaseline, FormControlLabel, Grid, Link, TextField} from "@mui/material";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import {Copyright, Lock} from "@mui/icons-material";
import Button from "@mui/material/Button";
import {useEffect, useState} from "react";
import {LOGIN_2FA, useAuth} from "../../../common/auth/AuthContext";
import {Navigate} from "react-router-dom";
import {err, ROLE_ADMIN, ROLE_SUPER_ADMIN} from "../../../common/backend";
import {toast} from "react-hot-toast";
import {useTranslation} from "react-i18next";
import {getAdminPageTitle} from "../../../common/commons";

const Login = () => {
    document.title = getAdminPageTitle('Login')
    const {authorized, user, login} = useAuth()

    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [remember, setRemember] = useState(false)

    const [show2fa, setShow2fa] = useState(false)
    const [g2fa, setG2fa] = useState('')

    const {setLoading} = useLoader()
    const {t} = useTranslation()

    useEffect(() => {
        if (authorized === false) setLoading(false)
    }, [authorized])

    if (authorized === true) {
        if (user.role === ROLE_ADMIN || user.role === ROLE_SUPER_ADMIN) {
            return <Navigate to="/admin"/>
        } else {
            return <Navigate to="/"/>
        }
    }

    const tryLogin = e => {
        e.preventDefault()
        if (show2fa && g2fa.length !== 6) {
            toast.error(t('enter_otp'))
            return
        }
        setLoading(true)

        const res = login(username, password, g2fa)
        if (res !== null) {
            res.then(status => {
                if (status === LOGIN_2FA) {
                    setShow2fa(true)
                    toast.error(t('enter_otp'))
                }
            }).catch(err).finally(() => setLoading(false))
        } else {
            toast.error(t('error_try_again'))
            setLoading(false)
        }
    }

    return [
        <Container key='main' component="main" maxWidth="xs">
            <CssBaseline/>
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
                    <Lock/>
                </Avatar>
                <Typography component="h1" variant="h5">
                    {t('sign_in')}
                </Typography>
                <Box component="form" onSubmit={tryLogin} sx={{mt: 1}}>
                    <TextField
                        margin="normal"
                        type='email'
                        required
                        fullWidth
                        id="email"
                        label={t('email_address')}
                        name="email"
                        autoComplete="email"
                        autoFocus
                        value={username}
                        onChange={e => setUsername(e.target.value)}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label={t('password')}
                        inputProps={{minLength: 3, style: {height: '3px'}}}
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                    />
                    <TextField
                        hidden={!show2fa}
                        margin="normal"
                        required={show2fa}
                        fullWidth
                        name="otp"
                        label={t('google_2fa_otp')}
                        inputProps={{minLength: 6, maxLength: 6}}
                        type="password"
                        id="otp"
                        value={g2fa}
                        onChange={e => setG2fa(e.target.value.replace(/\D/g, ''))}
                    />
                    <FormControlLabel
                        control={<Checkbox checked={remember} onChange={e => setRemember(e.target.checked)}
                                           value="remember"
                                           color="primary"/>}
                        label={t('remember_me')}
                    />
                    <Button type="submit" fullWidth variant="contained" sx={{mt: 3, mb: 2}}>
                        {t('sign_in')}
                    </Button>
                    <Grid container>
                        <Grid item xs>
                            <Link to="/remember-password" variant="body2">
                                {t('forgot_password')}
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link to="/sign-up" variant="body2">
                                {"Don't have an account? Sign Up"}
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Copyright sx={{mt: 8, mb: 4}}/>
        </Container>]
}
export default Login
