import {Box, InputAdornment, OutlinedInput, Paper, Table, TableBody, TableContainer, TableRow} from "@mui/material";
import * as React from "react";
import {useEffect, useState} from "react";
import {useLoader} from "../../../common/Loader";
import {get, PATH_USERS} from "../../../common/backend";
import {toast} from "react-hot-toast";
import {Add, Delete, DoneAll, Edit, Logout, Search, Warning} from "@mui/icons-material";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import IconButton from "@mui/material/IconButton";
import TablePagination from "@mui/material/TablePagination";
import Button from "@mui/material/Button";
import EditUserModal from "./EditUserModal";
import LogoutDialog from "./LogoutDialog";
import DeleteUserDialog from "./DeleteUserDialog";
import CreateUserModal from "./CreateUserModal";
import {isEmpty} from "lodash";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

const UsersPage = () => {
    document.title = 'Users | R!metCN'
    const [users, setUsers] = useState([])

    const [page, setPage] = useState(0)
    const [search, setSearch] = useState('')
    const [rowsPerPage, setRowsPerPage] = useState(25);

    const [editUser, setEditUser] = useState(null)

    const [logoutUser, setLogoutUser] = useState(null)
    const [deleteUser, setDeleteUser] = useState(null)
    const [createUserOpen, setCreateUserOpen] = useState(false)

    const {setLoading} = useLoader()

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const getPageItems = () => {
        return (users || [])
            .filter(it => isEmpty(search) || it.full_name.toLowerCase().indexOf(search.toLowerCase()) !== -1 || it.company.toLowerCase().indexOf(search.toLowerCase()) !== -1 || it.email.toLowerCase().indexOf(search.toLowerCase()) !== -1
            ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    };

    const loadUsers = () => {
        setLoading(true)
        get(PATH_USERS, res => setUsers(res.data), () => {
            toast.error('Error. Try reload page')
        }).finally(() => setLoading(false))
    }

    useEffect(() => {
        if (users.length === 0) {
            loadUsers()
        }
    }, [])

    const openEditUserModal = user => setEditUser(user)

    return <Box key='main-container' display='flex' m='auto'>
        <LogoutDialog open={logoutUser !== null} user={logoutUser} close={() => setLogoutUser(null)}/>
        <DeleteUserDialog open={deleteUser !== null} user={deleteUser} close={() => setDeleteUser(null)}
                          onDelete={loadUsers}/>
        <EditUserModal open={editUser !== null} user={editUser || {}} close={() => setEditUser(null)}/>
        <CreateUserModal open={createUserOpen} close={() => setCreateUserOpen(false)} onCreated={_ => loadUsers()}/>
        <Paper sx={{maxWidth: '100%', width: 'auto', mb: 2, margin: 'auto'}}>
            <TableContainer>
                <OutlinedInput onChange={e => {
                    setPage(0);
                    setSearch(e.target.value);
                }} label='Search' id='search' sx={{marginTop: '8px', marginLeft: '8px', width: 'fit-content'}}
                               variant='outlined' startAdornment={
                    <InputAdornment position='start'>
                        <Search/>
                    </InputAdornment>
                }>
                </OutlinedInput>
                <Button endIcon={<Add fontSize='large'/>}
                        onClick={() => setCreateUserOpen(true)} aria-label='Create'
                        color='primary'
                        variant='contained'
                        sx={{float: 'right', marginTop: '10px', marginRight: '10px'}}>
                    Create
                </Button>
                <Table sx={{minWidth: 750}}
                       aria-labelledby="tableTitle"
                       size='small'>
                    <TableHead>
                        <TableRow>
                            {['ID', 'Company', 'Full Name', 'Email', 'Edit', 'Logout', 'Delete'].map((headCell, index) => (
                                <TableCell
                                    key={`head-${index}`}
                                    align='left'
                                    padding='normal'>
                                    {headCell}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {getPageItems().map(row => <TableRow key={row.id}>
                                <TableCell>
                                    {row.id}
                                </TableCell>
                                <TableCell align="left">
                                    {row.company}
                                </TableCell>
                                <TableCell align="left">
                                    {row.full_name}
                                </TableCell>
                                <TableCell>
                                    <span>{row.email}</span>
                                    <span>
                                        <Tooltip placement='top' title={
                                            <Typography>
                                                {row.email_verified_at ? 'Verified' : 'Not Verified'}
                                            </Typography>
                                        }>
                                        <IconButton color={row.email_verified_at ? 'primary' : 'warning'}>
                                            {row.email_verified_at ? <DoneAll/> : <Warning/>}
                                        </IconButton>
                                    </Tooltip>
                                    </span>
                                </TableCell>
                                <TableCell align="left">
                                    <IconButton onClick={() => openEditUserModal(row)}>
                                        <Edit color="primary"/>
                                    </IconButton>
                                </TableCell>
                                <TableCell align="left">
                                    <IconButton onClick={() => setLogoutUser(row)}>
                                        <Logout color="primary"/>
                                    </IconButton>
                                </TableCell>
                                <TableCell align="left">
                                    <IconButton onClick={() => setDeleteUser(row)}>
                                        <Delete color="primary"/>
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        )}
                        {emptyRows > 0 && (
                            <TableRow style={{height: (33) * emptyRows}}>
                                <TableCell colSpan={6}/>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 25, 50, 100, {value: -1, label: 'All'}]}
                component="div"
                count={(users || []).length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    </Box>
}
export default UsersPage
