import {useLoader} from "../../../common/Loader";
import * as React from "react";
import {useEffect, useState} from "react";
import {
    get,
    PATH_GET_ALL_SPECS,
    PATH_GET_SCHEMA_VALUES,
    PATH_SAVE_SCHEMA_VALUES,
    patternAddress,
    post
} from "../../../common/backend";
import {useParams} from "react-router";
import {Save} from "@mui/icons-material";
import {
    Checkbox,
    Fab,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import Typography from "@mui/material/Typography";
import {toast} from "react-hot-toast";
import {isEmpty} from "lodash";
import {getAdminPageTitle} from "../../../common/commons";

const SchemaPage = () => {

    const {setLoading} = useLoader()
    const [specs, setSpecs] = useState({})
    const schemaId = useParams().id.split('-')[0]
    const schemaName = useParams().id.split('-').slice(1, 10).join(' ')

    document.title = getAdminPageTitle(`${schemaName} pattern`)

    useEffect(() => {
        if (isEmpty(specs)) {
            get(PATH_GET_ALL_SPECS, specsRes => {
                post(PATH_GET_SCHEMA_VALUES + schemaId, valuesRes => {
                    Object.values(specsRes.data.data).flat().forEach(it =>
                        it.selected = valuesRes.data.data.indexOf(it.id) !== -1
                    )
                    setSpecs(specsRes.data.data)
                    setLoading(false)
                })
            })
        }
    })

    const save = () => {
        setLoading(true);
        post(PATH_SAVE_SCHEMA_VALUES + schemaId, () => {
            setLoading(false)
            toast.success('Saved!')
        }, undefined, {values: Object.values(specs).flat()});
    }

    return [
        <Paper key='main-container' sx={{width: 'fit-content', overflow: 'hidden', margin: 'auto', marginTop: 2}}>
            <Typography textAlign='center' variant="h5">Material template options for {schemaName}</Typography>
            <Grid spacing={4} container>
                {Object.entries(specs).map(([spec, values]) => {
                    return <Grid key={`spec-${spec}`} item>
                        <TableContainer>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align='center' colSpan={3}>
                                            <Typography variant="h6">{spec}</Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {values.map(it => {
                                        return <TableRow key={`spec-${spec}-value-${it.name}`}
                                                         sx={{maxHeight: '80px', height: '80px'}}>
                                            <TableCell><Checkbox
                                                defaultChecked={it.selected}
                                                onChange={() => it.selected = !it.selected}/>
                                            </TableCell>
                                            <TableCell>{it.name}</TableCell>
                                            {spec === 'Pattern' &&
                                                <TableCell sx={{padding: '2px'}}>
                                                    <img style={{maxWidth: '100px'}}
                                                         src={patternAddress + it.image}
                                                         alt='img'/>
                                                </TableCell>}
                                        </TableRow>
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                })}
            </Grid>
        </Paper>,
        <Fab key='save-button' sx={{
            position: "fixed",
            bottom: (theme) => theme.spacing(2),
            right: (theme) => theme.spacing(2)
        }} onClick={save} color="primary"><Save/></Fab>
    ]
}
export default SchemaPage
