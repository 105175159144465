import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography
} from "@mui/material";
import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {
    backendAddress,
    deleteR,
    get,
    getDownloadLink,
    getErrorMessage,
    getFileIcon,
    getViewLink,
    PATH_ATTACHMENT_SHOW_DOWNLOADS,
    PATH_DOWNLOAD_ADD,
    PATH_DOWNLOAD_REMOVE,
    PATH_DOWNLOAD_UPDATE
} from "../../../common/backend";
import {Add, DeleteForever} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import {useLoader} from "../../../common/Loader";
import {DropzoneDialog} from "mui-file-dropzone";
import {toast} from "react-hot-toast";
import axios from "axios";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {getAPIKey} from "../../../common/auth/AuthContext";
import {clone, isEmpty} from "lodash";
import {getAdminPageTitle} from "../../../common/commons";

const Downloads = () => {
    document.title = getAdminPageTitle('Downloads')
    const {setLoading} = useLoader()
    const [files, setFiles] = useState(null)

    const [fileToRemove, setFileToRemove] = useState(null)

    const [dropzoneOpen, setDropzoneOpen] = useState(false)

    const [iconFor, setIconFor] = useState(null)

    const name = useRef()
    const link = useRef()

    useEffect(() => {
        if (files === null) {
            get(PATH_ATTACHMENT_SHOW_DOWNLOADS, res => {
                setFiles(res.data)
                setLoading(false)
            })
        }
    })

    const addAttachment = () => {
        if (isEmpty(name.current.value)) {
            toast.error('Provide name')
            return
        }
        if (files.some(it => it.name.toUpperCase() === name.current.value.toUpperCase())) {
            toast.error('Name duplicate')
            return
        }
        if (!isEmpty(link.current.value)) {
            uploadAttachment()
        } else {
            setDropzoneOpen(true)
        }
    }

    const uploadAttachment = (file = null) => {
        setDropzoneOpen(false);
        const formData = new FormData()
        formData.append('name', name.current.value)
        if (file) {
            if (!['jpg', 'jpeg', 'gif', 'png', 'mp4', 'doc', 'docx', 'pdf', 'xls', 'xlsx'].includes(file.name.split('.').pop())) {
                toast.error('Unknown format');
                return;
            }
            formData.append('file', file)
        } else {
            formData.append('link', link.current.value)
        }
        setLoading(true)
        axios.post(backendAddress + PATH_DOWNLOAD_ADD, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + getAPIKey()
            },
            withCredentials: false
        }).then(res => {
            const newFiles = clone(files)
            newFiles.push(res.data.object)
            setFiles(newFiles)
            toast.success('Download added!');
        }).catch(e => toast.error(getErrorMessage(e)))
            .finally(() => {
                setLoading(false)
                setIconFor(null)
            })
    }

    const removeFile = () => {
        setLoading(true)
        deleteR(PATH_DOWNLOAD_REMOVE + fileToRemove.id, () => {
            setFiles(files.filter(it => it !== fileToRemove))
            setFileToRemove(null)
            toast.success('File removed')
        }, res => {
            toast.error(getErrorMessage(res))
        }).finally(() => setLoading(false))
    }

    const uploadIcon = (file) => {
        file = file[0]
        const formData = new FormData()
        formData.append('icon', file)
        axios.post(backendAddress + PATH_DOWNLOAD_UPDATE + iconFor.id, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + getAPIKey()
            },
            withCredentials: false
        }).then(res => {
            const newFiles = files.filter(it => it.id !== res.data.id)
            newFiles.push(res.data)
            setFiles([])
            setFiles(newFiles)
            toast.success('Icon updated');
        }).catch(e => toast.error(getErrorMessage(e)))
            .finally(() => setLoading(false))
    }

    return [
        <DropzoneDialog key='dropzone' clearOnUnmount={true} filesLimit={1}
                        dropzoneText='Drop New File Here'
                        onClose={() => setDropzoneOpen(false)}
                        dialogTitle='Uploading'
                        onSave={file => uploadAttachment(file[0])}
                        maxFileSize={104857600} open={dropzoneOpen}/>,
        <DropzoneDialog key='dropzone-icon' clearOnUnmount={true} filesLimit={1}
                        acceptedFiles={['image/jpeg', 'image/png', 'image/gif', 'image/webp']}
                        dropzoneText={'Set icon for ' + (iconFor || {name: ''}).name}
                        onClose={() => setIconFor(null)}
                        dialogTitle='Icon'
                        onSave={uploadIcon}
                        maxFileSize={104857600} open={!!iconFor}/>,
        <Dialog
            key='remove-dialog'
            open={fileToRemove !== null}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {`Delete file ${fileToRemove && fileToRemove.name}?`}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {fileToRemove &&
                        <a target='_blank' href={getViewLink(fileToRemove)}>
                            <img alt='' src={getFileIcon(fileToRemove)}/>
                        </a>
                    }
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {
                    setFileToRemove(null)
                }}>No</Button>
                <Button onClick={() => {
                    removeFile();
                }} autoFocus>
                    Yes
                </Button>
            </DialogActions>
        </Dialog>,
        <TableContainer key='main' component={Paper} sx={{maxWidth: '600px', margin: 'auto', marginTop: '20px'}}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            Icon
                        </TableCell>
                        <TableCell>
                            File
                        </TableCell>
                        <TableCell>
                            Name
                        </TableCell>
                        <TableCell/>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {(files || []).map(it => <TableRow key={'file-' + it.id}>
                            <TableCell>
                                <img onClick={() => setIconFor(it)} style={{maxHeight: '110px', cursor: 'pointer'}}
                                     src={getFileIcon(it)}
                                     alt='img'/>
                            </TableCell>
                            <TableCell>
                                <a target='_blank' href={getViewLink(it) || getDownloadLink(it)}>
                                    View ({it.extension || 'link'})
                                </a>
                            </TableCell>
                            <TableCell>
                                <Typography variant='h6'>
                                    {it.name}
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <IconButton onClick={() => setFileToRemove(it)} sx={{cursor: 'pointer'}}>
                                    <DeleteForever color='primary' fontSize='large'/>
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    )}
                    <TableRow>
                        <TableCell>
                            <TextField label='Name' inputRef={name}/>
                        </TableCell>
                        <TableCell>
                            <TextField label='Link' inputRef={link}/>
                        </TableCell>
                        <TableCell>
                            <IconButton sx={{cursor: 'pointer', padding: 'inherit'}}
                                        onClick={addAttachment}>
                                <Add fontSize='medium' color='primary'/>
                            </IconButton>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>];
}
export default Downloads
