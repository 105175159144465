import {Link as RouterLink, useLocation} from "react-router-dom";
import {Breadcrumbs, Link} from "@mui/material";
import Typography from "@mui/material/Typography";
import {capitalize} from "lodash";

const AdminBreadcrumbs = () => {
    const path = useLocation().pathname.split('/').slice(1);
    return <Breadcrumbs sx={{marginTop: '15px', marginLeft: '15px'}} aria-label="breadcrumb">
        {path.map((it, i) => {
            const last = i === path.length - 1;
            const to = `/${path.slice(0, i + 1).join('/')}`;
            if (last && path.indexOf('template') !== -1) {
                it = it.substring(it.indexOf('-') + 1);
            }
            return last ? <Typography color="text.primary" key={to}>
                    {capitalize(decodeURI(it))}
                </Typography>
                : <Link component={RouterLink} underline="hover" color="inherit" to={to} key={to}>
                    {capitalize(it)}
                </Link>;
        })}
    </Breadcrumbs>
}
export default AdminBreadcrumbs