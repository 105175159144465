import FactoryTable from "./FactoryTable";
import {deliveryNote, itemsEqual} from "../../common/cart";
import {Trans, useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {groupBy} from "lodash";
import {useDeliveryData} from "../common/context/DeliveryDataContext";

const ItemsTable = ({items, onChange, editable, showAll = false}) => {
    onChange ??= () => false
    const f1 = items.some(it => it.factory === 1)
    const f2 = items.some(it => it.factory === 2)
    const {t} = useTranslation(['translation', 'note'])

    const {calculateDelivery, currency} = useDeliveryData()

    const [note, setNote] = useState(deliveryNote(items))
    const [deliveryPrice, setDeliveryPrice] = useState([0, 0])

    useEffect(() => {
        const priceItems = groupBy(items, 'factory')
        if (Object.entries(priceItems).length > 1) {
            setDeliveryPrice([
                calculateDelivery(priceItems[1]),
                calculateDelivery(priceItems[2])
            ])
        }
    }, [items, currency])

    /**
     * @param {{}||{}[]}changedItems
     * @param remove {boolean}
     */
    const changed = (changedItems, remove) => {
        if (!Array.isArray(changedItems)) {
            changedItems = [changedItems]
        }
        let noteItems = items
        if (remove) {
            noteItems = items.filter(it => !changedItems.some(s => itemsEqual(it, s)))
        }
        setNote(deliveryNote(noteItems))
        noteItems = groupBy(noteItems, 'factory')
        if (Object.keys(noteItems).length > 1) {
            setDeliveryPrice([
                calculateDelivery(noteItems[1]),
                calculateDelivery(noteItems[2])
            ])
        }
        onChange(changedItems, remove)
    }

    return [
        f1 || showAll ? <h2 key='f1-header' className="order--title">{t('factory')} 1</h2> : null,
        f1 || showAll ?
            <FactoryTable editable={editable} key='factory-1' items={items} factory={1} onChange={changed}/> : null,
        f2 || showAll ? <h2 key='f2-header' className="order--title">{t('factory')} 2</h2> : null,
        f2 || showAll ?
            <FactoryTable editable={editable} key='factory-2' items={items} factory={2} onChange={changed}/> : null,
        <div hidden={!(f1 && f2)} key='delivery-price'
             className='container order--description'>
            <div className='' style={{paddingBottom: '6px'}}>
                <p>{t('delivery_note_1')}</p>
                <p>{t('delivery_note_2')}</p>
            </div>
            <div className="" style={{paddingBottom: '6px'}}>
                <p>
                    <Trans i18nKey='delivery_note_12' t={t} price={deliveryPrice[0]}>
                        Delivery fee (approximate) from Factory 1 to Factory
                        2: <strong>{{price: deliveryPrice[0]}}</strong> USD
                    </Trans>
                </p>
                <p>
                    <Trans i18nKey='delivery_note_21' t={t} price={deliveryPrice[0]}>
                        Delivery fee (approximate) from Factory 2 to Factory
                        1: <strong>{{price: deliveryPrice[1]}}</strong> USD
                    </Trans>
                </p>
            </div>
            <div className=''>
                <p hidden={items.length === 0} className="order--description" key='note'>
                    {t(note[0], note[1])}
                </p>
            </div>
        </div>,
    ]
}
export default ItemsTable
