import i18next from "i18next";
import BrowserLanguageDetector from "i18next-browser-languagedetector";
import {initReactI18next} from "react-i18next";
import I18NextHttpBackend from "i18next-http-backend";
import {i18nextPlugin} from "translation-check";
import I18NextChainedBackend from "i18next-chained-backend";
import Fetch from 'i18next-fetch-backend';
import {backendAddress} from "./backend";

i18next
    .use(BrowserLanguageDetector)
    .use(initReactI18next)
    .use(I18NextChainedBackend)
    .use(i18nextPlugin)
    .init({
        debug: false,
        fallbackLng: {
            uk: ['ru'],
            ru: ['en'],
            es: ['en'],
            default: ['en'],
        },
        interpolation: {
            escapeValue: false,
        },
        backend: {
            backends: [
                I18NextHttpBackend,
                Fetch,
            ],
            backendOptions: [
                {},
                {
                    loadPath: backendAddress.replace('/api/', '') + '/storage/translation/{{lng}}/{{ns}}.json'
                }
            ]
        }
    })
