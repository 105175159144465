import {useEffect, useState} from "react";
import {get, PATH_ATTACHMENT_SHOW_DOWNLOADS} from "../../../common/backend";
import {useTranslation} from "react-i18next";
import {useLoader} from "../../../common/Loader";
import File from "./File";

const Downloads = () => {
    const {t} = useTranslation()
    document.title = t('page_downloads') + ' | R!met.CN'

    const [files, setFiles] = useState(null)
    const {setLoading} = useLoader()

    useEffect(() => {
        if (files === null) {
            get(PATH_ATTACHMENT_SHOW_DOWNLOADS, res => setFiles(res.data)).finally(() => setLoading(false))
        }
    })

    //https://docs.google.com/document/d/1OLqrZMOdd9aaEuMuuP2I-zeafqehzuLDuAHzbRR2f3c/edit
    //https://docs.google.com/document/u/0/export?format=docx&id=1OLqrZMOdd9aaEuMuuP2I-zeafqehzuLDuAHzbRR2f3c&token=AC4w5VjwrHe9eLw_8UDIE03bYikMUZtlFA%3A1679863013320&includes_info_params=true&cros_files=false

    //https://docs.google.com/document/u/0/export?format=docx&id={ID}


    return <article key='main' className="col-12 col-xl-9 content downloads">
        <div className="row">
            <div className="col-12">
                <h2 className="banner--title">{t('downloads')}</h2>
            </div>
            <div className="downloads-wrap">
                {(files || []).map(it => <File key={it.id} file={it}/>)}
            </div>
        </div>
    </article>
}
export default Downloads
