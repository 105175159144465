import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router";

const NotFoundPage = () => {
    document.title = '404 | R!met.CN'

    const {t} = useTranslation()
    const navigate = useNavigate()

    return <article key='main' className="col-12 col-xl-9 content not-found">
        <div className="row">
            <div className="banner col-12">
                <h2 className="banner--title">
                    {t('404_header')}
                </h2>
            </div>
            <div className="col-12 not-found--sign">
                <span>404</span>
            </div>
            <div className="col-12 not-found--text">
                <span>{t('page_doesnt_exist')}</span>
            </div>
            <div className="col-12 not-found--navigation">
                <button onClick={() => navigate('/')} className="feedback-form--submit">{t('return_main')}</button>
                <button onClick={() => navigate('/contact')}
                        className="feedback-form--submit">{t('page_contact')}</button>
            </div>
        </div>
    </article>
}
export default NotFoundPage
