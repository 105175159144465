import {useLoader} from "../../../common/Loader";
import * as React from "react";
import {useEffect, useState} from "react";
import {deleteR, get, PATH_GET_ALL_SCHEMAS, PATH_SAVE_SCHEMAS, PATH_SCHEMA, post} from "../../../common/backend";
import {Fab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField} from "@mui/material";
import {Add, Delete, Save, Send} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import {Link} from "react-router-dom";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import {toast} from "react-hot-toast";
import {clone, groupBy} from "lodash";
import {getAdminPageTitle} from "../../../common/commons";

const SchemasPage = () => {
    document.title = getAdminPageTitle('Patters')

    const {setLoading} = useLoader()
    const [schemas, setSchemas] = useState(null)
    const [deleteSchema, setDeleteSchema] = useState(null)

    const loadSchemas = () => {
        get(PATH_GET_ALL_SCHEMAS, res => {
            setSchemas(res.data.data.map((it, i) => {
                it.index = i
                return it
            }))
            setLoading(false)
        })
    }

    useEffect(() => {
        if (schemas === null) {
            loadSchemas()
        }
    })

    const save = () => {
        const errors = [];
        Object.values(groupBy(schemas, function (it) {
            return it.name.toLowerCase();
        })).forEach(it => {
            if (it[0].name === '') {
                errors.push('Empty name!');
            }
            if (it.length > 1) {
                errors.push(`Duplicated name (${it[0].name})`);
            }
        })
        if (errors.length > 0) {
            toast.error(errors.join('; '));
            return
        }
        setLoading(true)
        post(PATH_SAVE_SCHEMAS, res => {
            setSchemas(res.data.data.map((it, i) => {
                it.index = i
                return it
            }));
            setLoading(false);
            toast.success('Saved!')
        }, undefined, {data: schemas})
    }

    return [
        <Dialog
            key='delete-dialog'
            open={deleteSchema !== null}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Deleting schema"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Delete schema {deleteSchema ? deleteSchema.name : ''} ?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setDeleteSchema(null)}>No</Button>
                <Button onClick={() => {
                    const newSchemas = clone(schemas);
                    newSchemas.splice(deleteSchema.index, 1);
                    newSchemas.forEach((it, i) => it.index = i)
                    if (deleteSchema.id > 0) {
                        setLoading(true)
                        deleteR(PATH_SCHEMA + deleteSchema.id, () => {
                            toast.success(`Schema ${deleteSchema.name} deleted!`)
                            setLoading(false)
                        })
                    } else {
                        toast.success(`Schema ${deleteSchema.name} deleted!`)
                    }
                    setSchemas(newSchemas);
                    setDeleteSchema(null);
                }} autoFocus>
                    Yes
                </Button>
            </DialogActions>
        </Dialog>,
        <TableContainer key='main-container'>
            <Table style={{maxWidth: '30%', textAlign: 'center', margin: 'auto'}} size='small'>
                <TableHead>
                    <TableRow>
                        <TableCell>Material</TableCell>
                        <TableCell/>
                        <TableCell/>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {(schemas || []).map(it => <TableRow key={`schema-${it.name}`}>
                            <TableCell>
                                <TextField
                                    inputProps={{style: {fontSize: '0.8 rem', height: 20, width: 'fit-content'}}}
                                    defaultValue={it.name} onChange={e => it.name = e.target.value.trim()}/>
                            </TableCell>
                            <TableCell>
                                <IconButton onClick={() => setDeleteSchema(it)}><Delete color='primary'/></IconButton>
                            </TableCell>
                            <TableCell>
                                {it.id > 0 ?
                                    <IconButton component={Link} to={`/admin/material-template/${it.id}-${it.name}`}><Send
                                        color='primary'/></IconButton> : ''}
                            </TableCell>
                        </TableRow>
                    )}
                    <TableRow>
                        <TableCell>
                            <IconButton onClick={() => {
                                const newSchemas = clone(schemas)
                                newSchemas.push({id: -1, name: '', index: newSchemas.length})
                                setSchemas(newSchemas)
                            }}>
                                <Add/>
                            </IconButton>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>,
        <Fab key='save-button' sx={{
            position: "fixed",
            bottom: (theme) => theme.spacing(2),
            right: (theme) => theme.spacing(2)
        }}
             onClick={save.bind(this)} color="primary"><Save/></Fab>
    ];
}
export default SchemasPage
