import {createContext, useContext, useState} from "react";
import {Backdrop, CircularProgress} from "@mui/material";

const LoaderContext = createContext({isOpen: true})

const useLoader = () => {
    const context = useContext(LoaderContext)
    if (!context) throw new Error("useLoader must be used inside LoaderProvider")
    return context
}

const Loader = ({children}) => {
    const [loading, setLoading] = useState(false)
    return <LoaderContext.Provider
        value={{isOpen: loading, hide: () => setLoading(false), show: () => setLoading(true), setLoading: setLoading}}>
        <Backdrop style={{zIndex: 9000}} open={loading}>
            <CircularProgress/>
        </Backdrop>
        {children}
    </LoaderContext.Provider>
}

export {useLoader, Loader}
