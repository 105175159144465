import {useTranslation} from "react-i18next";
import {useState} from "react";
import axios from "axios";

const ContactUs = () => {
    const {t} = useTranslation()
    document.title = t('page_contact') + ' | R!met.CN'
    const [data, setData] = useState('')

    const text = axios.get('/contact.html').then(res => setData(res.data))

    return <article className="col-12 col-xl-9 content search-results">
        <div className="row">
            <div style={{padding: '10px'}} className="col-12 contact-us--table"
                 dangerouslySetInnerHTML={{__html: data}}/>
            {/*<div className="col-12" style={{margin: '10px 0 10px 0'}}>
                    <h2>{t('feedback')}</h2>
                </div>
                <form onSubmit={e => {
                    e.preventDefault();
                    submit();
                }}>
                    <div className="feedback-form col-12 col-md-10">
                        <div className="feedback-form--subject">
                            <div className="form-item" id="edit-subject-wrapper">
                                <label htmlFor="edit-subject">{t('subject')}: <span className="form-required"
                                                                                    title="This field is required.">*</span></label>
                                <input required onChange={handleChange} type="text" name="subject" id="edit-subject"
                                       defaultValue=''
                                       title="Subject ..."
                                       className="form-text required"/>
                            </div>
                        </div>
                        <div className="feedback-form--name">
                            <div className="form-item" id="edit-your-name-wrapper">
                                <label htmlFor="edit-your-name">{t('your_name')}: <span className="form-required"
                                                                                        title="This field is required.">*</span></label>
                                <input required onChange={handleChange} minLength={1} type="text" name="name"
                                       id="edit-your-name"
                                       defaultValue=''
                                       title="Your name ..."
                                       className="form-text required"/>
                            </div>
                        </div>
                        <div className="feedback-form--e-mail">
                            <div className="form-item" id="edit-your-email-wrapper">
                                <label htmlFor="edit-your-email">{t('email_address')}: <span className="form-required"
                                                                                             title="This field is required.">*</span></label>
                                <input required onChange={handleChange} type="email" name="your_email"
                                       id="edit-your-email"
                                       defaultValue=''
                                       title="Your e-mail address ..." style={{width: '100%'}}
                                       className="form-text required"/>
                            </div>
                        </div>
                        <input type="submit" name="op" id="edit-submit-2" value={t('send_message')}
                               className="feedback-form--submit"/>

                        <div className="feedback-form--message" id="edit-message-wrapper">
                            <label htmlFor="edit-message">{t('message')}: <span className="form-required"
                                                                                title="This field is required.">*</span></label>
                            <textarea style={{marginTop: '3px'}} required name="message" id="edit-message"
                                      title="Message ..."
                                      className="form-textarea required"
                                      cols="5"
                            />
                        </div>
                    </div>
                </form>*/}
        </div>
    </article>
}
export default ContactUs
