import {useEffect, useRef, useState} from "react";
import {getCabinetHandleFilters, setCabinetHandleFilters} from "../../common/commons";
import {KeyboardArrowUp} from "@mui/icons-material";
import {useTranslation} from "react-i18next";
import {capitalize} from "lodash";

const Filter = ({filters, reload}) => {

    const {t} = useTranslation()

    let filtersActive = 0

    const getDefaultFilters = (reset = false) => {
        const all = Object.fromEntries(Object.entries(filters).map(([k, v]) => [k, Object.fromEntries(v.map(it => [it, false]))]))
        if (!reset) {
            Object.entries(getCabinetHandleFilters()).forEach(([filter, values]) => {
                values.forEach(val => all[filter][val] = true)
            })
        }

        Object.entries(all).forEach(([filter, values]) => {
            Object.entries(values).forEach(([naming, value]) => {
                if (value) filtersActive++
            })
        })

        return all
    }

    //текущие фильтры, реф т.к. родительский компонент все равно обновится
    const state = useRef(getDefaultFilters())

    const [open, setOpen] = useState(false)

    const updateState = (filter, value) => {
        state.current[filter][value] = !state.current[filter][value]
        setCabinetHandleFilters(state.current)
        reload()
    }

    const reset = () => {
        state.current = getDefaultFilters(true)
        setCabinetHandleFilters(state.current)
        reload()
    }

    const FilterHeader = () => {
        return <div className="filter-top flex">
            <p className="filter--title flex active" onClick={() => setOpen(!open)}>
                {t('filter')}
                <span className={!filtersActive ? "filter--counter hidden" : "filter--counter"}>
                    &nbsp;: {filtersActive}
                </span>
                <KeyboardArrowUp style={{
                    transform: open ? '' : 'rotate(180deg)',
                    transition: '400ms',
                    backgroundColor: 'white',
                    fill: 'black',
                    // marginLeft: '15px',
                    height: '29px',
                    width: '29px',
                    borderRadius: '50%',
                    marginLeft: '0.75ch'
                }}/>
            </p>
            <p hidden={!filtersActive} className="filter--reset" onClick={reset}>{t('reset_filters')}</p>
        </div>
    }

    useEffect(() => {
        let parent = document.querySelector('.content-header')
        if (open) {
            parent.classList.add('content-header--opened')
        } else {
            parent.classList.remove('content-header--opened')
        }
    }, [open])

    return <div className="filter flex flex-wrap">
        <FilterHeader/>
        <div style={{
            display: open ? 'inline-block' : 'none'
        }} className="filters flex">
            <FilterHeader/>
            {Object.entries(filters).map(([name, values]) =>
                <div className="filter-item" key={`spec-${name}`}>
                    <p className="filter-item--title">{capitalize(t(name.toLowerCase()))}:</p>
                    <div className="filter-points">
                        {values.map(val => {
                            let valName = val
                            if (name === 'kit') valName = valName ? 'Yes' : 'No'
                            if (name === 'popularity') {
                                valName = valName.toString()
                                if (valName === '1') {
                                    valName = 'Archive'
                                } else if (valName === '2') {
                                    valName = 'Standard'
                                } else if (valName === '3') {
                                    valName = 'Popular'
                                }
                            }
                            valName = capitalize(t(valName.toLowerCase().replace(' ', '_'))).replace('_', ' ')
                            return <div className="filter-point" key={`${name}-${val}-${state.current[name][val]}`}>
                                <input onChange={() => updateState(name, val)} defaultChecked={state.current[name][val]}
                                       className="filter--input"
                                       type="checkbox"/>
                                <span className="filter--mask"/>
                                <p>{valName}</p>
                            </div>
                        })}
                    </div>
                </div>)}
        </div>
    </div>
}
export default Filter
