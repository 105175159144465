import {Checkbox, Table, TableBody, TableContainer, TableRow} from "@mui/material";
import {last, sortBy} from "lodash";
import * as React from "react";
import {useMemo, useRef} from "react";
import {useTranslation} from "react-i18next";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import Item from "./Item";
import {useAuth} from "../../../common/auth/AuthContext";

const allColumns = {
    item_code: 'code',
    catalog_code2: 'catalog_number',
    goods_name: 'name',
    carton_qty: 'box_count',
    small_carton_qty: 'small_box_count',
    units: 'measure_unit',
    packing_size: 'box_size',
    net_weight: 'box_net',
    gross_weight: 'box_gross',
    supplier_price: 'supplier_price'
}

const DescriptionTable = ({data, filters, setSelect, setSupplierPriceItem, cartItems}) => {

    const {t} = useTranslation(['translation', 'name'])

    const isAdmin = (useAuth().user || {}).isAdmin ?? false

    const columns = useMemo(
        () => {
            if (data.length === 0) {
                return []
            }
            const list = Object.entries(allColumns).filter(([_, v]) => data.some(it => v === 'small_box_count' ? it[v] && parseInt(it[v]) > 1 : it[v]))
            if (last(list)[0] === 'supplier_price' && isAdmin) {
                list.pop()
                list.push(['quantity', null], ['select', null], ['supplier_price', 'supplier_price'])
            } else {
                list.push(['quantity', null], ['select', null])
            }
            return list
        },
        [data, isAdmin]
    )

    const refs = useRef({})

    const selectAll = val => {
        Object.values(refs.current).filter(it => it !== null).forEach(it => it.setSelected(val))
    }

    return <TableContainer sx={{maxWidth: '100%'}}>
        <Table width='500px' style={{fontFamily: 'inherit', maxWidth: '100%', tableLayout: 'auto'}}>
            <TableHead style={{backgroundColor: '#ececec'}}>
                <TableRow>
                    {columns.map(([name]) => {
                        let val = null
                        if (name === 'select') {
                            val = <Checkbox onChange={e => selectAll(e.target.checked)}/>
                        }
                        if (val === null) {
                            val = t(name)
                        }
                        if (name === 'select' && data.filter(it => it.schema === null).length === 0) {
                            return null;
                        }
                        return <TableCell align='center' className='param--title param--title_second' key={'h-' + name}>
                            {val}
                        </TableCell>
                    })}
                </TableRow>
            </TableHead>
            <TableBody>
                {sortBy(data.filter(it => filters.length === 0 || filters.includes(it.filter)),
                    it => it.index)
                    .map(item => <Item t={t} key={item.code} ref={it => {
                        if (it === null) {
                            return
                        }
                        refs.current[item.code] = it
                    }} item={item} columns={columns} setSelect={setSelect}
                                       setSupplierPriceItem={setSupplierPriceItem}
                                       cartItems={cartItems}
                    />)
                }
            </TableBody>
        </Table>
    </TableContainer>
}
export default DescriptionTable