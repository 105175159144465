import {useParams} from "react-router";
import * as React from "react";
import {useEffect, useState} from "react";
import {useLoader} from "../../../common/Loader";
import {attachmentsAddress, backendAddress, get, getErrorMessage, PATH_CATEGORY} from "../../../common/backend";
import {toast} from "react-hot-toast";
import {Dialog, Fab, Paper, TextField, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import {DropzoneDialog} from "mui-file-dropzone";
import IconButton from "@mui/material/IconButton";
import {DeleteForever, Save, SettingsBackupRestore} from "@mui/icons-material";
import {getAdminPageTitle, wrapTooltip} from "../../../common/commons";
import axios from "axios";
import {getAPIKey} from "../../../common/auth/AuthContext";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import {isEmpty} from "lodash";

const CategoryEditor = () => {
    const cid = useParams().id
    document.title = getAdminPageTitle('Categories')

    const [data, setData] = useState({})
    const [dropzoneOpen, setDropzoneOpen] = useState(false)
    const {setLoading} = useLoader()

    const [newBanner, setNewBannerReal] = useState(null)
    const [newBannerFile, setNewBannerFile] = useState(null)
    const [deleteBanner, setDeleteBanner] = useState(false)

    const [dialogOpen, setDialogOpen] = useState(false)

    const currentImage = data.banner ? `${attachmentsAddress}${data.banner.id}/full.${data.banner.extension}` : '/images/no-image.jpg'

    const setNewImage = file => {
        setNewBannerFile(file)
        const reader = new FileReader()
        reader.addEventListener('load', () => setNewBannerReal(reader.result))
        reader.readAsDataURL(file)
    }

    const load = () => {
        setLoading(true)
        get(PATH_CATEGORY + cid, res => {
            setData(res.data)
        }, () => toast.error('Error. Try Again')).finally(() => setLoading(false))
    }

    useEffect(load, [cid])

    const closeDialog = () => setDialogOpen(false)

    const trySave = () => {
        if (deleteBanner && newBannerFile === null) {
            setDialogOpen(true)
            return
        }
        save()
    }

    const save = () => {
        setLoading(true)
        const formData = new FormData()
        if (!isEmpty(data.description)) {
            formData.append('description', data.description)
        }
        if (newBannerFile !== null) {
            formData.append('banner', newBannerFile)
        }
        if (deleteBanner) {
            formData.append('deleteBanner', '1')
        }
        axios.post(backendAddress + PATH_CATEGORY + cid, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + getAPIKey()
            },
            withCredentials: false
        })
            .then(() => toast.success('Saved!'))
            .catch(err => toast.error(getErrorMessage(err))).finally(() => {
            setDialogOpen(false)
            setNewBannerFile(null)
            setNewBannerReal(null)
            setDeleteBanner(false)
            load()
        })
    }

    return <Box sx={{
        marginTop: '25px',
        width: '500px',
        height: '',
        marginLeft: 'auto',
        marginRight: 'auto',
        textAlign: 'center'
    }} component={Paper}>
        <Dialog onClose={closeDialog} open={dialogOpen}>
            <DialogTitle>Remove current banner?</DialogTitle>
            <DialogContent>
                Are you sure you want to remove current banner?
            </DialogContent>
            <DialogActions>
                <Button onClick={closeDialog}>No</Button>
                <Button onClick={save}>Yes</Button>
            </DialogActions>
        </Dialog>
        <DropzoneDialog maxFileSize={104857600}
                        acceptedFiles={['image/jpeg', 'image/png', 'image/gif', 'image/webp']}
                        onClose={() => setDropzoneOpen(false)}
                        onSave={file => {
                            setDropzoneOpen(false)
                            setNewImage(file[0])
                        }}
                        clearOnUnmount={true} filesLimit={1}
                        dialogTitle='Upload banner'
                        dropzoneText='Drop new banner here'
                        open={dropzoneOpen}/>

        <Typography style={{textAlign: 'center'}} variant="h6">
            Category <span style={{fontWeight: 'bold'}}>{data.name}</span>. Factory {data.factory}
        </Typography>
        <Typography style={{marginTop: '25px', textAlign: 'center'}}>Description</Typography>
        <TextField label='Category Description' defaultValue={data.description || ''}
                   onChange={e => {
                       data.description = e.target.value
                       console.log(data)
                   }}
                   style={{width: 'calc(100% - 50px)', marginTop: '5px', marginBottom: '25px'}} rows={5}
                   multiline={true} placeholder='Category Description'/>
        <div style={{textAlign: 'right'}}>
            <Typography style={{marginBottom: '5px', textAlign: 'center'}}>Banner Image (Click for change)</Typography>
            {wrapTooltip('Remove current banner', <IconButton style={{color: deleteBanner ? 'red' : ''}}
                                                              onClick={() => setDeleteBanner(!deleteBanner)}><DeleteForever/></IconButton>)}
            {newBanner ? wrapTooltip('Restore', <IconButton
                onClick={() => setNewBannerReal(null)}><SettingsBackupRestore/></IconButton>) : null}
        </div>
        <img onClick={() => setDropzoneOpen(true)} style={{cursor: 'pointer', textAlign: 'center', border: 'solid'}}
             src={newBanner ? newBanner : currentImage}/>
        <Fab variant='extended' key='goto-button'
             onClick={trySave}
             sx={{
                 position: "fixed",
                 bottom: (theme) => theme.spacing(2),
                 right: (theme) => theme.spacing(2),
                 top: (theme) => theme.spacing(10)
             }} color="primary"><Save sx={{mr: 1}}/>Save</Fab>
    </Box>
}
export default CategoryEditor