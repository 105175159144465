import AdminHeader from "../other/AdminHeader";
import AdminBreadcrumbs from "../other/AdminBreadcrumbs";
import {Navigate, useLocation} from "react-router-dom";
import {useAuth} from "../../common/auth/AuthContext";
import {ROLE_ADMIN, ROLE_SUPER_ADMIN} from "../../common/backend";
import {toast} from "react-hot-toast";
import {startCase} from "lodash";
import {adminPageTitle, getAdminPageTitle} from "../../common/commons";

const pages = {
    Specs: 'edit specs',
    'Material Template': 'edit specs',
    Products: 'product view',
    'Supplier Price': 'product supplier price',
    // 'Supplier Price Import History': 'product supplier price edit',
    Downloads: 'downloads',
    Users: '*',
    Orders: 'admin',
    'Main Page': 'admin',
    Categories: 'admin',
    Info: 'admin',
    'Setting': 'admin'
};

const BasePage = ({page}) => {
    if (!document.title.includes(adminPageTitle)) {
        document.title = getAdminPageTitle()
    }
    const path = useLocation().pathname.replace('/admin/', '').replace('/admin', '').split('/')[0]

    const {authorized, user} = useAuth()
    if (authorized === null) {
        return ''
    }
    if (authorized === false) {
        return <Navigate to="/admin/login"/>
    }
    if (user.role !== ROLE_ADMIN && user.role !== ROLE_SUPER_ADMIN) {
        toast.error('You`re not an admin')
        return <Navigate to="/"/>
    }
    if (path.length > 1 && !user.hasPerm(pages[startCase(path).replace('-', ' ')])) {
        toast.error('No permission')
        return <Navigate to='/admin'/>
    }

    return [
        <AdminHeader pages={pages} key={'header'}/>,
        <AdminBreadcrumbs key={'breadcrumbs'}/>,
        page
    ];
}

export default BasePage;
