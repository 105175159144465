import {CircularProgress, ClickAwayListener} from "@mui/material";
import {useRef, useState} from "react";
import {useNavigate} from "react-router";
import axios from "axios";
import {attachmentsAddress, backendAddress, PATH_PRODUCT_SEARCH} from "../../../common/backend";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

const Search = (props) => {
    const [searchOpen, setSearchOpen] = useState(false)
    const [results, setResults] = useState([])

    const searchElement = useRef(null);

    const navigate = useNavigate()
    const {t} = useTranslation()

    const [currentRequest, setCurrentRequest] = useState(null)
    const abortController = useRef(new AbortController())

    const loadSearch = () => {
        const query = searchElement.current.value
        if (currentRequest !== null) {
            abortController.current.abort();
            abortController.current = new AbortController();
        }
        if (!query || query.length < 2) {
            setSearchOpen(false)
            return;
        } else {
            setSearchOpen(true)
        }
        setCurrentRequest(axios.get(backendAddress + PATH_PRODUCT_SEARCH + query + '&limit=5', {
                withCredentials: false,
                signal: abortController.current.signal
            }).then(res => {
                setResults(res.data.data)
                setCurrentRequest(null)
            })
        )
    }

    const getSearchElement = (data) => {
        let image = '/images/no-image.png'
        if (data.attachments.length !== 0) {
            const it = data.attachments[0]
            image = `${attachmentsAddress}/${it.id}/card.jpg`
        }
        return <Link onClick={() => {
            setSearchOpen(false)
            props.handleChange()
        }}
                     key={data.catalog_number}
                     to={`/${data.catalog_number}`}
                     className="qurey-result-peek--item">
            <img src={image} alt=""/>
            <div>
                <p className="item-code">{data.catalog_number}</p>
                <p className="item-description"></p>
            </div>
        </Link>
    }

    return <ClickAwayListener onClickAway={() => setSearchOpen(false)}>
        <>
            <form className="search-form" action="src/desktop/common/header/Header" onSubmit={e => {
                setSearchOpen(false)
                e.preventDefault();
                navigate(`/s?q=${searchElement.current.value}`);
            }}>
                <input ref={searchElement} className="search--input" type="text"
                       placeholder={t('im_searching')}
                       onClick={e => (e.target.value || '').length > 1 && setSearchOpen(true)}
                       onChange={() => loadSearch()}/>
                <input className="search--submit"
                       type="submit"
                       value={t('search')}
                       onClick={() => props.handleChange()}/>

                <div className={`query-results-peek ${searchOpen ? '' : 'hidden-peek'}`}>
                    {currentRequest !== null ? <CircularProgress sx={{margin: '5ch auto'}}/> :
                        results.map(it => getSearchElement(it))}
                    <button type="submit" onClick={() => props.handleChange()}>{t('all_results')}</button>
                </div>
            </form>
        </>
    </ClickAwayListener>
}
export default Search