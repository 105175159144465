import {Modal} from "@mui/material";
import {err, get, getErrorMessage, PATH_SEND_PASSWORD_RESET_TOKEN} from "../../../common/backend";
import {useLoader} from "../../../common/Loader";
import {LOGIN_2FA, useAuth} from "../../../common/auth/AuthContext";
import {useState} from "react";
import {toast} from "react-hot-toast";
import {Warning} from "@mui/icons-material";
import {useTranslation} from "react-i18next";
import {capitalize} from "lodash";

const LoginForm = ({open, close}) => {
    const {show, hide} = useLoader()

    const {login} = useAuth()
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [g2fa, setG2fa] = useState('')
    const [showG2fa, setShowG2fa] = useState(null)

    const {t} = useTranslation()

    const tryLogin = e => {
        e.preventDefault()
        if (showG2fa && g2fa.length !== 6) {
            toast.error(t('enter_otp'))
            return
        }
        show()

        const res = login(username, password, g2fa)
        if (res !== null) {
            res.then(status => {
                if (status === LOGIN_2FA) {
                    setG2fa('')
                    setShowG2fa(true)
                    toast.error(t('enter_otp'), {icon: <Warning sx={{color: 'red'}}/>})
                } else close()
            }).catch(err).finally(hide)
        }
    }

    const sendPasswordReset = e => {
        e.preventDefault()
        if (username.length === 0) {
            toast.error(t('enter_email'));
            return;
        }
        show()
        get(PATH_SEND_PASSWORD_RESET_TOKEN + username, () => toast.success(t('check_email')), res => toast.error(getErrorMessage(res))).finally(hide)
    }

    return <Modal open={open} onClose={close}>
        <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">{t('login')}</h5>
                    <button onClick={close} type="button" className="close" data-dismiss="modal"
                            aria-label={t('close')}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <form onSubmit={tryLogin}>
                    <div className="modal-body register-container--inner">
                        <label htmlFor="email">{capitalize(t('email_address'))}<br/>
                            <input style={{marginTop: '3px'}} value={username}
                                   onChange={e => setUsername(e.target.value)}
                                   type="email"
                                   name="email" required/>
                        </label>
                        <label htmlFor="password">{capitalize(t('password'))}<br/>
                            <input style={{marginTop: '3px'}} value={password}
                                   onChange={e => setPassword(e.target.value)}
                                   type="password"
                                   name="password" required minLength='8'/>
                        </label>
                        <label hidden={!showG2fa} htmlFor="otp">{t('google_2fa_otp')}<br/>
                            <input style={{marginTop: '3px'}} value={g2fa}
                                   onChange={e => setG2fa(e.target.value.replace(/\D/g, ''))}
                                   type="password"
                                   name="otp" required={showG2fa} minLength='6' maxLength='6'/>
                        </label>
                    </div>
                    <div className="modal-footer">
                        <div style={{clear: 'both', flex: 'auto'}}>
                            <button style={{float: 'left'}} onClick={sendPasswordReset}
                                    className="button ">{t('forgot_password')}</button>
                        </div>
                        <button type="button" className="button button-gray" data-dismiss="modal"
                                onClick={close}>{t('close')}
                        </button>
                        <button type="submit" className="button ">{t('login')}</button>
                    </div>
                </form>
            </div>
        </div>
    </Modal>
}
export default LoginForm