import {useLoader} from "../../../common/Loader";
import {deleteR, getErrorMessage, PATH_ORDER_DELETE} from "../../../common/backend";
import {toast} from "react-hot-toast";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";

const DeleteOrderDialog = ({open, order, close, onDelete}) => {
    order = order || {}

    const {setLoading} = useLoader()

    const deleteOrder = () => {
        setLoading(true)
        deleteR(PATH_ORDER_DELETE + order.id, () => {
            close()
            onDelete(order)
        }, res => toast.error(getErrorMessage(res))).finally(() => setLoading(false))
    }

    return <Dialog open={open} onClose={close}>
        <DialogTitle>Delete order {order.id} from {order.name} ({order.email})?</DialogTitle>
        <DialogActions>
            <Button onClick={close}>No</Button>
            <Button onClick={deleteOrder}>Yes</Button>
        </DialogActions>
    </Dialog>
}
export default DeleteOrderDialog
