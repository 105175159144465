import {useAuth} from "../../../common/auth/AuthContext";
import {Navigate} from "react-router-dom";
import {Backdrop, CircularProgress} from "@mui/material";
import {useEffect, useState} from "react";
import {get, PATH_ORDER_MY} from "../../../common/backend";
import {useLoader} from "../../../common/Loader";
import {toast} from "react-hot-toast";
import {isEmpty, round, uniq} from "lodash"
import OrderModal from "./OrderModal";
import {useTranslation} from "react-i18next";
import {calculateItemSize} from "../../../common/cart";

const Orders = () => {
    const {authorized, user} = useAuth()
    const [orders, setOrders] = useState(null)
    const [orderDetail, setOrderDetail] = useState(null)
    const {setLoading} = useLoader()
    const {t} = useTranslation()
    document.title = t('page_orders') + ' | R!met.CN'

    useEffect(() => {
        if (orders === null && authorized === true) {
            get(PATH_ORDER_MY, res => {
                setOrders(res.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at)))
            }, () => toast.error(t('error_try_again'))).finally(() => setLoading(false))
        }
    })
    if (authorized === null) {
        return ''
    }
    if (authorized === false) {
        return <Navigate to='/'/>
    }
    if (!user.email_verified_at) {
        toast.error(t('verify_email_first'))
    }
    if (orders === null) {
        return <Backdrop style={{zIndex: 9000}} open={true}>
            <CircularProgress/>
        </Backdrop>
    }

    const calculateItemsNumber = items => isEmpty(items) ? 0 : items.map(it => it.buy_count).reduce((prev, next) => prev + next)

    return <article className="col-12 col-xl-9 content">
        <OrderModal open={orderDetail !== null} order={orderDetail} close={() => setOrderDetail(null)}/>
        <h2 className="order--title">{t('orders')}</h2>
        <div className="col-12 card--information orders-table">
            <div className="row orders-table--header">
                <div className="param--title param--title_second"><span>№</span></div>
                <div className="param--title param--title_second"><span>{t('order_id')}</span></div>
                <div className="param--title param--title_second"><span>{t('order_date')}</span></div>
                <div className="param--title param--title_second"><span>{t('artsitems_count')}</span></div>
                <div className="param--title param--title_second"><span>{t('total_gw').toLowerCase()}</span></div>
                <div className="param--title param--title_second"><span>{t('total_cbm').toLowerCase()}</span></div>
                <div className="param--title param--title_second"><span>{t('last_rev_date').toLowerCase()}</span></div>
                <div className="param--title param--title_second"><span>{t('comment')}</span></div>
            </div>

            {orders.map((it, i) => {
                it.items.forEach(it => calculateItemSize(it));
                return <div className="row orders-table--item" key={`order-${it.id}`}
                            onClick={() => setOrderDetail(it)}>
                    <div className="param--value"><span>{i + 1}</span></div>
                    <div className="param--value"><span>{it.id}</span></div>
                    <div className="param--value"><span>{it.created_at.split('T')[0]}</span></div>
                    <div className="param--value param--value_underline">
                        <span>{uniq(it.items.map(it => it['catalog_number'])).length}/{calculateItemsNumber(it.items)}</span>
                    </div>

                    <div className="param--value">
                        <span>{round(it.items.reduce((sum, val) => sum + val.total_gross_weight, 0), 2)}</span>
                    </div>

                    <div className="param--value">
                        <span>{round(it.items.reduce((sum, val) => sum + val.total_volume, 0), 2)}</span>
                    </div>

                    <div className="param--value">
                        <span>{new Date(it.updated_at).toUTCString()}</span>
                    </div>

                    <div className="param--value"><span>{it.comment}</span></div>
                </div>
            })}
        </div>
    </article>
}
export default Orders