import {useAuth} from "../../common/auth/AuthContext";
import {useLoader} from "../../common/Loader";
import {Navigate} from "react-router-dom";
import {toast} from "react-hot-toast";
import {MarkEmailRead, MoveToInbox, RemoveModerator, Security} from "@mui/icons-material";
import Button from "@mui/material/Button";
import AddGoogle2FAModal from "./google2fa/AddGoogle2FAModal";
import {useEffect, useState} from "react";
import RemoveGoogle2FAModal from "./google2fa/RemoveGoogle2FAModal";
import {get, getErrorMessage, PATH_RESEND_EMAIL_VERIFICATION_CODE} from "../../common/backend";
import {useTranslation} from "react-i18next";
import {capitalize, isEmpty} from "lodash";

const Cabinet = () => {
    let {authorized, user} = useAuth()
    const {setLoading} = useLoader()
    const {t} = useTranslation()
    document.title = t('page_cabinet') + ' | R!met.CN'

    const [g2faOpen, setG2faOpen] = useState(false)
    const [removeG2faOpen, setRemoveG2faOpen] = useState(false)

    useEffect(() => {
        if (authorized === true) {
            setLoading(false)
        }
    }, [authorized])
    if (authorized === null) {
        return '';
    }
    if (authorized === false) {
        toast.error(t('login_first'))
        return <Navigate to='/'/>
    }
    const resendCode = () => {
        setLoading(true)
        get(PATH_RESEND_EMAIL_VERIFICATION_CODE, () => toast.success(t('done')), res => toast.error(getErrorMessage(res))).finally(() => setLoading(false))
    }
    user = user || {}

    return <article className="col-12 col-xl-9 content cabinet">
        <AddGoogle2FAModal open={g2faOpen} close={() => setG2faOpen(false)}/>
        <RemoveGoogle2FAModal open={removeG2faOpen} close={() => setRemoveG2faOpen(false)}/>
        <h2 className="order--title">{t('personal_data')}</h2>

        {['full_name', 'company', 'country', 'city', 'phone', 'email'].map(it =>
            <label key={it} htmlFor={it}>
                <span>{t(it).split(' ').map(it => capitalize(it)).join(' ')}</span>
                <input value={user[it]} id={it} readOnly type="text"/>
            </label>
        )}
        <label>
            <span>{t('email_verification_status')}</span>
            {(isEmpty(user.email_verified_at)) ?
                <Button onClick={resendCode} sx={{color: 'red'}}
                        endIcon={<MoveToInbox color='primary'/>}>{t('not_verified_resend_code')}</Button> :
                <MarkEmailRead color='primary'/>}
        </label>
        <label>
            <span>{t('google_authenticator')}</span>
            {user.google_2fa_enabled ?
                <span>{t('youre_secured')}<Button onClick={() => setRemoveG2faOpen(true)} endIcon={
                    <RemoveModerator/>}>{t('remove')}</Button></span> :
                <Button onClick={() => setG2faOpen(true)} endIcon={<Security/>}>{t('add_google_2fa')}</Button>}
        </label>
        {/*<input type="submit" value="Submit" className="button"/>*/}
    </article>
}
export default Cabinet
