import {useState} from "react";
import {getItemImage} from "../../../common/commons";
import {useNavigate} from "react-router";

const Item = ({data, last, handleLinkClick}) => {
    const navigate = useNavigate()
    return <div
        style={{justifyContent: 'center', padding: '0', cursor: 'pointer', paddingBottom: last ? '15px' : '0px'}}
        onClick={() => {
            handleLinkClick();
            navigate('/' + data.catalog_number)
        }} key={data.catalog_number}
        className="sidebar-product--item flex">
        <div>
            <div className="product-item--image flex">
                <img style={{maxHeight: '170px'}} src={getItemImage(data)} alt="item-image"/>
            </div>
            <div className="product-item--info">
                <h4 className="product-item--name">{data.catalog_number}</h4>
            </div>
        </div>
    </div>
}

const ItemList = ({title, items, hidden, defaultOpen, handleLinkClick}) => {
    const [open, setOpen] = useState(defaultOpen)

    if (hidden) {
        return ''
    }

    return [
        <div key='header' className="submenu-wrap">
            <h3 className="sidebar-title">{title}</h3>
            <div className="submenu-toggle" style={{height: '56px'}} onClick={() => setOpen(!open)}>
                <i style={{color: 'white', transform: open ? 'rotate(180deg)' : '', paddingTop: '5px'}}
                   className="fas fa-chevron-down"/>
            </div>
        </div>,
        <div key='list' hidden={!open} className="sidebar-content">
            {(items || []).map((it, i) => <Item key={it.catalog_number}
                                                data={it}
                                                last={i === items.length - 1}
                                                handleLinkClick={() => handleLinkClick()}
            />)}
        </div>
    ]
}
export default ItemList