import {useMemo, useRef, useState} from "react";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import {Checkbox, FormControlLabel, FormGroup, InputLabel, Select, TextField} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";

const rotations = {
    0: 0,
    1: 90,
    2: 180,
    3: 270
}

const VideoUploadDialog = ({videoFile, onClose, upload}) => {
    const videoRef = useRef(null);
    const [startTime, setStartTime] = useState(0);
    const [endTime, setEndTime] = useState(0);
    const [rotation, setRotation] = useState(0);
    const [removeSound, setRemoveSound] = useState(false);
    const actualRotation = rotations[rotation];
    const videoSrc = useMemo(() => {
        if (!videoFile) {
            return null;
        }
        return URL.createObjectURL(videoFile);
    }, [videoFile])

    const handleSave = () => {
        upload(
            startTime,
            endTime,
            rotation,
            removeSound
        )
    };

    const handleTimeChange = (setter) => (e) => {
        let value = parseInt(e.target.value)
        if (isNaN(value)) {
            value = 0
        }
        setter(value);
    };


    return <Dialog open={!!videoFile} onClose={onClose} maxWidth="md" fullWidth>
        <DialogTitle>Upload video</DialogTitle>
        <DialogContent>
            {videoSrc && <div
                style={{
                    position: "relative",
                    width: "100%",
                    overflow: "hidden",
                    display: "flex",
                    justifyContent: "center",
                    transition: "height 0.3s ease-in-out",
                }}
            >
                <video
                    ref={videoRef}
                    src={videoSrc}
                    controls
                    style={{
                        maxWidth: "100%",
                        transform: `rotate(${actualRotation}deg)`,
                        transformOrigin: "center",
                        transition: "transform 0.3s ease-in-out",
                    }}
                />
            </div>}

            <div style={{marginTop: 20}}>
                <FormGroup sx={{display: 'inline'}}>
                    <TextField
                        variant='standard'
                        label="Start Time (seconds)"
                        type="number"
                        value={startTime}
                        onChange={handleTimeChange(setStartTime)}
                        InputProps={{inputProps: {min: 0}}}
                    />
                    <TextField
                        variant='standard'
                        label="End Time (seconds)"
                        type="number"
                        value={endTime}
                        onChange={handleTimeChange(setEndTime)}
                        InputProps={{inputProps: {min: 0}}}
                        style={{marginLeft: 20}}
                    />
                    <InputLabel id='rotate_label'>Rotate</InputLabel>
                    <Select variant='standard' labelId='rotate_label' label='Rotate' value={rotation}
                            onChange={(e) => {
                                setRotation(parseInt(e.target.value))
                            }}>
                        {Object.entries(rotations).map(([ffmpegValue, actual]) => (
                            <MenuItem key={ffmpegValue} value={ffmpegValue}>{actual}°</MenuItem>
                        ))}
                    </Select>
                    <FormControlLabel
                        control={<Checkbox
                            checked={removeSound}
                            onChange={(e) => setRemoveSound(e.target.checked)}/>
                        }
                        label="Remove sound"
                    />
                </FormGroup>
            </div>

        </DialogContent>
        <DialogActions>
            <Button onClick={onClose} color="secondary">
                Cancel
            </Button>
            <Button onClick={handleSave} color="primary">
                Upload video
            </Button>
        </DialogActions>
    </Dialog>
}

export default VideoUploadDialog;
