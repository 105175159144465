import {useParams} from "react-router";
import {useEffect, useState} from "react";
import {get, PATH_PRODUCT_RECOMMENDED, PATH_VIEW_PRODUCTS_BY_CATALOG_NUMBER} from "../../../common/backend";
import ItemList from "./ItemList";
import {useTranslation} from "react-i18next";
import {clone, isEmpty, sortBy} from "lodash";

const ItemsSidebar = ({handleLinkClick}) => {
    const productSKU = useParams().sku

    let last = localStorage.getItem('last-viewed')

    const [lastProducts, setLastProducts] = useState(null)
    const [recommended, setRecommended] = useState(null)

    const {t} = useTranslation()

    useEffect(() => {
        if (productSKU) {
            get(PATH_PRODUCT_RECOMMENDED + productSKU, res => {
                setRecommended(res.data)
            })
        }
    }, [productSKU])

    useEffect(() => {
        const listener = () => {
            if (lastProducts === null) {
                return
            }
            last = localStorage.getItem('last-viewed')
            if (lastProducts.some(it => !last.includes(it.catalog_number))) {
                reloadLastItems();
            } else {
                reorderLastItems()
            }
        };
        window.addEventListener('storage', listener)
        return () => window.removeEventListener('storage', listener)
    })

    useEffect(() => {
        if (lastProducts === null) {
            reloadLastItems()
        }
    }, [last, lastProducts])

    const reorderLastItems = () => {
        let lastNew = clone(lastProducts)
        lastNew = sortBy(lastNew, it => last.indexOf(it.catalog_number))
        setLastProducts(lastNew)
    }
    const reloadLastItems = () => {
        if (last === null) {
            setLastProducts([])
            return
        }
        get(PATH_VIEW_PRODUCTS_BY_CATALOG_NUMBER + last, res => {
                let lastNew = Object.values(res.data.data).map(it => it[0])
                lastNew = sortBy(lastNew, it => last.indexOf(it.catalog_number))
                setLastProducts(lastNew)
            }
        )
    }

    return [
        <ItemList key='recommended'
                  title={t('recommended')}
                  items={recommended}
                  defaultOpen={true}
                  hidden={isEmpty(recommended)}
                  handleLinkClick={() => handleLinkClick()}
        />,
        <ItemList key='last-viewed'
                  title={t('last_viewed')}
                  items={lastProducts}
                  defaultOpen={false}
                  handleLinkClick={() => handleLinkClick()}
        />
    ]
}
export default ItemsSidebar