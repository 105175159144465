import React, {useContext, useState} from "react";

export const ThemeContext = React.createContext(null)

export const useTheme = () => {
    const context = useContext(ThemeContext)
    if (!context) throw new Error("useAuth must be used inside AuthContext")
    return context
}

const ThemeProvider = ({children}) => {
    const [color, setColor] = useState('var(--clr-default)')
    const updateTheme = factory => {
        if (factory === 1) {
            setColor('var(--clr-blue)')
        } else {
            setColor('var(--clr-red)')
        }
    }
    return <ThemeContext.Provider children={children} value={{color, updateTheme}}/>
}
export default ThemeProvider
