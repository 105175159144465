import Dialog from "@mui/material/Dialog";
import {useLoader} from "../../../common/Loader";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {useRef} from "react";
import {Grid, TextField} from "@mui/material";
import {getErrorMessage, PATH_USERS_CREATE, post} from "../../../common/backend";
import {toast} from "react-hot-toast";

const CreateUserModal = ({open, onCreated, close}) => {

    const {setLoading} = useLoader()

    const data = useRef({})
    const formRef = useRef()

    const save = e => {
        e.preventDefault()
        setLoading(true)
        post(PATH_USERS_CREATE, res => {
            onCreated(res.data.email)
            close()
            toast.success('Done')
        }, res => toast.error(getErrorMessage(res)), data.current).finally(() => setLoading(false))
    }

    return <Dialog maxWidth='500px' open={open} onClose={close}>
        <DialogTitle>Create New User</DialogTitle>
        <DialogContent>
            <form autoComplete='off' style={{marginTop: 5}} onSubmit={save}>
                <Grid spacing={1} container direction='column'>
                    {['Full name', 'Email', 'Country', 'City', 'Company', 'Phone', 'Password'].map(it =>
                        <Grid item key={it}>
                            <TextField sx={{maxWidth: '170px'}} required={true}
                                       defaultValue=''
                                       autoComplete={it === 'Email' || it === 'Password' ? 'new-' + it.toLowerCase() : 'off'}
                                       id={it}
                                       label={it}
                                       type={it === 'Password' ? 'password' : it === 'Email' ? 'email' : 'text'}
                                       onChange={e => data.current[it.toLowerCase().replace(' ', '_')] = e.target.value}
                                       name={it}/>
                        </Grid>
                    )}
                </Grid>
                <input type='submit' hidden ref={formRef}/>
            </form>
        </DialogContent>
        <DialogActions>
            <Button onClick={close}>Cancel</Button>
            <Button onClick={() => formRef.current.click()}>Save</Button>
        </DialogActions>
    </Dialog>
}
export default CreateUserModal;