import IconButton from "@mui/material/IconButton";
import {removeItemsFromCart} from "../../common/cart";
import {DeleteForever} from "@mui/icons-material";
import {useTranslation} from "react-i18next";
import {isEmpty} from "lodash";
import {Link} from "react-router-dom";

const SelectedItems = ({cartItems, updateCart}) => {
    const {t} = useTranslation(['translation', 'name'])
    if (isEmpty(cartItems)) {
        return ''
    }
    const schemas = ['color', 'ceramic_color', 'pattern'].filter(it => cartItems.some(item => it in item))

    return <div className="col-12 card--order-list">
        <div className="row">
            <h2>{t('selected_items')}</h2>
        </div>
        <div className="row whitesided-table">
            <div className="col-12 card--information">
                <div className="row">
                    <div className="param--title param--title_second">
                        <span>{t('item_code')}</span>
                    </div>
                    <div className="param--title param--title_second">
                        <span>{t('goods_name')}</span>
                    </div>
                    {schemas.map(it =>
                        <div key={it} className="param--title param--title_second">
                            <span>{t(it).toLowerCase()}</span>
                        </div>
                    )}
                    <div className="param--title param--title_second">
                        <span>{t('cartons')}</span>
                    </div>
                    <div className="param--title param--title_second">
                        <span>{t('order_quantity')}</span>
                    </div>
                    <div className="param--title param--title_second">
                        <span>{t('net_weight')}</span>
                    </div>
                    <div className="param--title param--title_second">
                        <span>{t('gross_weight')}</span>
                    </div>
                    <div className="param--title param--title_second">
                        <span>{t('delete')}</span>
                    </div>
                </div>
                {cartItems.map(it =>
                    <div key={it.code} className="row">
                        <div className="param--value"><span className='default-font'>{it.code}</span>
                        </div>
                        <div className="param--value">
                            <span className='default-font'>{t(it.name, {ns: 'name'})}</span>
                        </div>
                        {schemas.map(k =>
                            <div key={it.code + k} className="param--value">
                                <span className='default-font'>{it[k].name}</span>
                            </div>
                        )}
                        <div className="param--value">
                            <span className='default-font'>{it.buy_count / (it.box_count || 1)}</span>
                        </div>
                        <div className="param--value">
                            <span className='default-font'>{it.buy_count}</span>
                        </div>
                        <div className="param--value">
                            <span className='default-font'>{it.total_net_weight}</span>
                        </div>
                        <div className="param--value">
                            <span className='default-font'>{it.total_gross_weight}</span>
                        </div>
                        <div className="param--value">
                            <IconButton className='remove remove-item-button' onClick={() => {
                                removeItemsFromCart([it])
                                updateCart()
                            }}><DeleteForever/></IconButton>
                            {/*<img src="/images/basket.png" alt="basket"/>*/}
                        </div>
                    </div>)}
            </div>
        </div>
        <div className="row card--order-btn--box to-cart">
            <div className="to-cart-btn">
                <div className="row card--order-btn">
                    <Link to="/order" className="col-12">
                        <div style={{width: '210px'}}
                             className="card--order-btn--btn">{t('cart')}</div>
                        <div style={{width: '75px'}} className="card--order-btn--arrow">
                            <img src="/images/order-btn--arrow.png" alt="ar"/>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    </div>
}
export default SelectedItems