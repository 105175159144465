import {downloadAddress, getDownloadLink, getViewLink} from "../../../common/backend";
import {useTranslation} from "react-i18next";
import {saveAs} from "file-saver";

const Buttons = ({file}) => {
    const {t} = useTranslation()
    if (file.link || file.extension === 'pdf') {
        return <div className="download-buttons">
            {/* download */}
            <a target='_blank' href={getDownloadLink(file)}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                    <path
                        d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM216 232V334.1l31-31c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-72 72c-9.4 9.4-24.6 9.4-33.9 0l-72-72c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l31 31V232c0-13.3 10.7-24 24-24s24 10.7 24 24z"/>
                </svg>
                <span>{t('download')}</span>
            </a>
            {/* view */}
            <a href={getViewLink(file)} target="_blank">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path
                        d="M160 96a96 96 0 1 1 192 0A96 96 0 1 1 160 96zm80 152V512l-48.4-24.2c-20.9-10.4-43.5-17-66.8-19.3l-96-9.6C12.5 457.2 0 443.5 0 427V224c0-17.7 14.3-32 32-32H62.3c63.6 0 125.6 19.6 177.7 56zm32 264V248c52.1-36.4 114.1-56 177.7-56H480c17.7 0 32 14.3 32 32V427c0 16.4-12.5 30.2-28.8 31.8l-96 9.6c-23.2 2.3-45.9 8.9-66.8 19.3L272 512z"/>
                </svg>
                <span>{t('view')}</span>
            </a>
        </div>
    }
    return null
}

const File = ({file}) => {
    let downloadLink = <a download href={getDownloadLink(file)} className="download-name">{file.name}</a>
    if (file.link || file.extension === 'pdf') {
        downloadLink = <a target='_blank' href={getViewLink(file)} className="download-name">{file.name}</a>
    }
    return <section className={"download-item" + (!(file.link || file.extension === 'pdf') ? " no-buttons" : "")}>
        <span className="mime" style={{backgroundImage: `url(${downloadAddress}${file.id}-icon.${file.icon})`}}/>
        {downloadLink}
        <Buttons file={file}/>
    </section>
}
export default File
