export const notes = {
    "0-19000": {
        "0-23": {
            "msg": "Ваш заказ меньше полного контейнера, вы можете добавить еще {minWeight}-{maxWeight} кг товара до 20-25,5 тонн и {volume} куб.м до 28 куб. Максимальный вес до которого можно догружать контейнер лучше узнать у вашего перевозчика. Мы из Китая можем грузить до 28 тонн",
            "weight": [20, 25.5],
            "volume": 28,
            "key": "note-0-19000-0-23"
        },
        "23,1-27": {
            "msg": "Ваш заказ по объему составляет почти полный 20-футовый контейнер. Вы можете добавить еще {volume} куб.м и {minWeight}-{maxWeight} кг товара до 20-25,5 тонн.\nМаксимальный вес до которого можно догружать контейнер лучше узнать у вашего перевозчика. Мы из Китая можем грузить до 28 тонн",
            "weight": [20, 25.5],
            "volume": 28,
            "key": "note-0-19000-23,1-27"
        },
        "27,1-28": {
            "msg": "Ваш заказ составляет полный 20-футовый контейнер, однако Вы можете догрузить еще товара до 20-25,5 тонн и {volume} куб.м до 40-футового контейнера (65 куб.м).\nМаксимальный вес до которого можно догружать контейнер лучше узнать у вашего перевозчика. Мы из Китая можем грузить до 28 тонн",
            "weight": [20, 25.5],
            "volume": 65,
            "key": "note-0-19000-27,1-28"
        },
        "28,1-35": {
            "msg": "Объем вашего заказа больше чем стандартный 20-футовый контейнер (28 куб.м). Вы можете увеличить объем заказа до 40-футового контейнера (добавить {volume} куб.м до 65 куб.м).\nМаксимальный вес до которого можно догружать контейнер лучше узнать у вашего перевозчика. Мы из Китая можем грузить до 28 тонн",
            "weight": 0,
            "volume": 65,
            "key": "note-0-19000-28,1-35"
        },
        "35,1-63": {
            "msg": "Вы можете добавить еще {volume} куб.м до полного 40-футового контейнера (65 куб.м) и {minWeight}-{maxWeight} кг товара до 20-25,5 тонн.\nМаксимальный вес до которого можно догружать контейнер лучше узнать у вашего перевозчика. Мы из Китая можем грузить до 28 тонн",
            "weight": [20, 25.5],
            "volume": 65,
            "key": "note-0-19000-35,1-63"
        },
        "63,1-65": {
            "msg": "Ваш заказ составляет полный 40-футовый контейнер",
            "weight": 0,
            "volume": 0,
            "key": "note-0-19000-63,1-65"
        },
        "65,1-9007199254740991": {
            "msg": "Ваш заказ по объему больше полного 40-футового контейнера (65 куб.м). Можно уменьшить количество либо разбить заказ на несколько контейнеров.",
            "weight": 0,
            "volume": 0,
            "key": "note-0-19000-65,1-9007199254740991"
        }
    }, "19001-24500": {
        "0-23": {
            "msg": "Ваш заказ составляет почти полный контейнер. Вы можете добавить еще {minWeight} кг товара до 25,5 тонн и {volume} куб.м до 28 куб. Если Вы планируете 40 футовый контейнер, то объем не должен быть выше 65 куб.м\n Максимальный вес до которого можно догружать контейнер лучше узнать у вашего перевозчика. Мы из Китая можем грузить до 28 тонн",
            "weight": [25.5],
            "volume": 28,
            "key": "note-19001-24500-0-23"
        },
        "23,1-27": {
            "msg": "Ваш заказ составляет почти полный контейнер. Вы можете добавить еще {minWeight} кг товара до 25,5 тонн но чтобы объем был не выше 28 куб для 20-футового и 65 куб для 40-футового контейнера. Максимальный вес до которого можно догружать контейнер лучше узнать у вашего перевозчика. Мы из Китая можем грузить до 28 тонн",
            "weight": [25.5],
            "volume": 0,
            "key": "note-19001-24500-23,1-27"
        },
        "27,1-28": {
            "msg": "Ваш заказ составляет полный 20-футовый контейнер",
            "weight": 0,
            "volume": 0,
            "key": "note-19001-24500-27,1-28"
        },
        "28,1-35": {
            "msg": "Объем вашего заказа больше чем стандартный 20-футовый контейнер (28 куб.м). Вы можете также увеличить объем заказа до 40-футового контейнера (добавить {volume} куб.м до 65 куб.м)",
            "weight": 0,
            "volume": 65,
            "key": "note-19001-24500-28,1-35"
        },
        "35,1-63": {
            "msg": "Вы можете добавить еще {volume} куб.м до полного 40-футового контейнера (65 куб.м) и {minWeight}-{maxWeight} кг товара до 20-25,5 тонн.\nМаксимальный вес до которого можно догружать контейнер лучше узнать у вашего перевозчика. Мы из Китая можем грузить до 28 тонн",
            "weight": [20, 25.5],
            "volume": 65,
            "key": "note-19001-24500-35,1-63"
        },
        "63,1-65": {
            "msg": "Ваш заказ составляет полный 40-футовый контейнер",
            "weight": 0,
            "volume": 0,
            "key": "note-19001-24500-63,1-65"
        },
        "65,1-9007199254740991": {
            "msg": "Ваш заказ по объему больше полного 40-футового контейнера (65 куб.м). Можно уменьшить количество либо разбить заказ на несколько контейнеров.",
            "weight": 0,
            "volume": 0,
            "key": "note-19001-24500-65,1-9007199254740991"
        }
    }, "24501-25500": {
        "0-23": {
            "msg": "Ваш заказ составляет полный 20-футовый контейнер",
            "weight": 0,
            "volume": 0,
            "key": "note-24501-25500-0-23"
        },
        "23,1-27": {
            "msg": "Ваш заказ составляет полный 20-футовый контейнер",
            "weight": 0,
            "volume": 0,
            "key": "note-24501-25500-23,1-27"
        },
        "27,1-28": {
            "msg": "Ваш заказ составляет полный 20-футовый контейнер",
            "weight": 0,
            "volume": 0,
            "key": "note-24501-25500-27,1-28"
        },
        "28,1-35": {
            "msg": "Объем вашего заказа больше чем стандартный 20-футовый контейнер (28 куб.м). Вы можете также увеличить объем заказа до 40-футового контейнера (добавить {volume} куб.м до 65 куб.м)",
            "weight": 0,
            "volume": 65,
            "key": "note-24501-25500-28,1-35"
        },
        "35,1-63": {
            "msg": "Ваш заказ составляет полный 40-футовый контейнер. Просьба уточнить у вашего перевозчика сможет ли он принять такой вес.",
            "weight": 0,
            "volume": 0,
            "key": "note-24501-25500-35,1-63"
        },
        "63,1-65": {
            "msg": "Ваш заказ составляет полный 40-футовый контейнер. Просьба уточнить у вашего перевозчика сможет ли он принять такой вес.",
            "weight": 0,
            "volume": 0,
            "key": "note-24501-25500-63,1-65"
        },
        "65,1-9007199254740991": {
            "msg": "Ваш заказ по объему больше полного 40-футового контейнера (65 куб.м). Можно уменьшить количество либо разбить заказ на несколько контейнеров.",
            "weight": 0,
            "volume": 0,
            "key": "note-24501-25500-65,1-9007199254740991"
        }
    }, "25501-28000": {
        "0-23": {
            "msg": "Ваш заказ составляет полный 20-футовый контейнер. \nПросьба уточнить у вашего перевозчика сможет ли он принять такой вес.",
            "weight": 0,
            "volume": 0,
            "key": "note-25501-28000-0-23"
        },
        "23,1-27": {
            "msg": "Ваш заказ составляет полный 20-футовый контейнер. \nПросьба уточнить у вашего перевозчика сможет ли он принять такой вес.",
            "weight": 0,
            "volume": 0,
            "key": "note-25501-28000-23,1-27"
        },
        "27,1-28": {
            "msg": "Ваш заказ составляет полный 20-футовый контейнер. \nПросьба уточнить у вашего перевозчика сможет ли он принять такой вес.",
            "weight": 0,
            "volume": 0,
            "key": "note-25501-28000-27,1-28"
        },
        "28,1-35": {
            "msg": "Объем вашего заказа больше чем стандартный 20-футовый контейнер (28 куб.м). Вы можете также увеличить объем заказа до 40-футового контейнера (добавить {volume} кг до 65 куб.м)",
            "weight": 0,
            "volume": 65,
            "key": "note-25501-28000-28,1-35"
        },
        "35,1-63": {
            "msg": "Ваш заказ составляет полный 40-футовый контейнер. Просьба уточнить у вашего перевозчика сможет ли он принять такой вес.",
            "weight": 0,
            "volume": 0,
            "key": "note-25501-28000-35,1-63"
        },
        "63,1-65": {
            "msg": "Ваш заказ составляет полный 40-футовый контейнер. Просьба уточнить у вашего перевозчика сможет ли он принять такой вес.",
            "weight": 0,
            "volume": 0,
            "key": "note-25501-28000-63,1-65"
        },
        "65,1-9007199254740991": {
            "msg": "Ваш заказ по объему больше полного 40-футового контейнера (65 куб.м). Можно уменьшить количество либо разбить заказ на несколько контейнеров.",
            "weight": 0,
            "volume": 0,
            "key": "note-25501-28000-65,1-9007199254740991"
        }
    }, "28001-9007199254740991": {
        "0-23": {
            "msg": "Ваш заказ по весу больше полного  20-футового контейнера. Можно уменьшить количество по весу либо разбить заказ на несколько контейнеров.",
            "weight": 0,
            "volume": 0,
            "key": "note-28001-9007199254740991-0-23"
        },
        "23,1-27": {
            "msg": "Ваш заказ по весу больше полного  20-футового контейнера. Можно уменьшить количество по весу либо разбить заказ на несколько контейнеров.",
            "weight": 0,
            "volume": 0,
            "key": "note-28001-9007199254740991-23,1-27"
        },
        "27,1-28": {
            "msg": "Ваш заказ по весу больше полного  20-футового контейнера. Можно уменьшить количество по весу либо разбить заказ на несколько контейнеров.",
            "weight": 0,
            "volume": 0,
            "key": "note-28001-9007199254740991-27,1-28"
        },
        "28,1-35": {
            "msg": "Ваш заказ по весу больше полного  40-футового контейнера. Можно уменьшить количество по весу либо разбить заказ на несколько контейнеров.",
            "weight": 0,
            "volume": 0,
            "key": "note-28001-9007199254740991-28,1-35"
        },
        "35,1-63": {
            "msg": "Ваш заказ по весу больше полного  40-футового контейнера. Можно уменьшить количество по весу либо разбить заказ на несколько контейнеров.",
            "weight": 0,
            "volume": 0,
            "key": "note-28001-9007199254740991-35,1-63"
        },
        "63,1-65": {
            "msg": "Ваш заказ по весу больше полного  40-футового контейнера. Можно уменьшить количество по весу либо разбить заказ на несколько контейнеров.",
            "weight": 0,
            "volume": 0,
            "key": "note-28001-9007199254740991-63,1-65"
        },
        "65,1-9007199254740991": {
            "msg": "Ваш заказ по объему и весу больше полного контейнера. Можно уменьшить количество либо разбить заказ на несколько контейнеров.",
            "weight": 0,
            "volume": 0,
            "key": "note-28001-9007199254740991-65,1-9007199254740991"
        }
    }
};