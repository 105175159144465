import * as React from "react";
import {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {calcTotalSize, getCartItems} from "../../common/cart";
import {useTranslation} from "react-i18next";

const OrderVolume = () => {
    const [volume, setVolume] = useState(calcTotalSize(getCartItems()))
    const {t} = useTranslation()
    useEffect(() => {
        window.addEventListener('storage', () => setVolume(calcTotalSize(getCartItems())))
    }, [])

    return <div className="header--volume-content">
        <Link to="/order">{t('order_volume')}</Link>
        <p><b>{volume.gross_weight}</b> kg</p>
        <p><b>{volume.volume}</b> m²</p>
    </div>
}
export default OrderVolume