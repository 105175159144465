import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import {Autocomplete, Box, Collapse, FormControl, Grid, InputLabel, Select, styled, TextField} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {Add, ArrowDownward, Delete} from "@mui/icons-material";
import * as React from "react";
import {useEffect, useRef, useState} from "react";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import {toast} from "react-hot-toast";
import {useLoader} from "../../../common/Loader";
import {PATH_SAVE_PRICE, post} from "../../../common/backend";
import {useAuth} from "../../../common/auth/AuthContext";
import {wrapTooltip} from "../../../common/commons";
import {clone, uniq, upperCase} from "lodash";
import ConfirmationDialog from "../../../common/ConfirmationDialog";

const CenterItem = styled(Grid)({
    display: 'flex',
    alignItems: 'center'
})

const ProductModal = ({item, close}) => {
    const [variants, setVariants] = useState([])
    const [prices, setPrices] = useState([])
    const id = useRef(0);

    const [supplier, setSupplier] = useState('none')
    const [color, setColor] = useState('')
    const [showAll, setShowAll] = useState(false)

    const [supplierToRemove, setSupplierToRemove] = useState(null)

    const {setLoading} = useLoader()

    const user = useAuth().user

    const [canChange, setCanChange] = useState(false)

    useEffect(() => {
        if (user !== null) {
            setCanChange(user.hasPerm('product supplier price edit'))
        }
    }, [user])

    const addPrice = () => {
        const newPrices = clone(prices)
        newPrices.push({id: ++id.current, color: '', name: '', source: 'manual', price: '0', date: 'Now'})
        setPrices(newPrices)
    }

    const removeSupplier = obj => {
        setSupplierToRemove(obj)
    }

    useEffect(() => {
        if (item !== null) {
            item.supplier_price.forEach(it => !('id' in it) && (it.id = ++id.current))
            setVariants(uniq(item.supplier_price.map(it => it.name).filter(it => it.length !== 0)))
            setPrices(item.supplier_price)
        }
    }, [item])

    const sortByDate = (f, s) => {
        if (f.date.toUpperCase() === 'NOW' && s.date.toUpperCase() === 'NOW') {
            return 0;
        }
        if (f.date.toUpperCase() === 'NOW') {
            return -1;
        } else if (s.date.toUpperCase() === 'NOW') {
            return 1;
        }
        const res = new Date(s.date.replace(' ', 'T')) - new Date(f.date.replace(' ', 'T'))
        if (res === 0) {
            return 0;
        }
        if (res > 0) {
            return 1;
        } else {
            return -1;
        }
    }

    const getFiltered = () => prices
        .sort(sortByDate)
        .filter(it => (supplier === 'none' || it.name === supplier || it.date === 'Now') && (color.length === 0 || it.date === 'Now' || it.color.toLowerCase().startsWith(color.toLowerCase())))

    /* Последние цены по производителям */
    const getUniquePrices = () => {
        const filtered = getFiltered()
        const map = [...(new Map(filtered.map(it => [it.date === 'Now' ? it.id : it.name + it.color, it])).values())]
        map.sort(sortByDate)
        return map
    }
    //Все остальные цены
    const getOtherPrices = () => {
        const uniquePrices = getUniquePrices().map(it => it.id)
        return getFiltered().filter(it => !uniquePrices.includes(it.id))
    }

    const tryClose = () => {
        if (prices.find(it => it.date === 'Now') !== undefined) {
            toast.error('Unsaved rows detected', {duration: 6000})
            return
        }
        setShowAll(false)
        close()
    }

    const save = () => {
        if (prices.find(it => it.name.length === 0)) {
            toast.error('No supplier name provided', {duration: 3500})
            return
        }
        if (prices.find(it => parseFloat(it.price) <= 0)) {
            toast.error('No supplier price provided', {duration: 3500})
            return
        }
        setLoading(true)
        item.supplier_price = prices
        post(PATH_SAVE_PRICE, res => {
            item.supplier_price = res.data[item.code].supplier_price
            item.supplier_price.forEach(it => !('id' in it) && (it.id = ++id.current))
            setPrices(item.supplier_price)
            toast.success('Done')
        }, res => {
            toast.error('Error: ' + res.data.error.message)
        }, {items: [item]}).finally(() => setLoading(false))
    }

    const saveButton = () => {
        if (canChange) {
            return <Button autoFocus onClick={save}>
                Save changes
            </Button>
        }
        return wrapTooltip('No permission', <Button disabled>Save changes</Button>)
    }

    // color
    // supplier
    // price
    // customer
    // supplier_number
    // order_qty
    // print_id
    // date

    const renderPrices = list => list.map(obj => <Grid key={obj.id} item>
        <Grid container direction='row'>
            <CenterItem item>
                <TextField inputProps={{style: {textTransform: 'uppercase'}}} autoComplete='off'
                           onChange={e => obj.color = e.target.value} sx={{maxWidth: '80px'}}
                           id={`color-${obj.id}`}
                           label='Color' placeholder='Color Code' defaultValue={obj.color} size='small'/>
            </CenterItem>
            <CenterItem item>
                <Autocomplete style={{width: '160px'}} autoComplete={false} freeSolo={true} defaultValue={obj.name}
                              size='small'
                              onInputChange={(e, newInputValue) => {
                                  obj.name = upperCase(newInputValue)
                              }}
                              renderInput={params => {
                                  if (params.InputProps) {
                                      params.InputProps.style = {textTransform: 'uppercase'}
                                  }
                                  return <TextField onChange={e => {
                                      const newVal = upperCase(e.target.value)
                                      e.target.value = newVal
                                  }}{...params} label='Supplier'/>
                              }} options={variants}/>
            </CenterItem>
            <CenterItem item>
                <TextField onChange={e => obj.price = e.target.value} sx={{marginLeft: '10px', width: '80px'}}
                           inputProps={{min: 0}} label='Price' autoComplete='off'
                           type='number' defaultValue={obj.price}
                           placeholder='Price' size='small'/>
            </CenterItem>
            <Grid sx={{width: '100px'}} item display='flex' alignItems='center'>
                <Typography fontFamily='inherit' style={{marginRight: '5px', marginLeft: '5px'}}>{obj.date}</Typography>
            </Grid>
            {obj.source !== 'manual' && obj.source !== 'matrix' && obj.source !== 'file import' ? [
                <CenterItem key='1' item>
                    <TextField sx={{marginLeft: '10px', width: '90px'}}
                               label='Order Qty' autoComplete='off'
                               inputProps={{readOnly: true}}
                               type='number' value={obj.order_qty}
                               size='small'/>
                </CenterItem>,
                <CenterItem key='2' item>
                    <TextField sx={{maxWidth: '140px'}}
                               id={`customer-${obj.id}`} inputProps={{readOnly: true}}
                               label='Customer' value={obj.customer} size='small'/>
                </CenterItem>,
                <CenterItem key='3' item>
                    <TextField sx={{maxWidth: '160px'}} inputProps={{readOnly: true}} size='small'
                               value={obj.supplier_number}
                               label={'Supplier Number'}/>
                </CenterItem>,
                <CenterItem key='4' item>
                    <TextField inputProps={{readOnly: true}} size='small' value={obj.art || ''}
                               label={'Name from print'}/>
                </CenterItem>
            ] : [
                <CenterItem key='5' item>
                    <TextField label='Note' onChange={e => obj.note = e.target.value} sx={{width: '450px'}} size='small'
                               defaultValue={obj.note || ''}/>
                </CenterItem>]}
            <CenterItem item>
                <IconButton sx={{marginLeft: '5px'}} onClick={() => removeSupplier(obj)}>
                    <Delete/>
                </IconButton>
            </CenterItem>
            <CenterItem item>
                <Typography fontFamily='inherit' style={{marginRight: '5px'}}>{obj.source}</Typography>
            </CenterItem>
        </Grid>
    </Grid>)

    return [
        <ConfirmationDialog key='remove-confirmation' accept={() => {
            const newPrices = prices.filter(it => it.id !== supplierToRemove.id)
            setPrices(newPrices)
            setSupplierToRemove(null)
        }} close={() => setSupplierToRemove(null)}
                            text={`Are you sure to remove supplier price for ${(supplierToRemove || {}).name} from ${(supplierToRemove || {}).date}, price: ${(supplierToRemove || {}).price}? YOU STILL NEED TO SAVE CHANGES!`}
                            isOpen={supplierToRemove !== null} title='Remove supplier price?'/>,
        <Dialog key='dialog' open={item !== null} onClose={tryClose} maxWidth='xl'>
            <DialogTitle>
                {(item || {}).name}
            </DialogTitle>
            <DialogContent dividers sx={{width: '1200px'}}>
                <Typography textAlign='center' variant='h6' marginBottom='10px'>Search</Typography>
                <Box sx={{flexDirection: 'row'}} marginBottom='20px'>
                    <FormControl noValidate aria-autocomplete='none'>
                        <TextField inputProps={{style: {textTransform: 'uppercase'}}} autoComplete='off' size='small'
                                   sx={{maxWidth: '80px'}} label='Color' value={color}
                                   onChange={e => setColor(e.target.value)}/>
                    </FormControl>
                    <FormControl noValidate aria-autocomplete='none'>
                        <InputLabel htmlFor='supplier-search-filter' id='filter-supplier'>Supplier</InputLabel>
                        <Select id='supplier-search-filter' labelId='filter-supplier' sx={{width: '140px'}}
                                value={supplier}
                                onChange={e => setSupplier(e.target.value)} size='small'>
                            <MenuItem value={'none'}>None</MenuItem>
                            {variants.map(it => <MenuItem key={it} value={it}>{it}</MenuItem>)}
                        </Select>
                    </FormControl>
                </Box>
                <Typography textAlign='center' variant='h6' marginBottom='10px'>Price History</Typography>
                <Grid spacing={1} container direction='column'>
                    <Grid item>
                        <IconButton aria-labelledby='add' onClick={addPrice} sx={{marginBottom: '13px'}}>
                            <Add/>
                        </IconButton>
                    </Grid>
                    {renderPrices(getUniquePrices())}
                    <Collapse in={showAll} sx={{marginLeft: '5px'}}>
                        {renderPrices(getOtherPrices())}
                    </Collapse>
                    <Grid item sx={{textAlign: 'center'}}>
                        <IconButton size='large' onClick={() => setShowAll(!showAll)}>
                            <ArrowDownward sx={{
                                transitionDuration: '0.2s',
                                transitionProperty: 'transform',
                                textAlign: 'center',
                                transform: 'rotate(' + (showAll ? 180 : 0) + 'deg)'
                            }}/>
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={tryClose}>Close</Button>
                {saveButton()}
            </DialogActions>
        </Dialog>
    ]
}

export default ProductModal
