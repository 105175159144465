import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import {Download} from "@mui/icons-material";
import {downloadOrder} from "../../../common/commons";
import {useLoader} from "../../../common/Loader";
import {useTranslation} from "react-i18next";
import {capitalize} from "lodash";

const OrderModal = ({order, open, close}) => {
    order = order || {created_at: new Date(), updated_at: new Date(), delivery_note: ['', {}]}
    const {setLoading} = useLoader()
    const {t} = useTranslation(['note'])
    return <Dialog key='dialog' open={open} onClose={close} maxWidth='md' fullWidth={true}>
        <DialogTitle>
            Order {order.id} from {order.name} ({order.email})
            <Button endIcon={<Download/>} onClick={() => {
                setLoading(true)
                downloadOrder(order).finally(() => setLoading(false))
            }} sx={{float: 'right'}}
                    color='primary'>
                Admin
            </Button>
            <Button endIcon={<Download/>} onClick={() => {
                setLoading(true)
                downloadOrder(order, false).finally(() => setLoading(false))
            }} sx={{float: 'right'}}
                    color='primary'>
                User
            </Button>
        </DialogTitle>
        <DialogContent>
            <Grid container direction='row' spacing={3}>
                {['name', 'company', 'phone', 'email'].map(it =>
                    <Grid item key={order.id + it}><Typography>{capitalize(it)}: {order[it]}</Typography></Grid>)}
            </Grid>
            <br/>
            <Grid container direction='row' spacing={3}>
                <Grid item><Typography>Created
                    at: {order.created_at.toISOString().replace('.000Z', '')}</Typography></Grid>
                <Grid item><Typography>Updated
                    at: {order.updated_at.toISOString().replace('.000Z', '')}</Typography></Grid>
                <Grid item>{order.created_at.getTime() === order.updated_at.getTime() ? '' :
                    <Typography>Order was updated!</Typography>
                }</Grid>
            </Grid>
            <br/>
            <Typography>Comment:</Typography>
            <Typography>{order.comment}</Typography>
            <br/>
            <Typography>Location:</Typography>
            <Typography>{order.location}</Typography>
            <br/>
            <Typography>Delivery Note:</Typography>
            <Typography>{t(order.delivery_note[0], {...order.delivery_note[1]})}</Typography>
        </DialogContent>
        <DialogActions>
            <Button onClick={close}>Close</Button>
        </DialogActions>
    </Dialog>
}
export default OrderModal
