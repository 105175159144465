import {
    Autocomplete,
    Dialog,
    Grid,
    InputAdornment,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    TextField
} from "@mui/material";
import TableHead from "@mui/material/TableHead";
import * as React from "react";
import {PureComponent, useEffect, useRef, useState} from "react";
import {FormatListBulleted, Search, Upload} from "@mui/icons-material";
import $ from "jquery";
import {get, getErrorMessage, PATH_GET_ALL_PRODUCTS, PATH_IMPORT_PRICE, post} from "../../../common/backend";
import {useLoader} from "../../../common/Loader";
import MenuItem from "@mui/material/MenuItem";
import {toast} from "react-hot-toast";
import TablePagination from "@mui/material/TablePagination";
import IconButton from "@mui/material/IconButton";
import ProductModal from "./ProductModal";
import {useAuth} from "../../../common/auth/AuthContext";
import {getAdminPageTitle, wrapTooltip} from "../../../common/commons";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {maxBy, uniq} from "lodash";

class Selector extends PureComponent {
    render() {
        const {variants, select, sx} = this.props
        return (<Select sx={sx} value='' onChange={e => select(e.target.value)} size='small'>
            <MenuItem disabled value=''/>
            {variants.map(it => <MenuItem key={it} value={it}>{it}</MenuItem>)}
        </Select>)
    }
}

const SupplierPrice = () => {
    document.title = getAdminPageTitle('Supplier Price')

    const [items, setItems] = useState(null)
    const {setLoading} = useLoader()

    const importSupplier = useRef('')
    const [variants, setVariants] = useState([])

    const [rowsPerPage, setRowsPerPage] = useState(5)
    const [page, setPage] = useState(0)
    const [search, setSearch] = useState('')
    const [modalItem, setModalItem] = useState(null)

    // [result: Object name => result, filename]
    const [importResult, setImportResult] = useState([])

    const canImport = useAuth().user.hasPerm('product supplier price edit')

    const loadItems = () => {
        setLoading(true)
        get(PATH_GET_ALL_PRODUCTS + '?columns=name,supplier_price,code&supplier_price=true',
            res => {
                setItems(res.data.data)
                updateVariants(res.data.data)
            })
            .finally(() => setLoading(false))
    }

    const updateVariants = data => setVariants(uniq(data.map(it => it.supplier_price.map(s => s.name)).flat()))

    useEffect(() => {
        if (items === null) {
            loadItems()
        }
    }, [])

    //table
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const getPageItems = () => {
        return (items || [])
            .filter(it => search === '' || search.split(',')
                .filter(it => it !== '')
                .some(s => {
                    return it.name.toLowerCase().indexOf(s.toLowerCase()) !== -1 ||
                        it.code.toLowerCase().indexOf(s.toLowerCase()) !== -1
                })
            )
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    };
    //table end

    const importPrice = file => {
        if (!file) {
            return
        }
        setLoading(true)
        const formData = new FormData()
        formData.append('file', file)
        formData.append('supplier', importSupplier.current)

        post(PATH_IMPORT_PRICE, res => {
            toast.success('Updated')
            setImportResult([res.data.result, file.name])
            loadItems()
        }, res => {
            toast.error(getErrorMessage(res))
            setLoading(false)
        }, formData).finally(() => {
            document.getElementById('import-price').value = null
        })
    }

    const importButton = () => {
        const sx = {/*float: 'right',*/ marginTop: '2px', marginRight: '8px'}
        if (canImport) {
            return <IconButton
                onClick={_ => {
                    if (importSupplier.current === '') {
                        toast.error('Choose supplier')
                        return
                    }
                    $('#import-price').click()
                }}
                aria-label='Import'
                color='primary'
                variant='contained'
                sx={sx}>
                <Upload fontSize='large'/>
            </IconButton>
        }
        return wrapTooltip('No permission', <IconButton
            disabled
            color='primary'
            variant='contained'
            sx={sx}>
            <Upload fontSize='large'/>
        </IconButton>)
    }

    return [
        <Dialog onClose={() => setImportResult([])} key='import-result' open={importResult.length === 2}>
            <DialogTitle>
                Import result from {importResult[1]} for supplier {importSupplier.current}
            </DialogTitle>
            <DialogContent>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                Name/Color
                            </TableCell>
                            <TableCell>
                                Result
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Object.entries(importResult[0] || {}).map(([name, result]) => {
                            return <TableRow key={name}>
                                <TableCell>{name}</TableCell>
                                <TableCell>{result}</TableCell>
                            </TableRow>
                        })}
                    </TableBody>
                </Table>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setImportResult([])}>Close</Button>
            </DialogActions>
        </Dialog>,
        <TableContainer key='main' sx={{maxWidth: '550px', margin: 'auto', marginTop: '10px'}} component={Paper}>
            <Grid justifyContent='space-between' container direction='row' spacing={2} paddingLeft={'8px'}
                  marginTop={'8px'}>
                <Grid item>
                    <TextField autoComplete='off' onChange={e => {
                        setPage(0);
                        setSearch(e.target.value);
                    }} defaultValue='' label='Search' size='small' sx={{width: 'fit-content'}}
                               InputProps={{
                                   startAdornment: <InputAdornment position='start'><Search/></InputAdornment>
                               }}/>
                </Grid>
                <Grid item>
                    <Grid spacing={1} container direction='row'>
                        <Grid item>
                            <Autocomplete aria-label='SUpplier' size='small' autoComplete={false} defaultValue=''
                                          onInputChange={(_, newInputValue) => {
                                              importSupplier.current = newInputValue
                                          }} style={{width: '160px'}} freeSolo={true} renderInput={params => {
                                return <TextField label='Supplier' {...params}/>
                            }} options={variants}/>
                        </Grid>
                        <Grid item padding={'0 !important'}>
                            {importButton()}
                            <input onChange={e => importPrice(e.target.files.item(0))} hidden={true} type='file'
                                   accept='.xlsx'
                                   id='import-price'/>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell align='center'>
                            Code
                        </TableCell>
                        <TableCell align='center'>
                            Name
                        </TableCell>
                        <TableCell align='center'>
                            Last Price
                        </TableCell>
                        <TableCell>
                            Price
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {getPageItems().map(it => <TableRow key={it.code}>
                        <TableCell align='center'>{it.code}</TableCell>
                        <TableCell align='center'>{it.name}</TableCell>
                        <TableCell align='center'>{(maxBy(it.supplier_price, 'date') || {date: ''}).date}</TableCell>
                        <TableCell>
                            <IconButton onClick={() => setModalItem(it)}>
                                <FormatListBulleted/>
                            </IconButton>
                        </TableCell>
                    </TableRow>)}
                </TableBody>
            </Table>
            <TablePagination
                rowsPerPageOptions={[5, 15, 50, 100, {value: -1, label: 'All'}]}
                component="div"
                count={(items || []).length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </TableContainer>,
        <ProductModal key="product-modal" item={modalItem} variants={variants} close={() => setModalItem(null)}/>
    ]
}
export {Selector}
export default SupplierPrice
