import Footer from "./common/Footer";
import Header from "./common/header/Header";
import * as React from "react";
import {useEffect, useState} from "react";
import LoginForm from "./common/auth/LoginForm";
import OrderVolume from "./common/OrderVolume";
import {Link} from "react-router-dom";
import RegisterForm from "./common/auth/RegisterForm";
import {useTranslation} from "react-i18next";
import Carousel from "nuka-carousel";
import {range} from "lodash";
import {get, PATH_MAIN_PAGE_ITEMS} from "../common/backend";
import {toast} from "react-hot-toast";
import {useLoader} from "../common/Loader";
import {useNavigate} from "react-router";
import {useAuth} from "../common/auth/AuthContext";
import ExchangeRate from "../admin/other/ExchangeRate";
import BackupDate from "../admin/other/BackupDate";

const Index = () => {
    document.title = 'R!met.CN'
    const {t} = useTranslation()
    const {setLoading} = useLoader()
    const navigate = useNavigate()

    const [loginOpen, setLoginOpen] = useState(false)
    const [registerOpen, setRegisterOpen] = useState(false)
    const [items, setItems] = useState([])

    const isAdmin = (useAuth().user || {}).isAdmin ?? false

    const params = new URLSearchParams(document.location.search)

    useEffect(() => {
        if (params.has('password-reset')) {
            toast.success(t('password_reset_success'), {duration: 6500})
            window.history.replaceState({}, document.title, '/');
        }
    }, [])

    useEffect(() => {
        get(PATH_MAIN_PAGE_ITEMS, res => setItems(res.data), () => toast.error(t('error_try_again'))).finally(() => setLoading(false))
    }, [])

    useEffect(() => {
        if (window.location.href.indexOf("?order") !== -1) {
            window.history.replaceState(null, '');
            toast.success(t('order_added'), {duration: 12000, style: {fontSize: 'large'}})
        }
    }, [])

    return [
        <Header openLogin={() => setLoginOpen(true)} openRegister={() => setRegisterOpen(true)} noBurger={true}
                key='header'/>,
        <LoginForm key='login-form' open={loginOpen} close={() => setLoginOpen(false)}/>,
        <RegisterForm key='register-form' open={registerOpen} close={() => setRegisterOpen(false)}/>,
        <main key='main'>
            <div className="header--volume">
                <div className="container">
                    <ul className="nav-bar">
                        <li><Link to='/company'>{t('company')}</Link></li>
                        <li><Link to='/c'>{t('products')}</Link></li>
                        <li><Link to="/downloads">{t('downloads')}</Link></li>
                        <li><Link to="/contact">{t('contacts')}</Link></li>
                    </ul>
                    {isAdmin && <><ExchangeRate/><BackupDate/></>}
                    <OrderVolume/>
                </div>
            </div>
            <div className="banner-main">
                <div className="container">
                    <div className="col-12">
                        <div className="row">
                            <Carousel
                                autoplay={true}
                                autoplayInterval={4000}
                                autoplayReverse={false}
                                pauseOnHover={true}
                                wrapAround={true}
                                renderCenterLeftControls={() => null}
                                renderCenterRightControls={() => null}
                                slideIndex={0}
                                slidesToShow={1}
                                adaptiveHeight={false}
                                renderBottomCenterControls={state => <ul className='slider-dot'>
                                    {range(0, state.slideCount).map(i => {
                                            let className = state.currentSlide === i ? 'slick-active' : ''
                                            if (!state.currentSlide && i === 0) {
                                                className = 'slick-active'
                                            }
                                            return <li key={i} className={className}
                                                       onClick={() => state.goToSlide(i)}>
                                                <button type="button"
                                                        data-role="none" role="button" tabIndex={0}>
                                                    {i}
                                                </button>
                                            </li>
                                        }
                                    )}
                                </ul>}
                                cellAlign='center'
                                defaultControlsConfig={{
                                    pagingDotsContainerClassName: 'slider-dot',
                                    pagingDotsClassName: ''
                                }}
                            >
                                {items.map(it => <div key={it} className="flex flex-wrap">
                                    <div style={{cursor: 'pointer'}} onClick={() => navigate('/' + it.cn)}
                                         className="slider-content col-4">
                                        <h2 className="slider--title">{t('new_items')}</h2>
                                        <p className="slider--descr">{it.text}</p>
                                    </div>
                                    <div className="slider-image col-8">
                                        <img draggable={false} src={'/images/slider/' + it.img} alt="slider-item"/>
                                    </div>
                                </div>)}
                            </Carousel>
                        </div>
                    </div>
                </div>
            </div>
            <div className="site-note">
                <div className="container">
                    <div className="col-12">
                        <div className="row">
                            <p>{t('new_site')}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="col-12">
                    <div className="row">
                        <div className="product-main col-12 col-md-3 ">
                            <div className="index-card plant-1" onClick={() => navigate('/c/1')}>
                                <div className="flex justify-content-between">
                                    <div className="product-main--item">
                                        <img src="/images/map/1.png" alt="s1"/></div>
                                    <div className="product-main--item">
                                        <img src="/images/map/2.png" alt="s2"/>
                                    </div>
                                </div>
                                <h2 className="product-main--title">{t('factory')} 1</h2>
                                <div className="flex justify-content-between">
                                    <div className="product-main--item">
                                        <img src="/images/map/3.png" alt="s1"/>
                                    </div>
                                    <div className="product-main--item">
                                        <img src="/images/map/4.png" alt="s2"/>
                                    </div>
                                </div>
                            </div>
                            <div className="index-card plant-2" onClick={() => navigate('/c/2')}>
                                <div className="flex justify-content-between">
                                    <div className="product-main--item">
                                        <img src="/images/map/5.png" alt="s1"/>
                                    </div>
                                    <div className="product-main--item">
                                        <img src="/images/map/6.png" alt="s2"/>
                                    </div>
                                </div>
                                <h2 className="product-main--title">{t('factory')} 2</h2>
                                <div className="flex justify-content-between">
                                    <div className="product-main--item">
                                        <img src="/images/map/7.png" alt="s1"/>
                                    </div>
                                    <div className="product-main--item">
                                        <img src="/images/map/8.png" alt="s2"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="map-location col-12 col-md-9">
                            <p className="location factory-1">{t('factory')} 1</p>
                            <p className="location factory-2">{t('factory')} 2</p>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="row">
                        <div className="col-12 col-md-6 site-descr">
                            <p>{t('index_about_1')}</p>
                            <p>{t('index_about_2')}</p>
                            <p>{t('index_about_3')}</p>
                        </div>
                        <div className="col-12 col-md-6 site-descr">
                            <p>{t('index_about_4')}</p>
                            <p>{t('index_about_5')}</p>
                        </div>
                    </div>
                </div>
            </div>
        </main>,
        <Footer key='footer'/>
    ];
}
export default Index
