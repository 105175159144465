import {Fab, Grid} from "@mui/material";
import {useEffect, useRef, useState} from "react";
import {
    backendAddress,
    get,
    getErrorMessage,
    PATH_GET_ALL_SPECS,
    PATH_SAVE_SPECS,
    PATH_SET_PATTERN_IMAGE,
    post
} from "../../../common/backend";
import {toast} from "react-hot-toast";
import axios from "axios";
import {useLoader} from "../../../common/Loader";
import {getAPIKey} from "../../../common/auth/AuthContext";
import {flatten, groupBy, last, sortBy} from "lodash";
import SpecTable from "./SpecTable";
import {Save} from "@mui/icons-material";
import {getAdminPageTitle} from "../../../common/commons";

const SpecsPage = () => {
    document.title =  getAdminPageTitle('Specs')

    const {setLoading} = useLoader()
    const [items, setItems] = useState(null)
    const changed = useRef(false)

    const loadAll = () => {
        get(PATH_GET_ALL_SPECS, res => {
            setItems(res.data.data)
            setLoading(false)
        })
    }

    useEffect(() => {
        loadAll()
    }, [])

    const addItem = (name) => {
        const newItems = {}
        Object.assign(newItems, items)
        newItems[name].push({
            id: -1,
            name: '',
            code: '',
            image: '',
            changed: true,
            index: last(sortBy(items[name], 'index')).index + 1
        })
        setItems(newItems)
    }

    const deleteItem = (spec, id) => {
        const newItems = {}
        Object.assign(newItems, items)
        newItems[spec] = newItems[spec].filter(it => it.id !== id)
        setItems(newItems)
        changed.current = true
    }

    const update = () => {
        const newItems = {}
        Object.assign(newItems, items)
        setItems(newItems)
    }

    const save = () => {
        if (!changed.current && flatten(Object.values(items)).every(it => !it.changed)) {
            toast.error('Nothing changed')
            return;
        }
        setLoading(true)
        let errors = [];
        Object.entries(items).forEach(([spec, values]) => {
            ['name', 'code'].forEach(c => {
                Object.values(values).forEach(it => {
                    if (it[c].length === 0) {
                        errors.push(`Empty ${c} at ${spec}`)
                    }
                })
                Object.values(groupBy(values, it => it[c].toLowerCase())).forEach(it =>
                    it.length > 1 && errors.push(`Duplicated ${c} (${it[0][c]}) at ${spec}`)
                )
            })
        })
        if (errors.length > 0) {
            setLoading(false)
            toast.error(errors.join('; '), {duration: 4500})
            return
        }
        changed.current = false
        post(PATH_SAVE_SPECS, res => {
                const images = items.Pattern.filter(it => typeof it.image === 'object')
                if (images.length > 0) {
                    const formData = new FormData()
                    images.forEach(it => formData.append(it.id, it.image))
                    axios.post(backendAddress + PATH_SET_PATTERN_IMAGE, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': 'Bearer ' + getAPIKey()
                        },
                        withCredentials: false
                    }).then(() => {
                        loadAll()
                        toast.success('Saved!')
                    }).catch(res => toast.error(getErrorMessage(res))).finally(() => setLoading(false))
                } else {
                    setItems(res.data.data)
                    setLoading(false)
                    toast.success('Saved!')
                }
            }, () => {
                setLoading(false)
                toast.error('Error during saving')
            },
            {data: items}
        )
    }

    if (items === null) {
        return ''
    }

    return <Grid justifyContent='center' display='flex' container direction='row' spacing={4}>
        <Grid item>
            <SpecTable update={update} add={addItem} deleteItem={deleteItem} items={items['Ceramic Color']}
                       type='Ceramic Color'/>
        </Grid>
        <Grid item>
            <SpecTable update={update} add={addItem} deleteItem={deleteItem} items={items['Color']} type='Color'/>
        </Grid>
        <Grid item>
            <SpecTable update={update} add={addItem} deleteItem={deleteItem} items={items['Pattern']} type='Pattern'/>
        </Grid>
        <Fab sx={{
            position: "fixed",
            bottom: (theme) => theme.spacing(2),
            right: (theme) => theme.spacing(2)
        }} onClick={save} color="primary">
            <Save/>
        </Fab>
    </Grid>
}
export default SpecsPage
