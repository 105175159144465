import {TextField} from "@mui/material";
import {useState} from "react";
import {useLoader} from "../../../common/Loader";
import {PATH_2FA_REMOVE, post} from "../../../common/backend";
import {toast} from "react-hot-toast";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {useAuth} from "../../../common/auth/AuthContext";
import {useTranslation} from "react-i18next";

const RemoveGoogle2FAModal = ({open, close}) => {
    const {setLoading} = useLoader()
    const {checkLogin} = useAuth()

    const [otp, setOtp] = useState('')
    const {t} = useTranslation()

    const remove = () => {
        if (otp.length !== 6) {
            toast.error(t('check_otp'))
            return
        }
        setLoading(true)
        post(PATH_2FA_REMOVE, () => {
            toast.success(t('done'))
            checkLogin()
            close()
        }, err => toast.error((err.response.data.message || err.response.data.error.message)), {key: otp}).finally(() => setLoading(false))
    }

    return <Dialog open={open} onClose={close}>
        <DialogContent>
            <DialogTitle>
                {t('remove_google_2fa')}
            </DialogTitle>
            <TextField type='number' value={otp} onChange={e => setOtp(e.target.value)} sx={{width: '100%'}}
                       label={'OTP'} autoComplete='off'
                       placeholder={t('one_time_password')}/>
        </DialogContent>
        <DialogActions>
            <Button onClick={close}>{t('cancel')}</Button>
            <Button onClick={remove}>{t('remove_2fa')}</Button>
        </DialogActions>
    </Dialog>
}
export default RemoveGoogle2FAModal
