import {Typography} from "@mui/material";
import {useNavigate} from "react-router";
import {Link} from "react-router-dom";
import {useAuth} from "../../../common/auth/AuthContext";
import {WarningAmber} from "@mui/icons-material";
import Tooltip from "@mui/material/Tooltip";
import {useTranslation} from "react-i18next";
import Search from "./Search";
import AccountData from "./AccountData";
import {useTheme} from "../../../common/ThemeProvider";
import {useEffect} from "react";
import LangSwitch from "./LangSwitch";

const Header = ({openLogin, openRegister, noBurger = false}) => {
    const navigate = useNavigate();
    const {t} = useTranslation()
    const {authorized, user} = useAuth()
    const {color} = useTheme()
    const loginElement = () => {
        if (authorized === null) {
            return '';
        }
        if (authorized === false) {
            return [
                <a key='login-btn' className="lang-switcher--btn" href="src/desktop/common" onClick={e => {
                    e.preventDefault()
                    toggleHeader()
                    openLogin()
                }}>{t('login')}</a>,
                <a key='register-btn' className="lang-switcher--btn" href="src/desktop/common" onClick={e => {
                    e.preventDefault()
                    toggleHeader()
                    openRegister()
                }}>{t('register')}</a>
            ];
        }
        const cabinetLink = () => {
            if (user.email_verified_at === null) {
                return [
                    <div key='unsigned' style={{display: 'contents'}}>
                        <Tooltip title={<Typography fontSize={20}>{t('confirm_email')}</Typography>}
                                 placement='top'>
                            <WarningAmber style={{margin: 'auto', cursor: 'pointer', marginRight: '5px'}}
                                          onClick={() => {
                                              navigate('/cabinet')
                                              toggleHeader()
                                          }
                                          }
                                          className='account--img' color='error'/>
                        </Tooltip>
                        <span onClick={() => {
                            navigate('/cabinet')
                            toggleHeader()
                        }} className="account--text"
                              style={{
                                  font: 'menu',
                                  margin: 'auto 1px auto 7px',
                                  cursor: 'pointer'
                              }}>{user.full_name}</span>
                    </div>
                ]
            }
            return [
                <img onClick={() => {
                    navigate('/cabinet')
                    toggleHeader()
                }} key='img' className="account--img"
                     src="/images/account--img.png" style={{cursor: 'pointer'}} alt={t('account')}/>,
                <span onClick={() => {
                    navigate('/cabinet')
                    toggleHeader()
                }} key='text' className="account--text">{user.full_name}</span>
            ]
        }

        return <>
            {cabinetLink()}
            <span className="account--btn">▼</span>
        </>
    }

    const Burger = () => {
        /* page == index */
        if (noBurger) {
            return [
                <div key='mobile-birger-placeholder' className="mob-only"></div>
            ]
        }

        return [
            <div key='mobile-burger {}' className="mob-only mob-menu menu-toggle" onClick={() => toggleMenu()}>
                <div className="menu-toggle--line fst"></div>
                <div className="menu-toggle--line sec"></div>
                <div className="menu-toggle--line trd"></div>
            </div>
        ]
    }

    /* fix burger state if another factory selected in mobile screen size */
    useEffect(() => {
        let elem = document.querySelector('aside.sidebar');
        if (elem !== null && elem.classList.contains('shown')) {
            document.querySelector('.menu-toggle').classList.toggle('active');
        }
    }, [color])

    function toggleMenu() {
        document.querySelector('.menu-toggle').classList.toggle('active');
        document.querySelector('aside.sidebar').classList.toggle('shown');
    }

    function toggleHeader() {
        document.querySelector('.header-toggle').classList.toggle('active');
        document.querySelectorAll('.mob-secondary').forEach(elem => elem.classList.toggle('shown'));
    }

    return <header className="header flex align-items-center" style={{'--clr': color}}>
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="flex align-items-center header-container">
                        <Burger/>
                        <div className="logo col-2">
                            <div className="row">
                                <Link to="/">
                                    <img src="/images/logo_top.svg" alt="Rimet"/>
                                </Link>
                            </div>
                        </div>
                        <div className="col-1 header-toggle__container">
                            <div className="header-toggle mob-hidden" onClick={() => toggleHeader()}/>
                        </div>
                        <div className="search col-4 mob-secondary">
                            <Search handleChange={() => toggleHeader()}/>
                        </div>
                        <div className="col-0 mob-secondary"/>
                        <div className="lang-switcher col-2 mob-secondary">
                            <div className="row flex-nowrap">
                                <LangSwitch handleChange={() => toggleHeader()}/>
                            </div>
                        </div>
                        <div className="account col-3 mob-secondary">
                            <div className="row flex-nowrap">
                                {loginElement()}
                                <AccountData handleChange={() => toggleHeader()}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
}
export default Header
