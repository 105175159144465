import {FC} from 'react';
import {Draggable} from 'react-beautiful-dnd';
import {
    Box,
    IconButton,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Tooltip,
} from '@mui/material';
import {Item} from "./DraggableList";
import {useNavigate} from "react-router";
import {ArrowDownward, ArrowUpward, Send} from "@mui/icons-material";

export type Props = {
    item: Item;
    index: number;
};

const DraggableListItem: FC<Props> = ({item, index, move}) => {
    const navigate = useNavigate()
    return <Draggable draggableId={item.id.toString()} index={index}>
        {(provided, snapshot) => (
            <Tooltip title="">
                <ListItem
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    sx={snapshot.isDragging ? {background: 'rgb(235,235,235)'} : ''}
                >
                    <ListItemAvatar>
                        <ListItemIcon>
                            {item.order}
                        </ListItemIcon>
                        <IconButton onClick={() => move(index, index - 1)}><ArrowUpward/></IconButton>
                        <IconButton onClick={() => move(index, index + 1)}><ArrowDownward/></IconButton>
                    </ListItemAvatar>
                    <ListItemText primary={item.name} secondary={`Factory ${item.factory}`}/>
                    <ListItemButton dense={true} alignItems=''
                                    onClick={() => navigate('/admin/category/' + item.id)}>
                        <Box display="flex" justifyContent="flex-end" width="100%">
                            <ListItemIcon sx={{alignItems: 'end'}}>
                                <Send color='primary'/>
                            </ListItemIcon>
                        </Box>
                    </ListItemButton>
                </ListItem>
            </Tooltip>
        )}
    </Draggable>
};

export default DraggableListItem;
