import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router";
import {Link} from "react-router-dom";
import {useCurrentCategory} from "../context/CurrentCategoryContext";
import {flatten} from "lodash";

const Breadcrumbs = ({currentPage, categories}) => {
    categories = flatten(Object.values(categories || {'': []}))
    const {t} = useTranslation(['translation', 'category'])
    const sku = useParams().sku

    const [path, setPath] = useState([])

    let {current} = useCurrentCategory()

    useEffect(() => {
        if (categories.length === 0) {
            return
        }
        let newPath = [
            [t('main_page'), '/']
        ]
        const child = flatten(categories.map(it => it.children))
        let factory = 1;

        console.log(categories);
        console.log(child);

        (Array.isArray(current) ? current : Object.keys(current)).map(it => parseInt(it)).map(it => {
            return categories.find(c => c.id === it) ?? child.find(c => c.id === it)
        }).filter(it => !!it)
            .forEach(it => {
                factory = it.factory
                let link = it.link
                if (!Array.isArray(current) && current[it.id] !== 1) {
                    link += '?page=' + current[it.id]
                }
                if (sku) {
                    link += `#${sku}`
                }
                newPath.push([t(it.name, {ns: 'category'}), link])
            });
        newPath.splice(1, 0, [t('factory') + ` ${factory}`, factory.toString()]);
        if (sku) {
            newPath.push([sku, null])
        }

        /* remove breadcrumbs for specific pages */
        if (["Downloads", "ContactUs", "Company"].includes(currentPage)) {
            newPath = []
        }
        setPath(newPath)
    }, [currentPage, current])

    return <section className="breadcrumbs col-12">
        <div className="container">
            <ul>
                {path.map(([name, link]) => {
                    if (link) {
                        return <li key={link}><Link to={link === '/' ? '/' : `/c/${link}`}>{name}</Link></li>
                    }
                    return <li key={name}>{name}</li>
                })}
            </ul>
        </div>
    </section>
}

export default Breadcrumbs
