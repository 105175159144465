import {useEffect, useState} from "react";
import {get, getErrorMessage, PATH_ORDER_GET_ALL, PATH_ORDER_UPDATE, put} from "../../../common/backend";
import {toast} from "react-hot-toast";
import {useLoader} from "../../../common/Loader";
import {
    Backdrop,
    CircularProgress,
    FormControl,
    Input,
    InputAdornment,
    InputLabel,
    Paper,
    Select,
    Stack,
    Table,
    TableBody,
    TableContainer,
    TableRow,
    TextField
} from "@mui/material";
import {Clear, DeleteOutline, Download, Info, Search} from "@mui/icons-material";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import Box from "@mui/material/Box";
import TablePagination from "@mui/material/TablePagination";
import IconButton from "@mui/material/IconButton";
import {LocalizationProvider, MobileDatePicker} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import MenuItem from "@mui/material/MenuItem";
import DeleteOrderDialog from "./DeleteOrderDialog";
import OrderModal from "./OrderModal";
import {downloadOrder, getAdminPageTitle} from "../../../common/commons";
import {sortBy} from "lodash";

const Orders = () => {
    document.title = getAdminPageTitle('Orders')
    //name, email, date
    const [orders, setOrders] = useState(null)

    //table
    const [page, setPage] = useState(0)
    const [search, setSearch] = useState('')
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [dateFrom, setDateFrom] = useState(null);
    const [dateTo, setDateTo] = useState(null);
    const [status, setStatus] = useState('');
    //end table
    const [orderToRemove, setOrderToRemove] = useState(null)
    const [showOrder, setShowOrder] = useState(null)

    const {setLoading} = useLoader()

    useEffect(() => {
        if (orders === null) {
            get(PATH_ORDER_GET_ALL, res => {
                res.data.forEach(it => {
                    it.created_at = new Date(it.created_at)
                    it.updated_at = new Date(it.updated_at)
                })
                setOrders(res.data)
            }, res => toast.error(getErrorMessage(res))).finally(() => setLoading(false))
        }
    })

    if (orders === null) {
        return <Backdrop style={{zIndex: 9000}} open={true}>
            <CircularProgress/>
        </Backdrop>
    }

    const getPageItems = () => {
        const searchLower = search.toLowerCase()
        return sortBy(orders || [], 'created_at')
            .reverse()
            .filter(order =>
                (dateFrom === null || order.created_at.getTime() >= dateFrom.getTime()) &&
                (dateTo === null || order.created_at <= dateTo.getTime()) &&
                (status === '' || status === order.status) &&
                [order.name, order.email, order.phone].some(it => it.toLowerCase().indexOf(searchLower) !== -1)
            ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    };
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - orders.length) : 0;
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const clearSearch = () => {
        setSearch('')
        setDateFrom(null)
        setDateTo(null)
        setStatus('')
    }

    const statusList = Object.entries({
        1: 'Received',
        2: 'Accepted',
        3: 'Delivered',
        4: 'Declined'
    })

    const onOrderDeleted = order => {
        setOrders(orders.filter(it => it.id !== order.id))
        toast.success(`Order ${order.id} from ${order.name} deleted.`)
    }

    const changeStatus = (order, status) => {
        setLoading(true)
        put(PATH_ORDER_UPDATE + order.id,
            () => toast.success('Done'),
            res => toast.error(getErrorMessage(res)), {status})
            .finally(() => setLoading(false))
    }

    return <Box sx={{width: '100%', marginTop: '20px'}}>
        <DeleteOrderDialog order={orderToRemove} open={orderToRemove !== null} onDelete={onOrderDeleted}
                           close={() => setOrderToRemove(null)}/>,
        <OrderModal open={showOrder !== null} order={showOrder} close={() => setShowOrder(null)}/>,
        <Paper sx={{maxWidth: '80%', width: '100%', mb: 2, margin: 'auto'}}>
            <TableContainer>
                <Stack sx={{marginTop: '8px'}} direction='row' spacing={3}>
                    <Input onChange={e => {
                        setPage(0);
                        setSearch(e.target.value);
                    }} value={search}
                           sx={{marginLeft: '8px', width: 'fit-content'}}
                           variant='standard' startAdornment={
                        <InputAdornment position='start' placeholder='Search'>
                            <Search/>
                        </InputAdornment>
                    }>
                    </Input>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MobileDatePicker
                            orientation='landscape'
                            label="Date from"
                            inputFormat="MM/DD/YYYY"
                            value={dateFrom}
                            disableFuture={true}
                            onChange={it => setDateFrom(it.toDate())}
                            renderInput={(params) => <TextField variant='standard' {...params} />}
                        />
                        <MobileDatePicker
                            orientation='landscape'
                            disableFuture={true}
                            label="Date to"
                            inputFormat="MM/DD/YYYY"
                            ignoreInvalidInputs={true}
                            value={dateTo}
                            onChange={it => setDateTo(it.toDate())}
                            renderInput={(params) => <TextField variant='standard' {...params} />}
                        />
                    </LocalizationProvider>
                    <FormControl sx={{minWidth: '120px', marginTop: '8px'}}
                                 variant='outlined'>
                        <InputLabel id="set-status-label">Status</InputLabel>
                        <Select
                            variant='standard'
                            labelId="set-status-label"
                            label="Status"
                            onChange={e => setStatus(e.target.value)}
                            value={status}
                        >
                            <MenuItem value=''>None</MenuItem>
                            {statusList.map(([id, name]) =>
                                <MenuItem key={id} value={id}>{name}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                    <IconButton onClick={clearSearch}>
                        <Clear color='primary'/>
                    </IconButton>
                </Stack>
                <Table sx={{minWidth: 800, tableLayout: 'fixed'}}
                       aria-labelledby="tableTitle"
                       size='small'>
                    <TableHead>
                        <TableRow>
                            {['id', 'Email', 'Name', 'Phone', 'Status', 'Created', 'Changed', 'Show', 'Download', 'Delete'].map((headCell, index) => {
                                    let width = '';
                                    switch (headCell) {
                                        case 'id':
                                            width = '16px';
                                            break;
                                        case 'Show':
                                            width = '55px';
                                            break
                                        case 'Delete':
                                            width = '65px';
                                            break;
                                        case 'Download':
                                            width = '75px';
                                            break;
                                    }
                                    return <TableCell
                                        width={width}
                                        key={`head-${index}`}
                                        align='center'
                                        padding='normal'
                                    >
                                        {headCell}
                                    </TableCell>
                                }
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {getPageItems().map(row => {
                            return (
                                <TableRow
                                    sx={{width: '15%'}}
                                    tabIndex={-1}
                                    key={`order-${row.id}`}
                                >
                                    {['id', 'email', 'name', 'phone', 'status', 'created_at', 'updated_at'].map(it => {
                                        let value = row[it]
                                        if (value instanceof Date) {
                                            value = value.toISOString().split('T')[0]
                                        }
                                        if (it === 'status') {
                                            value = <FormControl>
                                                <Select variant='standard' defaultValue={value}
                                                        onChange={e => changeStatus(row, e.target.value)}>
                                                    {statusList.map(([id, name]) =>
                                                        <MenuItem key={row.id + 's' + id} value={id}>{name}</MenuItem>
                                                    )}
                                                </Select>
                                            </FormControl>
                                        }
                                        return <TableCell align='center' key={row.id + it}>
                                            {value}
                                        </TableCell>
                                    })}
                                    <TableCell align='center'>
                                        <IconButton color='primary' onClick={() => setShowOrder(row)}>
                                            <Info/>
                                        </IconButton>
                                    </TableCell>
                                    <TableCell align='center'>
                                        <IconButton color='primary' onClick={() => {
                                            setLoading(true)
                                            downloadOrder(row).finally(() => setLoading(false))
                                        }}>
                                            <Download/>
                                        </IconButton>
                                    </TableCell>
                                    <TableCell align='center'>
                                        <IconButton color='primary' onClick={() => setOrderToRemove(row)}>
                                            <DeleteOutline/>
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            );
                        })
                        }
                        {emptyRows > 0 && (
                            <TableRow
                                style={{
                                    height: (33) * emptyRows,
                                }}
                            >
                                <TableCell colSpan={6}/>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 25, 50, 100, {value: -1, label: 'All'}]}
                component="div"
                count={(orders || []).length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    </Box>
}
export default Orders
