import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {useLoader} from "../common/Loader";
import {backendAddress, getErrorMessage, PATH_INFO_LANG} from "../common/backend";
import {toast} from "react-hot-toast";
import i18next from "i18next";
import axios from "axios";

const Info = () => {
    document.title = 'Info | R!met.CN'
    const {setLoading} = useLoader()

    let lang = new URLSearchParams(window.location.search).get('lng')

    if (!lang) {
        lang = i18next.language
    }

    const prevLang = useRef(lang)
    const [data, setData] = useState([])

    useEffect(() => {
        const controller = new AbortController();
        axios.get(backendAddress + PATH_INFO_LANG + lang, {
            signal: controller.signal,
            withCredentials: false
        }).then(res => {
            setData(res.data)
        }).catch(err => toast.error(getErrorMessage(err))).finally(() => setLoading(false))
        return () => {
            if (prevLang.current === lang) {
                prevLang.current = lang
                return
            }
            controller.abort()
        }
    }, [lang])

    return <main key='main' className="info">
        <div className="container links">
            <img src="/images/avatar.webp" alt=""/>
            {data.map((it, i) => {
                const res = [
                    <a key={`${i}-link`} className="info-link" target='_blank' href={it.link}>
                        <h3>{it.text}</h3>
                        {it.small ? <p>{it.small}</p> : ""}
                    </a>
                ]
                if (it.del) {
                    res.push(<hr key={`${i}-delimiter`}/>)
                }
                return res
            })}
        </div>
    </main>
}
export default Info
