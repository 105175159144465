import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import {Checkbox, FormControlLabel, Grid, TextField} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {useEffect, useState} from "react";
import {useLoader} from "../../../common/Loader";
import {AddModerator, RemoveModerator} from "@mui/icons-material";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import {getErrorMessage, PATH_USERS_UPDATE, post} from "../../../common/backend";
import {toast} from "react-hot-toast";
import {clone} from "lodash";

const permissions = {
    'edit specs': 'Все связанное с характеристиками и шаблонами характеристик',
    'product view': 'просмотр всей информации о товарах (за исключением цен поставщика)',
    'product attachment': 'редактирование фото/видео/диаграмм',
    'product mark as new': 'установка срока актуальности',
    'product import': 'импорт товаров из файла',
    'product rimet print import': 'импорт размеров упаковки с сайта print',
    'product supplier price': 'просмотр цен поставщика (включая результаты импорта цен с сайта print)',
    'product supplier price edit': 'редактирование цен поставщика/импорт цен поставщика из файла',
    'downloads': 'Редактирование файлов со страницы загрузок'
}

const EditUserModal = ({open, user, close}) => {

    const {setLoading} = useLoader()
    const [editableUser, setEditableUser] = useState(user || {})
    const [google2faEnabled, setGoogle2faEnabled] = useState(false)

    useEffect(() => {
        if ('google_2fa_enabled' in user) {
            setGoogle2faEnabled(user.google_2fa_enabled)
        } else {
            setGoogle2faEnabled(false)
        }
        const nUser = clone(user)
        nUser.password = ''
        setEditableUser(nUser)
    }, [user])

    const save = () => {
        if (editableUser.perm.length > 0 && !google2faEnabled) {
            toast.error('Admin should enable 2FA')
            return
        }
        setLoading(true)

        let data = Object.entries(editableUser)
        data = data.filter(([k, v]) => user[k] !== v || k === 'perm')
        data = Object.fromEntries(data)
        if (!('password' in data) || data.password.length === 0) {
            delete data.password
        }
        data.google_2fa_enabled = google2faEnabled

        post(PATH_USERS_UPDATE + user.id, () => {
            toast.success('Done')
            Object.assign(user, editableUser)
            setEditableUser(clone(user))
        }, res => toast.error(getErrorMessage(res)), data).finally(() => setLoading(false))
    }

    const remove2fa = () => setGoogle2faEnabled(false)
    const add2fa = () => setGoogle2faEnabled(true)

    const g2faButton = () => {
        if (google2faEnabled) {
            return <Button onClick={remove2fa} startIcon={<RemoveModerator/>}>Remove
                Google 2FA</Button>
        }
        if (user.google_2fa_enabled) {
            return <Button onClick={add2fa} startIcon={<AddModerator/>}>Return Google 2FA</Button>
        }
        return <span>Google 2FA Disabled</span>
    }

    return <Dialog open={open} onClose={close}>
        <DialogTitle>Editing {editableUser.full_name} {editableUser.email}</DialogTitle>
        <DialogContent key={editableUser.id}>
            <Grid direction='row' alignItems='center' marginY='1px' container spacing={2}>
                {['Full name', 'Email', 'Country', 'City', 'Company', 'Phone', 'Print ID'].map(it =>
                    <Grid item key={it}>
                        <TextField sx={{maxWidth: '170px'}}
                                   type={it === 'Print ID' ? 'number' : 'text'}
                                   defaultValue={editableUser[it.toLowerCase().replace(' ', '_')]}
                                   id={it}
                                   min='0'
                                   autoComplete={`new-${it}`}
                                   label={it}
                                   onChange={e => editableUser[it.toLowerCase().replace(' ', '_')] = e.target.value}
                                   name={it}/>
                    </Grid>
                )}
                <Grid item>
                    <TextField sx={{maxWidth: '170px'}} defaultValue=''
                               id='password' type='password' autoComplete='new-password'
                               label='Password' onChange={e => editableUser.password = e.target.value}
                               name='password'/>
                </Grid>
                <Grid item maxWidth='185px'>
                    <Tooltip placement='top'
                             title={<Typography fontSize={20}>Вы сможете вернуть ее до сохранения</Typography>}>
                        {g2faButton()}
                    </Tooltip>
                </Grid>
            </Grid>
            <Typography variant='h4' textAlign='center'>Permissions</Typography>
            <Grid container direction='row' spacing={2}>
                {Object.entries(permissions).map(([k, v]) =>
                    <Grid item key={k + user.id}>
                        <Tooltip title={<Typography fontSize={20}>{v}</Typography>} placement='bottom'>
                            <FormControlLabel label={k} control={<Checkbox onChange={e => {
                                if (e.target.checked) {
                                    editableUser.perm.push(k)
                                } else {
                                    editableUser.perm.splice(editableUser.perm.indexOf(k), 1)
                                }
                            }} defaultChecked={(editableUser.perm || []).includes(k)}/>}/>
                        </Tooltip>
                    </Grid>
                )}
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button onClick={close}>Cancel</Button>
            <Button onClick={save}>Save</Button>
        </DialogActions>
    </Dialog>
}
export default EditUserModal
