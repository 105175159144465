import {useRef} from "react";
import {round} from "lodash";
import {Add, Remove} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import {TableRow} from "@mui/material";
import TableCell from "@mui/material/TableCell";
import {getItemImage} from "../../common/commons";
import {Link} from "react-router-dom";
import {nearestCount, productGetFullName} from "../../common/cart";
import {useTranslation} from "react-i18next";

const TableItem = ({item, params, index, onChange, editable, checkbox}) => {
    const inputRef = useRef()
    const {t} = useTranslation(['translation', 'name'])

    const setNumberOfPackages = n => {
        if (n === 0) {
            inputRef.current.value = Math.max(item.box_count, 1)
            onChange(item)
            return
        }
        if (item.box_count) {
            item.buy_count = item.box_count * n
        } else {
            item.buy_count = n
        }
        inputRef.current.value = item.buy_count
        onChange(item)
    }

    const setCount = n => {
        n = item.box_count ? nearestCount(n, item.box_count) : n
        if (n < 1) {
            n = item.box_count ? item.box_count : 1
        }
        item.buy_count = n
        inputRef.current.value = item.buy_count
        onChange(item)
    }

    const getValue = param => {
        param = param.toLowerCase()
        if (param === 'name') {
            return productGetFullName(item, t(item.name, {ns: 'name'}))
        }
        if (param === 'image') {
            return <Link target='_blank' to={`/${item.catalog_number}`}>
                <img alt="img"
                     className="cart-product-img"
                     src={getItemImage(item)}/>
            </Link>
        }
        if (param === 'index') {
            return index;
        }

        const res = item[param.split(',')[0].replaceAll(' ', '_')];

        if (param === 'catalog number') {
            return <Link to={`/${res}`}>
                {res}
            </Link>
        }

        if (param === 'buy count') {
            return <div className="buy-count-container">
                <IconButton hidden={!editable} key='remove'
                            onClick={() => setNumberOfPackages(item.buy_count / Math.max(+item.box_count, 1) - 1)}
                            size='small'>
                    <Remove fontSize='small'/>
                </IconButton>
                <input ref={inputRef} key='input' style={{width: '50px'}} type="number"
                       defaultValue={item.buy_count}
                       min={Math.max(+item.box_count, 1)}
                       readOnly={!editable}
                       onKeyUp={e => {
                           if (e.key !== "Enter" && e.keyCode !== 13) {
                               return
                           }
                           e.target.blur()
                           setCount(e.target.value)
                       }}
                           onBlur={e => setCount(e.target.value)}
                           />
                           <IconButton hidden={!editable} key='add'
                       onClick={() => {
                           setNumberOfPackages(item.buy_count / Math.max(+item.box_count, 1) + 1)
                       }}
                       size='small'>
                    <Add fontSize='small'/>
                </IconButton>
            </div>
        }
        if (!isNaN(res)) {
            return round(res, 2)
        }
        return res
    }

    return <TableRow>
        {params.map(param => {
            const sx = {}
            if (param === 'image') {
                sx.padding = '0px'
            }
            return <TableCell className='param--value_second default-font' style={sx} align='center' key={param}>
                {getValue(param)}
            </TableCell>;
        })}
        <TableCell align='center'>
            {editable ? checkbox : null}
        </TableCell>
    </TableRow>
}
export default TableItem
