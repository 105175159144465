import {Dialog, TextField} from "@mui/material";
import {useEffect, useRef, useState} from "react";
import {toast} from "react-hot-toast";
import {addToCart, nearestCount} from "../../common/cart";
import {useTranslation} from "react-i18next";
import {attachmentsAddress} from "../../common/backend";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import {capitalize, clone, isEmpty} from "lodash";

const SelectModal = ({nullableItem, close}) => {
    const {t} = useTranslation(['translation', 'name'])
    const params = useRef()
    const item = clone(nullableItem || {})
    const [count, setCount] = useState(item.box_count > 0 ? item.box_count : 1)

    useEffect(() => {
        params.current = nullableItem ? Object.fromEntries(Object.keys(nullableItem.schema || {}).map(it => [it, null])) : {}
        setCount(item.box_count || 0)
    }, [nullableItem])

    const addToOrder = () => {
        if (count < 1) {
            toast.error(t('select_count'))
            return
        }
        const notSet = Object.entries(params.current).find(([, v]) => v === null)
        if (notSet) {
            toast.error(t('not_selected', {what: t(notSet[0].replace(' ', '_').toLowerCase()).toLowerCase()}))
            return;
        }
        item.buy_count = count
        Object.entries(params.current).forEach(([k, v]) => item[k.toLowerCase().replace(' ', '_')] = v)
        addToCart([item], false)
        toast.success(t('done'))
        close()
    }

    const updateCount = newCount => {
        newCount = item.box_count ? nearestCount(newCount, item.box_count) : newCount
        if (newCount < 1) {
            newCount = item.box_count ? item.box_count : 1
        }
        setCount(newCount)
    }

    return <Dialog onClose={close} open={!!nullableItem}>
        <DialogTitle className="modal-header">
            <span className="modal-title">{t('product')} <span
                className="cat-code">{item.catalog_number}</span>
                <span className="item-code">{t(item.name, {ns: 'name'})}</span></span>
            <button onClick={close} type="button" className="close" data-dismiss="modal"
                    aria-label={t('close')}>
                <span aria-hidden="true">&times;</span>
            </button>
        </DialogTitle>
        <DialogContent style={{padding: 0}}>
            <div className="modal-body select-container--inner">
                <div className="select-colors">
                    {Object.entries(item.schema || {}).map(([name, list], i) => {
                            if (name === 'Pattern') {
                                return null
                            }
                            return <div key={`select-${name}`}>
                                <label htmlFor={`edit-vid:${i}`}>{t(name.toLowerCase().replace(' ', '_'))}: </label>
                                <select onChange={e => params.current[name] = list[e.target.value]}
                                        defaultValue='-1'
                                        name={`vid:${i}`} className="form-select" id={`edit-vid:${i}`}>
                                    <option value="-1" disabled>{t('none')}</option>
                                    {list.sort((a, b) => a.index - b.index).map((it, i) =>
                                        <option key={name + it.name} value={i}>{it.name}</option>
                                    )}
                                </select>
                            </div>
                        }
                    )}
                </div>
                {(item.schema || {}).Pattern && <div className="select-pattern">
                    <div style={{display: 'none'}}>
                        <input type="radio" id="edit-vid:4-" name="vid:4" value=""/>
                        <label className="option" htmlFor="edit-vid:4-"><span>default</span></label>
                    </div>
                    {item.schema.Pattern.map(pattern => {
                        return <div key={`pattern-${pattern.id}`}>
                            <input onChange={() => params.current['Pattern'] = pattern} type="radio"
                                   id={`edit-vid:` + pattern.code} name="vid:4"
                                   value={pattern.code}/>
                            <label className="option" htmlFor={`edit-vid:` + pattern.code}>
                                <img
                                    src={isEmpty(pattern.image) ? '/images/no-image.jpg' : attachmentsAddress + 'pattern/' + pattern.image}
                                    alt={pattern.code} title={pattern.code}/>
                            </label>
                        </div>
                    })}
                </div>}
            </div>
        </DialogContent>
        <DialogActions className="modal-footer">
            <TextField onChange={e => params.current['comment'] = e.target.value} variant='standard'
                       placeholder={t('comment_optional')} title={t('comment_optional')}/>
            <span>{capitalize(t('quantity'))}</span>
            <div className="param--quantity param--quantity--wide">
                <a className="button button-gray"
                   onClick={() => updateCount(count - (item.box_count > 0 ? item.box_count : 1))}>-</a>
                <input
                    onKeyUp={(e) => {
                        if (e.key !== 'Enter' && e.keyCode !== 13) {
                            return
                        }
                        updateCount(e.target.value)
                        addToOrder()
                        // (e.key === 'Enter' || e.keyCode === 13) && addToOrder()
                    }}
                    min='0'
                    onBlur={e => updateCount(e.target.value)}
                    onFocus={e => e.target.select()}
                    type="number" onKeyDown={e => e.key === 'Enter' && updateCount(count)}
                    value={count} onChange={e => setCount(e.target.value)}/>
                <a className="button button-gray"
                   onClick={() => updateCount(count + (item.box_count > 0 ? item.box_count : 1))}>+</a>
            </div>
            <button type="button" className="button" onClick={addToOrder}>{t('add_to_order')}</button>
        </DialogActions>
    </Dialog>
}
export default SelectModal
