import {styled, Table, TableBody, TableRow, TextField, Typography} from "@mui/material";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import {sortBy} from "lodash";
import IconButton from "@mui/material/IconButton";
import {Add, ArrowDownward, ArrowUpward, Delete} from "@mui/icons-material";
import {patternAddress} from "../../../common/backend";
import $ from "jquery";

const StyledTextField = styled(TextField)({fontSize: '0.8 rem', height: 35, width: 80})

const SpecTable = ({items, deleteItem, add, type, update}) => {

    const setImage = (it, img) => {
        it.changed = true
        it.image = img
    }

    const moveUp = (it) => {
        it.changed = true
        const sorted = sortBy(items, 'index')
        const next = sorted[sorted.findIndex(s => s.index === it.index) - 1]

        const prev = it.index
        it.index = next.index
        next.index = prev
        update()
    }

    const moveDown = (it) => {
        it.changed = true
        const sorted = sortBy(items, 'index')
        const next = sorted[sorted.findIndex(s => s.index === it.index) + 1]

        const prev = it.index
        it.index = next.index
        next.index = prev
        update()
    }

    return <Table>
        <TableHead>
            <TableRow>
                <TableCell>
                    <Typography>
                        {type}
                    </Typography>
                </TableCell>
                <TableCell>
                    name
                </TableCell>
                <TableCell>
                    code
                </TableCell>
                {type === 'Pattern' ? <TableCell>image</TableCell> : null}
                <TableCell/>
            </TableRow>
        </TableHead>
        <TableBody>
            {sortBy(items, 'index').map((it, i) => {
                return <TableRow sx={{maxHeight: '90px'}} key={it.id}>
                    <TableCell>
                        <IconButton onClick={() => moveUp(it)} disabled={i === 0}>
                            <ArrowUpward/>
                        </IconButton>
                        <IconButton onClick={() => moveDown(it)} disabled={i === items.length - 1}>
                            <ArrowDownward/>
                        </IconButton>
                    </TableCell>
                    <TableCell>
                        <StyledTextField onChange={e => {
                            it.name = e.target.value
                            it.changed = true
                        }} sx={{width: type === 'Pattern' ? '60px' : '135px'}} size='small'
                                         label='Name' defaultValue={it.name}/>
                    </TableCell>
                    <TableCell>
                        <StyledTextField
                            onChange={e => {
                                it.code = e.target.value
                                it.changed = true
                            }} sx={{width: type === 'Pattern' ? '60px' : '80px'}} size='small' label='Code'
                            defaultValue={it.code}/>
                    </TableCell>
                    {type === 'Pattern' ? <TableCell onClick={() => $("#select-image-" + it.id).click()}>
                        <input name="img" hidden="hidden"
                               id={"select-image-" + it.id}
                               accept="image/png, image/jpg, image/jpeg"
                               onChange={e => setImage(it, e.target.files[0])}
                               type='file' alt='img'/>
                        <img style={{maxHeight: 60, cursor: 'pointer'}}
                             src={typeof it.image === 'string' ? patternAddress + it.image : URL.createObjectURL(it.image)}
                             alt='img'/>
                    </TableCell> : null}
                    <TableCell>
                        <IconButton onClick={() => deleteItem(type, it.id)}>
                            <Delete/>
                        </IconButton>
                    </TableCell>
                </TableRow>
            })}
            <TableRow>
                <TableCell sx={{textAlign: 'center'}}>
                    <IconButton onClick={() => add(type)}>
                        <Add/>
                    </IconButton>
                </TableCell>
            </TableRow>
        </TableBody>
    </Table>
}
export default SpecTable
