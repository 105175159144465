import {CookieConsent as Consent} from "react-cookie-consent";
import * as React from "react";
import Cookies from "js-cookie";
import {useTranslation} from "react-i18next";

const tagManagerCookieName = 'consent-accepted'
const consentCookieName = 'consent'

const CookieConsent = () => {

    const {t} = useTranslation()

    const decline = () => {
        Cookies.set(tagManagerCookieName, 'false', {
            expires: 14,
            secure: window.location ? window.location.protocol === "https:" : true,
            sameSite: 'strict'
        });
        Cookies.set(consentCookieName, 'false', {
            expires: 14,
            secure: window.location ? window.location.protocol === "https:" : true,
            sameSite: 'strict'
        });
        // Remove GTM cookies
        Object.keys(Cookies.get()).filter(it => it.includes('_ga')).forEach(it => Cookies.remove(it))
        // for GTM
        window.location.reload()
    }

    const accept = () => {
        Cookies.remove(tagManagerCookieName)
    }

    return <Consent
        style={{
            background: 'gray'
        }}
        declineCookieValue='false'
        sameSite='strict'
        buttonStyle={{background: '#2c3e50', color: 'white'}}
        buttonText={t('i_agree')}
        onAccept={accept}
        setDeclineCookie={true}
        cookieName={consentCookieName}
    >
        {t('cookie_consent')}&nbsp;
        <span onClick={e => {
            e.preventDefault()
            decline()
        }} style={{textDecoration: 'underline', cursor: 'pointer'}}>{t('cookie_decline')}</span>
    </Consent>
}
export default CookieConsent