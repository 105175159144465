import {TextField} from "@mui/material";
import {useEffect, useState} from "react";
import {useLoader} from "../../../common/Loader";
import {get, PATH_2FA_APPLY, PATH_2FA_SECRET, post} from "../../../common/backend";
import {toast} from "react-hot-toast";
import QRCode from "react-qr-code";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {useAuth} from "../../../common/auth/AuthContext";
import {useTranslation} from "react-i18next";

const AddGoogle2FAModal = ({open, close}) => {
    const [secret, setSecret] = useState(null)
    const {setLoading} = useLoader()
    const {checkLogin} = useAuth()
    const {t} = useTranslation()

    const [otp, setOtp] = useState('')

    useEffect(() => {
        if (!open) {
            return
        }
        if (secret === null) {
            setLoading(true)
            get(PATH_2FA_SECRET, res => {
                setSecret(res.data.qr)
            }, err => {
                toast.error(err.data.message)
            }).finally(() => setLoading(false))
        }
    }, [secret, open])

    if (secret === null) {
        return '';
    }

    const save = () => {
        if (otp.length !== 6) {
            toast.error(t('check_otp'))
            return
        }
        setLoading(true)
        post(PATH_2FA_APPLY, () => {
            toast.success(t('done'))
            checkLogin()
            close()
        }, err => toast.error((err.response.data.message || err.response.data.error.message)), {key: otp}).finally(() => setLoading(false))
    }

    return <Dialog open={open} onClose={close}>
        <DialogContent>
            <DialogTitle>
                {t('scan_with_google_authenticator')}
            </DialogTitle>
            <QRCode style={{display: 'block', margin: 'auto'}} value={secret}/>
            <Typography sx={{marginTop: '20px', textAlign: 'center'}}
                        variant='h5'>{t('enter_one_time_password')}</Typography>
            <TextField type='number' value={otp} onChange={e => setOtp(e.target.value)} sx={{width: '100%'}}
                       label={'OTP'} autoComplete='off'/>
        </DialogContent>
        <DialogActions>
            <Button onClick={close}>{t('discard')}</Button>
            <Button onClick={save}>{t('save')}</Button>
        </DialogActions>
    </Dialog>
}
export default AddGoogle2FAModal
