import {FC, memo} from 'react';
import DraggableListItem from './DraggableListItem';
import {DragDropContext, Droppable, OnDragEndResponder,} from 'react-beautiful-dnd';
import {List} from '@mui/material';

export interface Item {
    id: number;
    name: string;
    link: string;
    parent: number | null;
    factory: 1 | 2;
    order: number;
    banner: string | null;
    description: string | null;
    index: number;
}

export type Props = {
    items: Item[];
    onDragEnd: OnDragEndResponder;
};

const DraggableList: FC<Props> = memo(({items, onDragEnd}) => {
    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable-list">
                {(provided) => (
                    <List ref={provided.innerRef} {...provided.droppableProps}>
                        {items.map((item: Item, index: number) => (
                            <DraggableListItem item={item} index={index} key={item.id}
                                               move={(from: number, to: number) => {
                                                   if (to < 0 || to > items.length) return;
                                                   onDragEnd({
                                                       destination: {index: to},
                                                       source: {index: from},
                                                   })
                                               }}/>
                        ))}
                        {provided.placeholder}
                    </List>
                )}
            </Droppable>
        </DragDropContext>
    );
});

export default DraggableList;
