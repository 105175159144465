import {useTranslation} from "react-i18next";

const Company = () => {
    const {t} = useTranslation()
    document.title = t('page_company') + ' | R!met.CN'
    return <article className="col-12 col-xl-9 content search-results">
        <div className="row">
            <div className="col-12 company-description">
                <p>{t('about_1')}</p>
                <p>{t('about_2')}</p>
                <p>{t('about_3')}</p>
                <p>{t('about_4')}</p>
                <p>{t('about_5')}</p>
                <p className="text-center">{t('about_6')}</p>
            </div>
        </div>
    </article>
}
export default Company
