import {useLoader} from "../../../common/Loader";
import {useEffect, useRef, useState} from "react";
import {get, getErrorMessage, PATH_INFO_ALL, PATH_INFO_SET, post} from "../../../common/backend";
import {toast} from "react-hot-toast";
import {
    Checkbox,
    Fab,
    Grid,
    IconButton,
    Paper,
    Tab,
    Table,
    TableBody,
    TableContainer,
    TableRow,
    Tabs,
    TextField
} from "@mui/material";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import {Add, ArrowDownward, ArrowUpward, Delete, Save} from "@mui/icons-material";
import {clone} from "lodash";
import {getAdminPageTitle, swapArrayLocs} from "../../../common/commons";

/**
 * @typedef Row
 * @property {number} id
 * @property {string} text
 * @property {string} small
 * @property {string} link
 * @property {boolean} delimiter
 */

const InfoEdit = () => {

    document.title = getAdminPageTitle('Info')

    const {setLoading} = useLoader()

    const [items, setItems] = useState({})
    const [newLngCode, setNewLngCode] = useState('')
    const [currentLng, setCurrentLng] = useState('')

    const id = useRef(0)

    useEffect(() => {
        get(PATH_INFO_ALL, res => {
            Object.values(res.data).forEach(list => {
                list.forEach(it => it.id = id.current++)
            })
            setCurrentLng(Object.keys(res.data)[0])
            setItems(res.data)
        }, err => toast.error(getErrorMessage(err))).finally(() => setLoading(false))
    }, [])

    const addRow = () => {
        items[currentLng].push({text: '', small: '', del: false, id: id.current++})
        setItems(clone(items))
    }

    const removeRow = (id) => {
        items[currentLng] = items[currentLng].filter(it => it.id !== id)
        setItems(clone(items))
    }

    const addLng = () => {
        if (newLngCode.length === 0) {
            toast.error('Empty code')
            return
        }
        if (newLngCode in items) {
            toast.error(newLngCode + ' already added')
            return
        }
        items[newLngCode] = []
        setItems(clone(items))
        setCurrentLng(newLngCode)
        setNewLngCode('')
    }

    const save = () => {
        setLoading(true)
        post(PATH_INFO_SET, res => {
            Object.values(res.data).forEach(it => {
                it.id = id.current++
            })
            setItems(res.data)
            toast.success('Done')
        }, res => {
            toast.error(res)
        }, {items}).finally(() => setLoading(false))
    }

    const swapUp = (i) => {
        swapArrayLocs(items[currentLng], i, i - 1)
        setItems(clone(items))
    }

    const swapDown = (i) => {
        swapArrayLocs(items[currentLng], i, i + 1);
        setItems(clone(items));
    }

    if (Object.keys(items).length === 0) {
        return null
    }

    return [
        <Grid display='flex' justifyContent='center' spacing={2} direction='row' container key='tabs'
              sx={{borderBottom: 1, borderColor: 'divider', padding: '5px'}}>
            <Grid item>
                <Tabs onChange={(_, v) => {
                    setCurrentLng(v)
                }} value={currentLng} key='tabs'>
                    {Object.keys(items).map(it => {
                        return <Tab value={it} key={it} label={it}/>
                    })}
                </Tabs>
            </Grid>
            <Grid item>
                <TextField variant='standard' label='Language code (en,ru)' value={newLngCode} onChange={e => {
                    setNewLngCode(e.target.value.toLowerCase())
                }}/>
            </Grid>
            <Grid item>
                <IconButton onClick={addLng} size='medium'>
                    <Add fontSize='medium'/>
                </IconButton>
            </Grid>
        </Grid>,
        <TableContainer key='table' sx={{textAlign: 'center', marginTop: '10px'}} component={Paper}>
            <Table sx={{maxWidth: '750px', margin: 'auto'}}>
                <TableHead>
                    <TableRow>
                        <TableCell/>
                        {['Main', 'Small', 'Link', 'Del'].map(it => <TableCell key={it}>{it}</TableCell>)}
                        <TableCell>
                            <IconButton disabled>
                                <Delete/>
                            </IconButton>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {items[currentLng].map((it, i) => {
                        return <TableRow key={it.id}>
                            <TableCell>
                                <Grid container direction='column'>
                                    <IconButton onClick={() => swapUp(i)} disabled={i === 0}>
                                        <ArrowUpward/>
                                    </IconButton>
                                    <IconButton onClick={() => swapDown(i)}
                                                disabled={i === items[currentLng].length - 1}>
                                        <ArrowDownward/>
                                    </IconButton>
                                </Grid>
                            </TableCell>
                            <TableCell>
                                <TextField onChange={e => it.text = e.target.value} sx={{width: '120px'}}
                                           multiline maxRows={5}
                                           label='Main text' variant='standard'
                                           defaultValue={it.text}/>
                            </TableCell>
                            <TableCell>
                                <TextField onChange={e => it.small = e.target.value} sx={{width: '120px'}}
                                           multiline maxRows={5}
                                           label='Small text' variant='standard'
                                           defaultValue={it.small}/>
                            </TableCell>
                            <TableCell>
                                <TextField onChange={e => it.link = e.target.value} sx={{width: '120px'}} label='Link'
                                           multiline maxRows={5}
                                           variant='standard'
                                           defaultValue={it.link}/>
                            </TableCell>
                            <TableCell sx={{maxWidth: '30px'}}>
                                <Checkbox onChange={e => it.del = e.target.checked} defaultChecked={it.del}/>
                            </TableCell>
                            <TableCell>
                                <IconButton onClick={() => removeRow(it.id)}>
                                    <Delete/>
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    })}
                    <TableRow>
                        <TableCell/>
                        <TableCell/>
                        <TableCell>
                            <IconButton onClick={() => addRow()}>
                                <Add/>
                            </IconButton>
                        </TableCell>
                        <TableCell/>
                        <TableCell/>
                        <TableCell/>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>,
        <Fab key='save' sx={{
            position: "fixed",
            bottom: (theme) => theme.spacing(2),
            right: (theme) => theme.spacing(2)
        }} onClick={save} color="primary">
            <Save/>
        </Fab>
    ]
}

export default InfoEdit
