import * as React from "react";
import {useEffect, useState} from "react";
import {CurrencyExchange} from "@mui/icons-material";
import Button from "@mui/material/Button";
import {apiRequest, get, PATH_EXCHANGE_RATE, PATH_UPDATE_EXCHANGE_RATE} from "../../common/backend";
import Tooltip from "@mui/material/Tooltip";
import {clone, round} from "lodash";
import {toast} from "react-hot-toast";

const defaultState = {updated_at: null, val: null}

const ExchangeRate = () => {
    const [rate, setRate] = useState(clone(defaultState))
    const [loading, setLoading] = useState(true)

    const animation = () => loading ? {
        animation: "spin 2s linear infinite",
        "@keyframes spin": {
            "100%": {
                transform: "rotate(360deg)",
            },
            "0%": {
                transform: "rotate(0deg)",
            },
        },
    } : {}

    const red = () => {
        if (loading) {
            return '#bdbdbd'
        }
        const updated_at = new Date(rate.updated_at);
        const now = new Date();
        const diffDays = Math.ceil(Math.abs(updated_at - now) / (1000 * 60 * 60 * 24));
        if (diffDays > 2) {
            return 'red'
        }
        return '#bdbdbd'
    }

    const updateExchangeRate = () => {
        if (loading) {
            toast.error('Wait for updating')
            return
        }

        setLoading(true)

        apiRequest(PATH_UPDATE_EXCHANGE_RATE, (res) => {
            const state = {}
            state.updated_at = res.data.updated_at.value
            state.val = round(parseFloat(res.data.rate.value), 4)
            setRate(state)
        }, () => {
            toast.error('Error')
        }, undefined, 'get')
            .finally(() => {
                toast.success('Updated')
                setLoading(false)
            })
    }

    useEffect(() => {
        get(PATH_EXCHANGE_RATE, res => {
            const state = {}
            state.updated_at = res.data.updated_at
            state.val = round(parseFloat(res.data.rate), 4)
            setRate(state)
            setLoading(false)
        })
    }, [])

    return <Tooltip title={`Updated at: ${rate.updated_at}`}>
        <Button onClick={updateExchangeRate}
                sx={{backgroundColor: red(), color: 'white', ':hover': {backgroundColor: red()}, marginRight: '5px'}}
                startIcon={<CurrencyExchange sx={animation()}/>}>{rate.val || ''}</Button>
    </Tooltip>
}

export default ExchangeRate;
