import {useLoader} from "../../../common/Loader";
import {deleteR, getErrorMessage, PATH_USERS_DELETE} from "../../../common/backend";
import {toast} from "react-hot-toast";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";

const DeleteUserDialog = ({open, user, close, onDelete}) => {
    user = user || {}

    const {setLoading} = useLoader()

    const deleteUser = () => {
        setLoading(true)
        deleteR(PATH_USERS_DELETE + user.id, () => {
            toast.success('Done')
            close()
            onDelete()
        }, res => toast.error(getErrorMessage(res))).finally(() => setLoading(false))
    }

    return <Dialog open={open} onClose={close}>
        <DialogTitle>Delete user {user.email}?</DialogTitle>
        <DialogActions>
            <Button onClick={close}>No</Button>
            <Button onClick={deleteUser}>Yes</Button>
        </DialogActions>
    </Dialog>
}
export default DeleteUserDialog