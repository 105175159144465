import {useParams} from "react-router";
import * as React from "react";
import {useEffect, useMemo, useRef, useState} from "react";
import {isEmpty, take, uniq} from "lodash";
import $ from 'jquery';
import {attachmentsAddress, get, PATH_VIEW_PRODUCTS_BY_CATALOG_NUMBER} from "../../common/backend";
import {useLoader} from "../../common/Loader";
import {ArrowDownward} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import {addToCart, getCartItems} from "../../common/cart";
import {toast} from "react-hot-toast";
import {default as SupplierPriceModal} from '../../admin/page/supplier/ProductModal'
import CategoryCarousel from "./CategoryCarousel";
import OldOrders from "./OldOrders";
import {useTranslation} from "react-i18next";
import SelectedItems from "./SelectedItems";
import DimensionsTable from "./DimensionsTable";
import DescriptionTable from "./description/DescriptionTable";
import {useAuth} from "../../common/auth/AuthContext";
import {Fab} from "@mui/material";
import {Link} from "react-router-dom";
import NotFoundPage from "../common/NotFoundPage";
import {Fancybox} from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import SelectModal from "./SelectModal";
import {useCurrentCategory} from "../common/context/CurrentCategoryContext";

const Product = () => {
    const sku = useParams().sku
    const lastSku = useRef(sku)

    const {setLoading} = useLoader()
    const {t} = useTranslation(['translation', 'description', 'material', 'filter'])
    const [data, setData] = useState([])
    const [attachments, setAttachments] = useState([])

    const [select, setSelect] = useState(null)

    const setCurrentCategories = useCurrentCategory().setCurrent

    // const gallery = useRef(null)

    const [diagramShown, setDiagramShown] = useState(false)
    const [filters, setFilters] = useState([])
    const [cartItems, setCartItems] = useState(getCartItems().filter(it => it.catalog_number === sku))
    const [supplierPriceItem, setSupplierPriceItem] = useState(null)

    const notFound = useRef(false)

    const isAdmin = (useAuth().user || {}).isAdmin ?? false

    const availableFilters = useMemo(() => uniq(data.map(it => it.filter).filter(it => !isEmpty(it))), [data])

    useEffect(() => {
        let viewed = localStorage.getItem('last-viewed') || ''
        viewed = viewed.split(',')
        viewed.unshift(sku)
        viewed = uniq(viewed)
        viewed = take(viewed, 4)
        localStorage.setItem('last-viewed', viewed.join(','))
        window.addEventListener('storage', () => setCartItems(getCartItems().filter(it => it.catalog_number === sku)))
        window.dispatchEvent(new Event('storage'))
    }, [sku])

    useEffect(() => {
        if (lastSku.current !== sku) {
            notFound.current = false
        }
        if ((isEmpty(data) || lastSku.current !== sku) && !notFound.current) {
            setLoading(true)
            lastSku.current = sku
            get(PATH_VIEW_PRODUCTS_BY_CATALOG_NUMBER + sku + '?supplier_price=true', res => {
                const data = Object.values(res.data.data)[0];
                document.title = data[0].catalog_number + ' | R!met.CN'
                setData(data)
                const newCurrentCategories = data[0].categories
                Object.defineProperty(newCurrentCategories, 'noNavigate', {
                    enumerable: false,
                    writable: true,
                    value: true
                })
                setCurrentCategories(data[0].categories)
                setAttachments(data[0].attachments);
                setLoading(false);
            }, e => {
                if (e.response.status === 404) {
                    notFound.current = true
                }
                setLoading(false)
            }).finally(() => updateCart())
        }
    }, [sku]);

    useEffect(() => {
        Fancybox.defaults = {
            ...Fancybox.defaults,
            Thumbs: {
                type: "classic",
            },
        }
        Fancybox.bind('[data-fancybox="gallery"]', {
            caption: function (_fancybox, slide) {
                const figcaption = slide.triggerEl.nextSibling.innerHTML
                return figcaption !== '' ? figcaption : slide.caption || ''
            },
            Html: {
                videoTpl: '<video class="fancybox__html5video" preload="metadata" playsinline controls controlsList="nodownload" poster="{{poster}}">' +
                    '<source src="{{src}}" type="{{format}}"/>' +
                    'Sorry, your browser doesn\'t support embedded videos.</video>'
            },
            on: {
                loaded: (f, s) => {
                    if (!s.src.endsWith('.mp4')) {
                        return;
                    }
                    console.log(f)
                    console.log(s.src)
                }
            }
        })
    })

    function handleOnclick() {
        let elem = document.querySelector('.card--content .card-spacer ~ .row > div > figure')
        if (elem) elem.click()
    }

    const defaultImage = () => {
        let attachment = attachments.find(it => it.type === 'gif' || it.type === 'img')
        let src;
        if (attachment !== undefined) {
            src = `${attachmentsAddress}${attachment.id}/full.${attachment.extension}`
        } else {
            src = '/images/no-image.jpg'
            attachment = {name: 'No Image'}
        }

        return <img src={src} alt={attachment.name} onClick={handleOnclick}/>
    }

    const addFilter = filter => {
        const newFilters = filters.filter(_ => true)
        newFilters.push(filter)
        setFilters(newFilters)
    }

    const removeFilter = filter => {
        const newFilters = filters.filter(it => it !== filter)
        setFilters(newFilters)
    }

    const cartAdd = () => {
        const items = data.filter(it => it.selected)
        if (items.length === 0) {
            toast.error(t('cart_no_items_selected'))
            return
        }
        if (items.find(it => !it.buy_count || it.buy_count < 0)) {
            toast.error(t('cart_select_count'))
            return
        }
        addToCart(items)
        updateCart(items)
        toast.success(t('done'))
    }

    const updateCart = (items = undefined) => {
        if (items) {
            setCartItems(items)
            return
        }
        setCartItems(getCartItems().filter(it => it.catalog_number === sku))
    }

    const fixAlign = {}

    const scrollProductUp = () => {
        document.querySelector('main').scrollIntoView({behavior: 'smooth', block: 'start'})
    }

    if (attachments.filter(it => it.type !== 'file' && it.type !== 'diagram').length > 9) {
        fixAlign.alignItems = 'start'
    }

    const filteredAttachments = attachments.filter(it => it.type !== 'file' && it.type !== 'diagram')
    const imagesCount = filteredAttachments.length
    const seeMore = <span className="see-more"
                          onClick={() => setHidePictures(false)}>+{imagesCount - 9} {t('photos')}</span>

    const [hidePictures, setHidePictures] = useState(false)

    useEffect(() => {
        if (imagesCount > 9) setHidePictures(true)
        else setHidePictures(false)
    }, [imagesCount])

    // $('.card').each((_, it) => $(it).css('height', `${it.offsetWidth}px`))
    const btnW = $('.param--btn button').width();
    const allW = $('.card--cart-btn').width();
    $('.card--cart-btn--arrow').width(`${btnW}px`);
    $('.card--cart-btn--btn').width(`${allW - btnW}px`);

    if (notFound.current) {
        return <NotFoundPage/>
    }

    return <>
        <SelectModal nullableItem={select} close={() => {
            setSelect(null)
            updateCart()
        }}/>
        <article className="col-12 col-xl-9 content">
            {(data[0] || {}).supplier_price ?
                <SupplierPriceModal item={supplierPriceItem} close={() => setSupplierPriceItem(null)}/>
                : null}
            <div className="row">
                <div className="col-12 card--body">
                    <div style={fixAlign} className="row">
                        <div className="col-12 col-md-7 card--logo">
                            <div className="card card_first edit-button-parent">
                                <Fab hidden={!isAdmin} className='edit-button-child' target='_blank' size='large' sx={{
                                    position: "absolute",
                                    top: (theme) => theme.spacing(2),
                                    right: (theme) => theme.spacing(2)
                                }} color="primary" to={`/admin/products/${sku}`} component={Link}>
                                    Edit
                                </Fab>
                                {defaultImage()}
                            </div>
                        </div>
                        <div className="col-12 col-md-5 card--content">
                            <div className="row">
                                <div className="col-12">
                                    <div className="row">
                                        <span className="card--code">{t('catalog_code')}:</span>
                                        {/*<span className="card--go-back"><Link to='/'>view catalog</Link></span>*/}
                                    </div>
                                    <div className="row">
                                        <h1 className="card--title">{(data[0] || {}).catalog_number}</h1>
                                    </div>
                                    {
                                        isEmpty((data[0] || {}).description) ? '' : <div className="row">
                                            <p dangerouslySetInnerHTML={{__html: t(data[0].catalog_number, {ns: 'description'}).replaceAll('\n', '<br/>')}}
                                               className="card--description"/>
                                        </div>
                                    }
                                    {
                                        (isEmpty(data) || isEmpty(t(data[0].code, {
                                            ns: 'material',
                                            defaultValue: null
                                        }))) ? '' :
                                            <div className="row">
                                                <p className="card--description">
                                                    {t('material')}: {t(data[0].code, {
                                                    ns: 'material'
                                                })}
                                                </p>
                                            </div>
                                    }
                                    <div className="card-spacer"/>
                                    <div className="product-miniatures">
                                        {filteredAttachments.map((picture, i) => {
                                            return <div key={picture.id}
                                                        className={(i > 8 && hidePictures) ? "hidden" : null}>
                                                <figure
                                                    data-fancybox="gallery"
                                                    key={picture.id}
                                                    data-src={`${attachmentsAddress}${picture.id}/full.${picture.extension}`}
                                                    className="card"
                                                >
                                                    <img
                                                        src={`${attachmentsAddress}${picture.id}/card.jpg`}
                                                        alt={picture.name}
                                                    />
                                                </figure>
                                                <figcaption>{}</figcaption>
                                            </div>
                                        })}
                                        {hidePictures ? seeMore : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="row">
                        {attachments.filter(it => it.type === 'file').map((it, i) =>
                            <a key={`file-${i}`} style={{paddingBottom: '10px', paddingRight: '6px'}}
                               target='_blank' rel='noreferrer'
                               download={it.name}
                               href={`${attachmentsAddress}${it.id}/full.${it.extension}`}>
                                <figure className="figure">
                                    <img style={{
                                        width: '60px',
                                        display: 'block',
                                        marginRight: 'auto',
                                        marginLeft: 'auto'
                                    }}
                                         src={`${attachmentsAddress}${it.id}/card.jpg`}
                                         className="figure-img img-fluid rounded"
                                         alt={it.name}/>
                                    <figcaption style={{textAlign: 'center'}}
                                                className="figure-caption">{it.name}</figcaption>
                                </figure>
                            </a>
                        )}
                    </div>
                    <div className="row diagram">
                        {attachments.filter(it => it.type === 'diagram').map(it => {
                            return [
                                <div key={`show-${it.id}`} className='col-12'>
                                    <IconButton onClick={() => {
                                        if (it.shown === undefined) {
                                            it.shown = false;
                                        }
                                        it.shown = !it.shown;
                                        setDiagramShown(!diagramShown)
                                    }}>{<ArrowDownward sx={{
                                        transitionDuration: '0.4s',
                                        transitionProperty: 'transform',
                                        transform: it.shown ? 'rotate(180deg)' : ''
                                    }}/>} <span className="icon-button">{it.name}</span></IconButton>
                                </div>,
                                <img key={`diagram-${it.id}`}
                                     className={`fadeable ${it.shown ? 'show' : ''}`}
                                     style={{width: '100%', display: 'block'}}
                                     src={`${attachmentsAddress}${it.id}/full.${it.extension}`} alt=''/>
                            ]
                        })}
                    </div>
                </div>
                <DimensionsTable data={data}/>
                {availableFilters.length === 0 ? '' :
                    <div className="col-12 card--filters">
                        <span className="dimension-table--filger-header">{t('filters')}:</span>
                        {availableFilters.map(it =>
                            <label htmlFor={`filter-${it}`} className="filter-point" key={`filter-${it}`}>
                                <input className="filter--input"
                                       onChange={e => e.target.checked ? addFilter(it) : removeFilter(it)}
                                       type="checkbox" name="filters" id={`filter-${it}`}/>
                                <span className="filter--mask"></span>
                                <span>{t(it, {ns: 'filter'})}</span>
                            </label>)}
                    </div>}
                <div className="col-12 card--information product-table" style={{padding: 0}}>
                    <DescriptionTable setSelect={setSelect} setSupplierPriceItem={setSupplierPriceItem} data={data}
                                      filters={filters} cartItems={cartItems}/>
                </div>
                <div className="col-12 card--order-btn--box">
                    <div className="row">
                        <div className="row col-12 justify-content-between" style={{paddingRight: '0'}}>
                            <OldOrders sku={sku} availableItems={data}/>
                            {isEmpty((data[0] || {}).schema) && <div className="row card--order-btn">
                                <div className="col-12" style={{cursor: 'pointer'}} onClick={cartAdd}>
                                    <div style={{width: '210px'}}
                                         className="card--order-btn--btn">{t('add_to_cart')}</div>
                                    <div style={{width: '75px'}} className="card--order-btn--arrow">
                                        <img src="/images/order-btn--arrow.png" alt="ar"/>
                                    </div>
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>
                <SelectedItems cartItems={cartItems} updateCart={updateCart}/>
            </div>
        </article>
        <CategoryCarousel handleLink={scrollProductUp} categories={(data[0] || {}).categories} catalogNumber={sku}/>
    </>
}
export default Product
