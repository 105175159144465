import React, {useContext, useEffect, useState} from "react";
import {
    get,
    getErrorMessage,
    PATH_LOGIN,
    PATH_REGISTER,
    PATH_USER,
    PATH_USER_SET_LANGUAGE,
    post,
    put,
    ROLE_ADMIN,
    ROLE_SUPER_ADMIN
} from "../backend";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import {toast} from "react-hot-toast";
import i18next from "i18next";

const LOGIN_SUCCESS = 1
const LOGIN_2FA = 2
export {LOGIN_SUCCESS, LOGIN_2FA}

const AuthContext = React.createContext(null);

const setAPIKey = key => localStorage.setItem('login', key)

const resetAPIKey = () => localStorage.removeItem('login')

const getAPIKey = () => localStorage.getItem('login')

/**
 * @returns {{
 * authorized: (boolean|null),
 * user: ({permissions:string[], isSuperAdmin:boolean, isAdmin:boolean, hasPerm:(...string) => boolean, role: [0,1,2]}|null),
 * logout: () => void,
 * checkLogin: () => void,
 * login: (username: string, password: string, otp: string|null) => Promise,
 * register: (data) => Promise
 * }|null}
 */
const useAuth = () => {
    const context = useContext(AuthContext)
    if (!context) throw new Error('useAuth must be used inside AuthContext')
    return context
}

export {useAuth, setAPIKey, getAPIKey, resetAPIKey}

const AuthContextProvider = ({children}) => {
    const [authState, setAuthState2] = useState({authorized: null, user: null})

    const setAuthState = state => {
        if (state.user instanceof Object) {
            const user = state.user
            user.permissions = user.perm
            user.isSuperAdmin = user.role === ROLE_SUPER_ADMIN
            user.isAdmin = user.role >= ROLE_ADMIN
            user.hasPerm = (...permission) => user.isSuperAdmin || permission.every(it => it === 'admin' ? user.role === ROLE_ADMIN : user.permissions.includes(it))
        }
        setAuthState2(state)
    }

    const {executeRecaptcha} = useGoogleReCaptcha()

    const checkLogin = () => {
        if (getAPIKey() === null) {
            setAuthState({authorized: false, user: null})
            return;
        }
        if (authState.authorized === false) {
            return
        }
        get(PATH_USER, res => {
            if (res.data.success) {
                setAuthState({authorized: true, user: res.data.user})
            }
        }, e => {
            if (e.status === 403) {
                resetAPIKey()
            }
            setAuthState({authorized: false, user: null})
        })
    }

    useEffect(() => {
        if (authState.user && i18next.language && authState.user.lang !== i18next.language) {
            put(PATH_USER_SET_LANGUAGE + i18next.language, () => {
            }, () => {
            })
        }
    }, [authState.user]);

    useEffect(() => checkLogin(), [])

    const login = (username, password, otp = null) => {
        if (authState.authorized === true) return null;
        if (!executeRecaptcha) {
            toast.error('Wait for captcha')
            return null;
        }
        return new Promise(async (resolve, reject) => {
            const data = {email: username, password, captcha: await executeRecaptcha('login')}
            if (otp !== null && otp.length === 6) {
                data.otp = otp
            }
            post(PATH_LOGIN, res => {
                if (res.data["2fa"]) {
                    resolve(LOGIN_2FA)
                    return
                }
                setAPIKey(res.data.token)
                setAuthState({authorized: true, user: res.data.user})
                resolve(LOGIN_SUCCESS)
            }, res => reject(getErrorMessage(res)), data)
        })
    }

    const register = data => {
        if (authState.authorized === true) return null;
        if (!executeRecaptcha) {
            toast.error('Wait for captcha')
            return null;
        }
        data.lang = i18next.language
        return new Promise(async (resolve, reject) => {
            data.captcha = await executeRecaptcha('register')
            post(PATH_REGISTER, res => {
                setAPIKey(res.data.token)
                setAuthState({authorized: true, user: res.data.user})
                resolve()
            }, err => reject(err), data)
        })
    }

    const logout = () => {
        setAPIKey(null)
        checkLogin()
    }

    return <AuthContext.Provider children={children} value={{...authState, login, register, logout, checkLogin}}/>
}

export default AuthContextProvider
