import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import axios from "axios";
import './common/i18n';
import {Backdrop, CircularProgress} from "@mui/material";

axios.defaults.withCredentials = true

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <BrowserRouter>
        <React.Suspense fallback={<Backdrop style={{zIndex: 9000}} open={true}><CircularProgress/></Backdrop>}>
            <App/>
        </React.Suspense>
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
