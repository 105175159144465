import {Link} from "react-router-dom";
import {useParams} from "react-router";
import {forwardRef, useImperativeHandle, useState} from "react";
import {useTranslation} from "react-i18next";
import {useCurrentCategory} from "../context/CurrentCategoryContext";
import {last} from "lodash";

const LeftCategory = forwardRef((props, ref) => {
    const {category, closeAll} = props
    let currentCategory = useCurrentCategory().current
    currentCategory = parseInt(last(Array.isArray(currentCategory) ? currentCategory : Object.keys(currentCategory)))
    const link = useParams().cat_link || ''

    const [open, setOpen] = useState(category.link === link || category.children.some(it => it.link === link))

    const {t} = useTranslation('category')

    useImperativeHandle(ref, () => ({close: () => setOpen(false), open: () => setOpen(true), id: category.id}));

    const selectedClass = link === category.link || currentCategory === category.id ? 'sidebar-nav-selected' : ''

    const children = category.children.sort((f, s) => s.order - f.order).map(it => {
            return <li key={it.id}>
                <Link className={link === it.link || currentCategory === it.id ? 'sidebar-nav-selected' : ''}
                      onClick={() => {
                          props.onClick();
                          closeAll(it)
                      }} to={`/c/${it.link}`}>
                    {t(it.name)}
                </Link>
            </li>
        }
    )

    const result = [
        <Link className={selectedClass} key='link' onClick={() => {
            props.onClick();
            closeAll(category)
        }}
              to={`/c/${category.link}`}>
            {t(category.name)}
        </Link>
    ];
    if (children.length !== 0) {
        result.push(<div key='toggle' className="submenu-toggle" onClick={() => setOpen(!open)}>
            <i style={{transform: open ? 'rotate(180deg)' : ''}} className="fas fa-chevron-down"/>
        </div>)
        result.push(<ul key='submenu' className={`submenu ${open ? 'd-block' : ''}`}>{children}</ul>)
        result[0] = <Link className={selectedClass} to={`/c/${category.link}`}
                          onClick={() => {
                              props.onClick()
                              setOpen(true)
                              closeAll(category)
                          }}
                          style={{cursor: 'pointer'}} key='link'>
            {t(category.name)}
        </Link>
    }

    return <li className={children.length ? 'submenu-wrap' : ''} key={category.id}>
        {result}
    </li>
})
export default LeftCategory
