import * as React from "react";
import {every, isEmpty, sortBy} from "lodash";
import {useTranslation} from "react-i18next";

const DimensionsTable = ({data}) => {

    const {t} = useTranslation(['translation', 'name'])

    if (isEmpty(data)) {
        return null
    }
    if (every(data, item =>
        every(['length', 'width', 'height'], val => {
            const int = parseInt(item[val])
            return isNaN(int) || int === 0
        })
    )) {
        return null
    }

    const getVal = val => {
        const int = parseInt(val)
        if (isNaN(int) || int === 0) {
            return '-'
        } else {
            return int
        }
    }

    return <div className="col-12 col-md-8 card--information card--information_param dimensions-table">
        <div className="param--title param--title_icon">
            <span className="param--text">{t('name')}</span>
        </div>
        <div className="param--title param--title_icon">
            <img src="/images/icon-C-500.png" alt="p1"/>
        </div>
        <div className="param--title param--title_icon">
            <img src="/images/icon-M-500.png" alt="p2"/>
        </div>
        <div className="param--title param--title_icon">
            <img className="big_L" src="/images/icon-L-500.png" alt="p3"/>
        </div>
        <div className="param--title param--title_icon">
            <img src="/images/icon-W-500.png" alt="p4"/>
        </div>
        <div className="param--title param--title_icon">
            <img className="big_H" src="/images/icon-H-500.png" alt="p5"/>
        </div>
        {sortBy(data, 'index').map(it => [
            <div className="param--value param--value_second" key={`${it.name}`}>
                <span>{it.name}</span>
            </div>,
            <div className="param--value param--value_second" key={`${it.name}-c-${it.size}`}>
                <span>{getVal(it.size)}</span>
            </div>,
            <div className="param--value param--value_second" key={`${it.name}-m-${it.one_weight}`}>
                <span>{getVal(it.one_weight) === '-' ? '-' : getVal(it.one_weight) + 'g'}</span>
            </div>,
            <div className="param--value param--value_second" key={`${it.name}-l-${it.length}`}>
                <span>{getVal(it.length)}</span>
            </div>,
            <div className="param--value param--value_second" key={`${it.name}-w-${it.width}`}>
                <span>{getVal(it.width)}</span>
            </div>,
            <div className="param--value param--value_second" key={`${it.name}-h-${it.height}`}>
                <span>{getVal(it.height)}</span>
            </div>
        ])}
    </div>
}
export default DimensionsTable
