import * as React from "react";
import {useState} from "react";
import Footer from "./Footer";
import Header from "./header/Header";
import LeftMenu from "./left_menu/LeftMenu";
import OrderVolume from "./OrderVolume";
import LoginForm from "./auth/LoginForm";
import RegisterForm from "./auth/RegisterForm";
import ScrollToTop from "react-scroll-to-top";
import SiteNav from "./SiteNav";
import {useTheme} from "../../common/ThemeProvider";
import ExchangeRate from "../../admin/other/ExchangeRate";
import {useAuth} from "../../common/auth/AuthContext";
import CurrentCategoryContext from "./context/CurrentCategoryContext";
import BackupDate from "../../admin/other/BackupDate";

const BasePage = ({page, overridePageName = null}) => {
    const [loginOpen, setLoginOpen] = useState(false)
    const [registerOpen, setRegisterOpen] = useState(false)
    const {color} = useTheme()
    const isAdmin = (useAuth().user || {}).isAdmin ?? false

    const pageName = overridePageName ? overridePageName : !!page ? page.type.name : null

    return [
        <Header key='header' openLogin={() => setLoginOpen(true)} openRegister={() => setRegisterOpen(true)}/>,
        <LoginForm key='login-form' open={loginOpen} close={() => setLoginOpen(false)}/>,
        <RegisterForm key='register-form' open={registerOpen} close={() => setRegisterOpen(false)}/>,
        <main key='main' style={{'--clr': color}}>
            <div className="header--volume">
                <div className="container">
                    <SiteNav/>
                    {isAdmin && <><ExchangeRate/><BackupDate/></>}
                    <OrderVolume/>
                </div>
            </div>
            <div className='container'>
                <div className="col-12">
                    <div className="row">
                        <CurrentCategoryContext>
                            <LeftMenu currentPage={pageName}/>
                            {page}
                        </CurrentCategoryContext>
                    </div>
                </div>
            </div>
        </main>,
        <Footer key='footer'/>,
        <ScrollToTop key='scroll-to-top' smooth/>
    ];
}

export default BasePage
