import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import ItemsTable from "../../items_table/ItemsTable";
import {Download, Edit, ShoppingCartCheckout} from "@mui/icons-material";
import {useEffect, useState} from "react";
import {useLoader} from "../../../common/Loader";
import {downloadOrder, wrapTooltip} from "../../../common/commons";
import {getErrorMessage, PATH_ORDER_UPDATE, put} from "../../../common/backend";
import {toast} from "react-hot-toast";
import {addToCart, clearCart, deliveryNote, getCartItems, itemsEqual} from "../../../common/cart";
import {useTranslation} from "react-i18next";
import {cloneDeep, isEmpty} from "lodash";
import {useNavigate} from "react-router";

const emptyOrder = {items: [], created_at: ''}

const OrderModal = ({order, open, close}) => {
    order = order ?? emptyOrder

    const {t} = useTranslation()
    const {setLoading} = useLoader()

    const [editable, setEditable] = useState(false)
    const [beforeEditing, setBeforeEditing] = useState()

    const [copyOrder, setCopyOrder] = useState(false)

    const navigate = useNavigate()

    useEffect(() => {
        setEditable(false)
        setBeforeEditing(cloneDeep(order.items))
    }, [order])

    const updateOrder = () => {
        if (order.items.length === 0) {
            toast.error(t('cart_empty'))
            return
        }
        setLoading(true)
        const note = deliveryNote(order.items)
        delete note[1]['ns'];
        put(PATH_ORDER_UPDATE + order.id,
            () => toast.success(t('done')),
            res => {
                toast.error(getErrorMessage(res))
                order.items = cloneDeep(beforeEditing)
            },
            {
                items: order.items,
                delivery_note: note
            }
        ).finally(() => setLoading(false))
    }

    const updateEditable = () => {
        if (!editable) {
            setEditable(true)
            return
        }
        updateOrder()
        setEditable(false)
    }

    const Comment = () => {
        if (editable) {
            return <textarea rows="5" placeholder={t('comment')}
                             className="order-modal order-input_textarea">{order.comment}</textarea>
        } else {
            return <span className="order-modal">{order.comment}</span>
        }
    }

    const copyOrderToCart = () => {
        if (isEmpty(getCartItems())) {
            clearCart()
            addToCart(order.items, false)
            toast.success(t('done'))
            navigate('/order')
            return
        }
        setCopyOrder(true)
    }

    //TODO: сохранять отредактированный коммент с таблицей

    return [
        <Dialog onClose={() => setCopyOrder(false)} key='copy-to-cart-dialog' open={copyOrder}>
            <DialogTitle>{t('copy_to_cart_dialog_title')}</DialogTitle>
            <DialogContent>
                {t('copy_to_cart_dialog_text', {count: getCartItems().length})}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setCopyOrder(false)}>{t('cancel')}</Button>
                <div style={{flex: '1 0 0'}}/>
                <Button onClick={() => {
                    clearCart()
                    addToCart(order.items, false)
                    setCopyOrder(false)
                    toast.success(t('done'))
                }}>
                    {t('copy_to_cart_clear')}
                </Button>
                <Button onClick={() => {
                    addToCart(order.items, false)
                    setCopyOrder(false)
                    toast.success(t('done'))
                }}>
                    {t('copy_to_cart_add')}
                </Button>
            </DialogActions>
        </Dialog>,
        <Dialog key='main-dialog' onClose={() => {
            order.items = beforeEditing
            close()
        }} open={open} maxWidth='800px'>
            <DialogTitle>
                {t('order')} #{order.id} {t('from')} {order.created_at.split('T')[0]}
                <Button endIcon={<Edit/>} color='primary' onClick={updateEditable}
                        sx={{float: 'right'}}>
                    {editable ? t('save') : t('edit')}
                </Button>
                {editable ? wrapTooltip(t('save-before-download'),
                    <Button disabled={editable} endIcon={<Download/>} color='primary' onClick={() => {
                        setLoading(true)
                        downloadOrder(order).finally(() => setLoading(false))
                    }} sx={{float: 'right'}}>
                        {t('download')}
                    </Button>) : <Button disabled={editable} endIcon={<Download/>} color='primary' onClick={() => {
                    setLoading(true)
                    downloadOrder(order).finally(() => setLoading(false))
                }} sx={{float: 'right'}}>
                    {t('download')}
                </Button>}
                <Button onClick={copyOrderToCart} color='primary' sx={{float: 'right'}}
                        endIcon={<ShoppingCartCheckout/>}>
                    {t('copy_order_to_cart')}
                </Button>
            </DialogTitle>
            <DialogContent>
                <ItemsTable editable={editable} items={order.items}
                            onChange={(items, remove) => {
                                if (!remove) {
                                    return
                                }
                                items.forEach(it => {
                                    const index = order.items.findIndex(s => itemsEqual(it, s))
                                    if (index !== -1) {
                                        order.items.splice(index, 1)
                                    }
                                })
                            }}/>
                <Comment/>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {
                    order.items = beforeEditing
                    close()
                }}>
                    {t('close')}
                </Button>
            </DialogActions>
        </Dialog>
    ]
}
export default OrderModal