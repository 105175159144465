import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";

const LangSwitch = (props) => {
    const {i18n} = useTranslation()

    const [language, setLanguage] = useState(i18n.language)
    const [languageList, setLanguageList] = useState([])

    const [params, setParams] = useSearchParams()

    useEffect(() => {
        fetch('/locales/languages.json').then(it => it.json()).then(it => setLanguageList(it))
    }, [])

    useEffect(() => {
        const lang = params.get('lng')
        if (!lang) {
            return
        }
        changeLanguage(lang)
        params.delete('lng')
        setParams(params)
    }, [params.get('lng')])

    useEffect(() => {
        setLanguage(i18n.language)
    }, [i18n.language])

    const changeLanguage = (lang) => {
        i18n.changeLanguage(lang)
    }

    return <select name="langs" value={language}
                   onChange={e => {
                       changeLanguage(e.target.value)
                       props.handleChange()
                   }}>
        {Object.entries(languageList).map(([k, v]) => {
            return <option value={k} key={k}>{v}</option>
        })}
    </select>
}
export default LangSwitch