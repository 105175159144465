import {createContext, useContext, useEffect, useState} from "react";
import {get, PATH_EXCHANGE_RATE} from "../../../common/backend";
import {max, parseInt, round} from "lodash";
import {calcTotalSize, isPureMathExpression} from "../../../common/cart";

/**
 * @typedef {import('../../../common/cart.js').Item} Item
 */
const DeliveryDataContext = createContext({
    currency: 0,
    volumeFormula: '0',
    grossWeightFormula: '0'
});

const useDeliveryData = () => {
    const context = useContext(DeliveryDataContext)
    if (!context) throw new Error('useDeliveryData must be used inside DeliveryDataProvider')
    return context
}

const DeliveryDataProvider = ({children}) => {
    /** @type array|Object [categoryId]|{categoryId=>page} */
    const [current, setCurrent] = useState({
        currency: 0,
        volumeFormula: '0',
        grossWeightFormula: '0'
    })

    useEffect(() => {
        get(PATH_EXCHANGE_RATE, res => {
            const newCurrent = {}
            newCurrent.currency = parseInt(res.data.rate)
            newCurrent.volumeFormula = res.data.volume_formula
            newCurrent.grossWeightFormula = res.data.gross_formula
            setCurrent(newCurrent)
        });
    }, [])

    /**
     * Calculate delivery price
     * @param items {Item[]}
     * @return {number}
     */
    const calculateDelivery = items => {
        const total = calcTotalSize(items)
        if (total.gross_weight === 0 || total.volume === 0) {
            return total.gross_weight || total.volume
        }

        let price = [
            current.volumeFormula.replace('volume', total.volume.toString()).replace('currency', current.currency.toString()),
            current.grossWeightFormula.replace('gross', total.gross_weight.toString()).replace('currency', current.currency.toString()),
        ];
        price = price.map(it => {
            if (isPureMathExpression(it)) {
                return eval(it)
            }
            return -1
        })
        if (price.includes(-1)) {
            return -1
        }

        price = max(price)
        price = round(price, 2)
        price = Math.ceil(price / 10) * 10
        return price
    }
    return <DeliveryDataContext.Provider children={children} value={{...current, calculateDelivery}}/>
}
export default DeliveryDataProvider
export {useDeliveryData}
