import {clone, isEmpty, last, round, sortBy} from "lodash";
import {notes} from "../desktop/cart/note";
import Cookies from "js-cookie";

/**
 * @typedef Item
 * @property {string} catalog_number
 * @property {string} code
 * @property {string} name
 * @property {number} index_number
 * @property {number} buy_count
 * @property {number} box_count
 * @property {string} box_size
 * @property {number} box_net
 * @property {number} box_gross
 * @property {number} number_of_packages
 * @property {number} total_net_weight
 * @property {number} total_gross_weight
 * @property {number} total_volume
 * @property {{}} schema
 */

/**
 * @param item {Item}
 * @return void
 */
export const calculateItemSize = item => {
    item.buy_count = nearestCount(Math.max(1, item.buy_count), item.box_count || 1)

    item.number_of_packages = item.buy_count / (Math.max(+item.box_count, 1));
    item.total_net_weight = item.box_net > 1 ? round(item.number_of_packages * item.box_net, 3) : 0;
    item.total_gross_weight = item.box_gross > 1 ? round(item.number_of_packages * item.box_gross, 3) : 0;
    item.total_volume = item.box_size.split('x').every(it => it <= 1) ? 0 : round([...item.box_size.split('x'), item.number_of_packages].reduce((prev, cur) => prev * cur) / 1e+9, 3);
}
/**
 * Ближайшее кратное число, но не меньше carton
 * @param number {number}
 * @param carton {number}
 * @return number
 */
export const nearestCount = (number, carton) => {
    if (number <= carton || isNaN(number)) {
        return carton
    }
    return number - number % carton + carton * !!(number % carton)
}
/**
 * Очиста корзины в localStorage
 * @return void
 */
export const clearCart = () => {
    localStorage.setItem('cart', '[]')
    localStorage.setItem('cart-original', '[]')
}
/**
 * Один и тот же это товар
 * Проверяет код и х-ки
 * @param first {Item}
 * @param second {Item}
 * @return boolean
 */
export const itemsEqual = (first, second) => {
    if (first.code !== second.code) {
        return false;
    }
    const specsFist = Object.keys(first).filter(it => ['color', 'ceramic_color', 'pattern'].includes(it))
    const specsSecond = Object.keys(second).filter(it => ['color', 'ceramic_color', 'pattern'].includes(it))
    if (specsFist.length !== specsSecond.length) {
        return false
    }
    if (specsFist.length === 0) {
        return true
    }
    return specsFist.every(it => first[it].code === second[it].code)
}

/**
 * Название состоит из названия/цветов(паттернов)
 * Возможные комбинации
 * Цвет
 * ЦветКерамики+Узор
 * Цвет+ЦветКерамики+Узор
 * Функция находит товар который имеет эти характеристики
 * Если товар найден то ему будут присовены характеристики и будет возвращена его копия
 * @param oldItem {{art:string,color:string}}
 * @param item {Item}
 * @return Item|null
 */
export const matchItemFromOldOrder = (oldItem, item) => {
    oldItem.art = oldItem.art.toUpperCase()
    item.name = item.name.toUpperCase()
    //полное совпадения по имени. В названии некоторых товаров есть слеш
    if (item.name === oldItem.art && !isEmpty(oldItem.color) && item.schema === null) {
        return clone(item)
    }
    if (item.name !== oldItem.art) {
        return null
    }
    //если нет цветов и по имени не нашло || у товара вообще не может быть модификаций
    if (isEmpty(oldItem.color) || item.schema === null) {
        return null
    }
    const color = oldItem.color.toUpperCase().split('/')
    const schemaKeys = Object.keys(item.schema)
    const schemaValues = Object.values(item.schema)
    if (color.length !== schemaKeys.length) {
        return null;
    }
    const select = {}
    for (const [i, code] of color.entries()) {
        const valueId = schemaValues[i].findIndex(value => value.code.toUpperCase() === code.toUpperCase())
        if (valueId === -1) {
            return null
        }
        select[schemaKeys[i].toLowerCase().replace(' ', '_')] = schemaValues[i][valueId]
    }
    item = clone(item)
    Object.assign(item, select)
    return item
}

/**
 * @param oldItem {{art: string, color: string}}
 * @param list {Item[]}
 * @return Item|null
 */
export const findItemFromOldOrder = (oldItem, list) => {
    for (let item of list) {
        item = matchItemFromOldOrder(oldItem, item)
        if (item !== null) {
            return item
        }
    }
    return null
}
/**
 * @param items {Item[]}
 * @param removeRest {boolean} Удалять ли остальные товары с таким же каталожным номером
 * @return void
 */
export const addToCart = (items, removeRest = true) => {
    items.forEach(it => {
        it.buy_count = nearestCount(it.buy_count, Math.max(+it.box_count, 1))
        calculateItemSize(it)
    })
    let current = getCartItemsOriginal()
    if (removeRest) {
        current = current.filter(it => it.catalog_number !== items[0].catalog_number)
    }
    items.forEach(newItem => {
        const index = current.findIndex(it => itemsEqual(it, newItem))
        if (index === -1) {
            current.push(newItem)
        } else {
            current[index] = newItem
        }
    })

    localStorage.setItem('cart-original', JSON.stringify(current))
    if (Cookies.get('site-cart') === '1') {
        current = sortSiteOrder(current)
    }
    localStorage.setItem('cart', JSON.stringify(current))
    window.dispatchEvent(new Event('storage'))
}
/**
 * Удаление товаров с корзины
 * @param items {Item[]}
 * @return void
 */
export const removeItemsFromCart = (items) => {
    const remove = it => !items.some(f => itemsEqual(f, it))

    localStorage.setItem('cart', JSON.stringify(getCartItems().filter(it => remove(it))))
    localStorage.setItem('cart-original', JSON.stringify(getCartItemsOriginal().filter(it => remove(it))))
    window.dispatchEvent(new Event('storage'))
}
/**
 * @param items {Item[]}
 * @return Item[]
 */
export const sortSiteOrder = items => sortBy(items, 'index_number')
/**
 * Сортировка товаров в корзине
 * По порядку добавления или как на сайте
 * @return void
 */
export const sortCart = () => {
    let items = getCartItemsOriginal()
    if (Cookies.get('site-cart') === '1') {
        items = sortSiteOrder(items)
    }
    localStorage.setItem('cart', JSON.stringify(items))
    window.dispatchEvent(new Event('storage'))
}

/**
 * @param items {Item[]}
 * @return {{volume: number, gross_weight: number, net_weight: number}}
 */
export const calcTotalSize = items => {
    items.forEach(it => calculateItemSize(it))
    return {
        net_weight: round(items.reduce((prev, cur) => (isNaN(prev) ? prev.total_net_weight : prev) + cur.total_net_weight, 0), 2),
        gross_weight: round(items.reduce((prev, cur) => (isNaN(prev) ? prev.total_gross_weight : prev) + cur.total_gross_weight, 0), 2),
        volume: round(items.reduce((prev, cur) => (isNaN(prev) ? prev.total_volume : prev) + cur.total_volume, 0), 2)
    }
}

/**
 * Check if string is a math expression
 * @param {string} input pure math expression with variables replaced (x * 2) FALSE, (2*2) TRUE
 * @return boolean
 */
export const isPureMathExpression = (input) => {
    // Remove whitespace
    input = input.replaceAll(' ', '')

    // Regular expression to allow only certain characters
    const allowedCharsRegex = /^[0-9+\-*/().]+$/;

    if (!allowedCharsRegex.test(input)) {
        return false; // Contains invalid characters
    }

    // Balanced parentheses check
    function areParenthesesBalanced(str) {
        let count = 0;
        for (const char of str) {
            if (char === '(') {
                count++;
            } else if (char === ')') {
                count--;
            }
            if (count < 0) {
                return false; // Unbalanced parentheses
            }
        }
        return count === 0;
    }

    if (!areParenthesesBalanced(input)) {
        return false; // Unbalanced parentheses
    }
    // Syntax check using new Function()
    try {
        new Function('return ' + input);
    } catch (e) {
        return false;
    }

    return true;
}

/** @return Item[] */
export const getCartItems = () => JSON.parse(getCartItemsRaw())
/** @return string */
export const getCartItemsRaw = () => localStorage.getItem('cart') || '[]'
/** @return Item[] */
export const getCartItemsOriginal = () => JSON.parse(getCartItemsOriginalRaw())
/** @return string */
export const getCartItemsOriginalRaw = () => localStorage.getItem('cart-original') || '[]'
/**
 * @param items {Item[]}
 * @return {[string,{volume: string, ns: string, minWeight: string, maxWeight: string}]}
 */
export const deliveryNote = items => {
    items.forEach(it => calculateItemSize(it))
    //ton
    const weight = (items.map(it => it.total_gross_weight).reduce((prev, cur) => prev + cur, 0) / 1000) * 1000
    //cb m
    const volume = items.map(it => it.total_volume).reduce((prev, cur) => prev + cur, 0)

    const weightKey = Object.getOwnPropertyNames(notes).find(it => {
        const f = parseInt(it.substring(0, it.indexOf('-')))
        const s = parseInt(it.substring(it.indexOf('-') + 1, it.length))
        return weight >= f && weight <= s;
    })

    const volumeKey = Object.getOwnPropertyNames(notes[weightKey] || {}).find(it => {
        const f = parseInt(it.substring(0, it.indexOf('-')))
        const s = parseInt(it.substring(it.indexOf('-') + 1, it.length))
        return volume >= f && volume <= s;
    })
    const note = (notes[weightKey] || {})[volumeKey] || last(last(notes))

    // KG!
    // note.weight[] initial is tons, @weight in kg
    let minWeight = (note.weight[0] || 0) * 1000.0 - weight
    minWeight = round(minWeight, 2)

    let maxWeight = (note.weight[1] || 0) * 1000.0 - weight
    maxWeight = round(maxWeight, 2).toString()

    let addVolume = (note.volume) - volume
    addVolume = round(addVolume, 2).toString()

    const key = `${weightKey}-${volumeKey}`

    const dash = minWeight < 0 ? '' : '-'
    minWeight = minWeight.toString()
    const values = {
        minWeight: minWeight,
        dash: dash,
        maxWeight: maxWeight,
        volume: addVolume,
        ns: 'note'
    }
    return [key, values]
}
/**
 * @param item {Item}
 * @param translatedName {string}
 * @return string
 */
export const productGetFullName = (item, translatedName = item.name) => {
    const add = ['color', 'ceramic color', 'pattern'].filter(it => it in item).map(it => item[it].code)
    if (add.length === 0) {
        return translatedName
    }
    return translatedName + '/' + add.join('/')
}
