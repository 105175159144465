import {useEffect, useState} from "react";
import {attachmentsAddress, get, PATH_PRODUCT_SEARCH} from "../common/backend";
import {useLoader} from "../common/Loader";
import {useSearchParams} from "react-router-dom";
import {useNavigate} from "react-router";
import {useTranslation} from "react-i18next";

const SearchResult = () => {
    const query = useSearchParams()[0].get('q') || ''

    const {setLoading} = useLoader()
    const {t} = useTranslation()
    document.title = t('page_search', {query: query}) + ' | R!met.CN'

    const navigate = useNavigate()
    const [items, setItems] = useState(null)

    useEffect(() => {
        if (query === '') {
            setItems([])
            setLoading(false)
        } else {
            get(PATH_PRODUCT_SEARCH + query, res => {
                setItems(res.data.data)
                setLoading(false)
            })
        }
    }, [query])

    const getSearchElement = data => {
        let image = '/images/no-image.png'
        if (data.attachments.length !== 0) {
            const it = data.attachments[0]
            image = `${attachmentsAddress}${it.id}/catalog.jpg`
        }
        return <div key={data.code}>
            <div className="product-item" style={{cursor: "pointer"}}
                 onClick={e => {
                     e.preventDefault()
                     navigate(`/${encodeURIComponent(data.catalog_number)}`)
                 }}>
                <div className="product-item--image flex">
                    <a href={`/${encodeURIComponent(data.catalog_number)}`}>
                        <img src={image} alt=''/>
                    </a>
                </div>
                <div className="product-item--info">
                    <a href={`/${encodeURIComponent(data.catalog_number)}`}
                       className="product-item--name">{data.catalog_number}</a>
                </div>
            </div>
        </div>
    }

    return <article className="col-12 col-xl-9 content search-results">
        <div className="row">
            <div className="col-12">
                <div className="banner">
                    <h2 className="banner--title">
                        {t((items || []).length > 1 ? 'search_result_2' : 'search_result')}
                        <span id="search-query">{query}</span>
                    </h2>
                </div>
            </div>
            <div className="products-wrap">
                {(items || []).map(it => getSearchElement(it))}
            </div>
        </div>
    </article>
}
export default SearchResult