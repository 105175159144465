import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {apiRequest, get, PATH_CATEGORIES_ALL, PATH_CATEGORIES_SET_ORDER} from "../../../common/backend";
import {useLoader} from "../../../common/Loader";
import {toast} from "react-hot-toast";
import {Fab, FormGroup, InputLabel, Paper, Select} from "@mui/material";
import Box from "@mui/material/Box";
import {useNavigate} from "react-router";
import type {Item} from "./DraggableList";
import DraggableList from "./DraggableList";
import type {DropResult} from "react-beautiful-dnd/src";
import {reorder} from "../../../common/commons";
import {Save} from "@mui/icons-material";
import MenuItem from "@mui/material/MenuItem";
import {clone, flatten, isEmpty, sortBy} from "lodash";

const List = () => {
    const unsaved = useRef(false)

    const [items, setItems] = useState(/** @type Item[] */{})
    const [factory, setFactory] = useState('1')
    const [type, setType] = useState('main')

    const {setLoading} = useLoader()
    const navigate = useNavigate()

    useEffect(() => {
        if (isEmpty(items)) {
            console.log('load')
            setLoading(true)
            get(PATH_CATEGORIES_ALL, res => {
                let list = []
                const addCategory = data => list.push({
                    name: data.name,
                    factory: data.factory,
                    id: data.id,
                    order: data.order
                })
                Object.values(res.data).forEach(main => {
                    addCategory(main)
                    main.main = true;
                    main.children.forEach(child => {
                        child.name = main.name + '/' + child.name
                        child.main = false;
                        addCategory(child)
                    })
                })
                list = sortBy(list, 'order').reverse()
                list.forEach((it, i) => it.index = i)

                setItems({
                    '1': list.filter(it => it.factory === 1),
                    '2': list.filter(it => it.factory === 2),
                })
            }, () => toast.error('Error. Try again')).finally(() => setLoading(false))
        }
    }, [])
    console.log(items)
    useEffect(() => {
        window.onbeforeunload = () => {
            if (unsaved.current) {
                return 'Unsaved changes';
            }
        }
    }, [])

    const onDragEnd = ({destination, source}: DropResult) => {
        unsaved.current = true;
        if (!destination) return;
        items[factory] = reorder(items[factory], source.index, destination.index)
        setItems(clone(items));
    };

    const save = () => {
        items['1'].reverse().forEach((it, i) => it.order = i)
        items['2'].reverse().forEach((it, i) => it.order = i)
        const orderData = Object.fromEntries(flatten(Object.values(items)).map(it => [it.id, it.order]));
        apiRequest(PATH_CATEGORIES_SET_ORDER, () => {
            unsaved.current = false;
            toast.success('Saved')
        }, () => {
            toast.error('Error')
        }, {order: JSON.stringify(orderData)}).finally(() => setLoading(false))
    }

    return <>
        <Fab sx={{
            position: "fixed",
            bottom: (theme) => theme.spacing(2),
            right: (theme) => theme.spacing(2)
        }} onClick={save} color="primary">
            <Save/>
        </Fab>
        <Box sx={{width: '100%', marginTop: '20px', display: 'flex'}}>
            <Paper style={{maxWidth: '40%', textAlign: 'center', margin: 'auto'}}>
                <FormGroup sx={{display: 'inline', marginTop: '10px'}}>
                    <InputLabel id='factory-label'>Factory</InputLabel>
                    <Select variant='standard'
                            size='large'
                            labelId='factory-label'
                            value={factory}
                            onChange={e => setFactory(e.target.value)}
                            label='Factory'>
                        <MenuItem value={'1'}>1</MenuItem>
                        <MenuItem value={'2'}>2</MenuItem>
                    </Select>
                </FormGroup>
                {/*<FormGroup sx={{display: 'inline', marginTop: '10px'}}>
                    <InputLabel id='type-label'>Type</InputLabel>
                    <Select variant='standard'
                            size='large'
                            labelId='type-label'
                            value={type}
                            onChange={e => setType(e.target.value)}
                            label='Factory'>
                        <MenuItem value={'main'}>Main</MenuItem>
                        <MenuItem value={'sub'}>Sub</MenuItem>
                    </Select>
                </FormGroup>*/}
                <DraggableList
                    items={(items[factory] || [])/*.filter(it => (type === 'main' && it.main) || (type === 'sub' && !it.main))*/}
                    onDragEnd={onDragEnd}/>
                {/*<TableContainer>
                <Table stickyHeader={true} style={{margin: 'auto'}} size='small'>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Factory</TableCell>
                            <TableCell>Open</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {items.map(it => <TableRow key={it.id}>
                            <TableCell><Typography style={{width: 'fit-content'}} value={it.name}>{it.name}</Typography></TableCell>
                            <TableCell><TextField style={{width: '10px'}} variant='standard'
                                                  value={it.factory}/></TableCell>
                            <TableCell><IconButton onClick={() => navigate('/admin/category/' + it.id)} color='primary'><Send/></IconButton></TableCell>
                        </TableRow>)}
                    </TableBody>
                </Table>
            </TableContainer>*/}
            </Paper>
        </Box>
    </>
}
export default List
