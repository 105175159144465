import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {endsWith} from "lodash";

const SiteNav = () => {
    const {t} = useTranslation()
    const selected = text => endsWith(window.location.href, text) ? {textDecoration: 'underline'} : {}
    return <ul className="nav-bar">
        <li><Link style={selected('company')} to='/company'>{t('company')}</Link></li>
        <li><Link style={selected('downloads')} to="/downloads">{t('downloads')}</Link></li>
        <li><Link style={selected('contact')} to="/contact">{t('contacts')}</Link></li>
    </ul>
}
export default SiteNav
