import './App.css';
import {Navigate, Route, Routes} from 'react-router-dom'
import SpecsPage from "./admin/page/specs/SpecsPage";
import SchemasPage from "./admin/page/schema/SchemasPage";
import SchemaPage from "./admin/page/schema/SchemaPage";
import {default as DesktopBasePage} from "./desktop/common/BasePage";
import {default as AdminProducts} from "./admin/page/product/ProductsPage";
import {default as AdminProduct} from "./admin/page/product/ProductPage"
import {default as AdminBasePage} from "./admin/page/BasePage";
import {default as AdminDownloads} from "./admin/page/downloads/Downloads";
import Downloads from "./desktop/info/download/Downloads";
import Company from "./desktop/info/Company";
import ContactUs from "./desktop/info/ContactUs";
import SearchResult from "./desktop/SearchResult";
import Product from "./desktop/product/Product";
import SupplierPrice from "./admin/page/supplier/SupplierPrice";
import {default as DesktopIndex} from "./desktop/Index";
import Cart from "./desktop/cart/Cart";
import Category from "./desktop/category/Category";
import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3";
import PriceImportHistory from "./admin/page/price_import/PriceImportHistory";
import {default as AdminLogin} from "./admin/page/login/Login";
import AuthContextProvider from "./common/auth/AuthContext";
import {Loader} from "./common/Loader";
import Cabinet from "./desktop/cabinet/Cabinet";
import {Toaster} from "react-hot-toast";
import {default as AdminUsersPage} from "./admin/page/users/UsersPage";
import {default as AdminOrders} from "./admin/page/order/Orders";
import Orders from "./desktop/cabinet/order/Orders";
import MainPageStatic from "./admin/page/main/MainPageStatic";
import List from "./admin/page/category/List";
import CategoryEditor from "./admin/page/category/CategoryEditor";
import ThemeProvider from "./common/ThemeProvider";
import Info from './desktop/Info';
import InfoEdit from "./admin/page/info/InfoEdit";
import CookieConsent from "./common/CookieConsent";
import * as React from "react";
import DeliveryDataProvider from "./desktop/common/context/DeliveryDataContext";
import DeliveryFormula from "./admin/page/settings/DeliveryFormula";

function App() {
    return (
        <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY}>
            <ThemeProvider>
                <AuthContextProvider>
                    <Loader>
                        <Toaster/>
                        <DeliveryDataProvider>
                            <Routes>
                                <Route path="">
                                    <Route path="" element={<DesktopIndex/>}/>
                                    <Route path="1" element={<Navigate to='/c/1'/>}/>
                                    <Route path="2" element={<Navigate to='/c/2'/>}/>
                                    <Route path="downloads" element={<DesktopBasePage page={<Downloads/>}
                                                                                      overridePageName='Downloads'/>}/>
                                    <Route path="order" element={<DesktopBasePage page={<Cart/>}/>}/>
                                    <Route path="orders" element={<DesktopBasePage page={<Orders/>}/>}/>
                                    <Route path="contact" element={<DesktopBasePage page={<ContactUs/>}
                                                                                    overridePageName='ContactUs'/>}/>
                                    <Route path="company"
                                           element={<DesktopBasePage page={<Company/>} overridePageName='Company'/>}/>
                                    <Route path="info" element={<Info/>}/>
                                    <Route path="s" element={<DesktopBasePage page={<SearchResult/>}/>}/>
                                    <Route path="c/:cat_link" element={<DesktopBasePage page={<Category/>}/>}/>
                                    <Route path="c" element={<DesktopBasePage page={<Category/>}/>}/>
                                    <Route path="cabinet" element={<DesktopBasePage page={<Cabinet/>}/>}/>
                                    <Route path="/:sku" element={<DesktopBasePage page={<Product/>}/>}/>
                                </Route>
                                <Route path="admin">
                                    <Route path="" element={<AdminBasePage page={''}/>}/>
                                    <Route path="login" element={<AdminLogin/>}/>
                                    <Route path="main-page"
                                           element={<AdminBasePage page={<MainPageStatic key='main-page-static'/>}/>}/>
                                    <Route path="orders" element={<AdminBasePage page={<AdminOrders key='orders'/>}/>}/>
                                    <Route path="users"
                                           element={<AdminBasePage page={<AdminUsersPage key='users'/>}/>}/>
                                    <Route path="info" element={<AdminBasePage page={<InfoEdit key='info'/>}/>}/>
                                    <Route path="downloads"
                                           element={<AdminBasePage page={<AdminDownloads key='downloads'/>}/>}/>
                                    <Route path="specs" element={<AdminBasePage page={<SpecsPage key='specs'/>}/>}/>
                                    <Route path="material-template/:id"
                                           element={<AdminBasePage page={<SchemaPage key='schema'/>}/>}/>
                                    <Route path="material-template"
                                           element={<AdminBasePage page={<SchemasPage key='schemas'/>}/>}/>
                                    <Route path="products/:sku"
                                           element={<AdminBasePage page={<AdminProduct key='product'/>}/>}/>
                                    <Route path="products"
                                           element={<AdminBasePage page={<AdminProducts key='products'/>}/>}/>
                                    <Route path="supplier-price"
                                           element={<AdminBasePage page={<SupplierPrice key='supplier'/>}/>}/>
                                    <Route path="supplier-price-import-history"
                                           element={<AdminBasePage page={<PriceImportHistory key='supplier'/>}/>}/>
                                    <Route path="categories"
                                           element={<AdminBasePage page={<List key='categories'/>}/>}/>
                                    <Route path="category/:id"
                                           element={<AdminBasePage page={<CategoryEditor key='categories'/>}/>}/>
                                    <Route path="setting"
                                           element={<AdminBasePage page={<DeliveryFormula key='categories'/>}/>}/>
                                </Route>
                            </Routes>
                        </DeliveryDataProvider>
                        <CookieConsent/>
                    </Loader>
                </AuthContextProvider>
            </ThemeProvider>
        </GoogleReCaptchaProvider>
    );
}

export default App;
