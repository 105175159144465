import {
    addToCart,
    calcTotalSize,
    clearCart,
    deliveryNote,
    getCartItems,
    removeItemsFromCart,
    sortCart
} from "../../common/cart";
import {useEffect, useRef, useState} from "react";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import {toast} from "react-hot-toast";
import {getErrorMessage, PATH_ORDER_ADD, post} from "../../common/backend";
import {useLoader} from "../../common/Loader";
import ItemsTable from "../items_table/ItemsTable";
import {useAuth} from "../../common/auth/AuthContext";
import {clone} from "lodash";
import Cookies from "js-cookie";
import {Switch} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router";
import i18next from "i18next";
import {setCabinetHandleFilters} from "../../common/commons";
import PhoneInput from "react-phone-input-2";
import phone from "phone";
import {useDeliveryData} from "../common/context/DeliveryDataContext";

const Cart = () => {
    const {t} = useTranslation()
    document.title = t('page_cart') + ' | R!met.CN'

    const [cartItems, setCartItems] = useState(getCartItems())
    const {setLoading} = useLoader()
    const {authorized, user} = useAuth()
    const {calculateDelivery, currency} = useDeliveryData()
    const navigate = useNavigate()
    const getCaptchaToken = useGoogleReCaptcha().executeRecaptcha
    const submitRef = useRef()
    const data = useRef({
        name: '',
        phone: '',
        email: '',
        company: '',
        comment: ''
    })
    const setData = e => data.current[e.target.name] = e.target.value

    const siteOrder = Cookies.get('site-cart') === '1'
    const [loggedIn, setLoggedIn] = useState(false)
    useEffect(() => {
        if (authorized === true) {
            data.current = clone(user)
            data.current.name = user.full_name
            setLoggedIn(true)
        }
    }, [authorized, user])
    useEffect(() => {
        window.addEventListener('storage', () => setCartItems(getCartItems()))
    }, [])

    const toggleSiteOrder = () => {
        Cookies.set('site-cart', siteOrder ? '0' : '1')
        sortCart()
    }

    const submitOrder = e => {
        e.preventDefault()
        if (cartItems.length === 0) {
            toast.error(t('cart_empty'))
            return
        }
        if (user && user.email_verified_at === null) {
            toast.error(t('verify_email_first'))
            return;
        }
        setLoading(true)
        getCaptchaToken('order').then(token => {
            const delivery = [calculateDelivery(cartItems.filter(it => it.factory === 1)), calculateDelivery(cartItems.filter(it => it.factory === 2))]
            if (delivery.some(it => it === Infinity)) {
                toast.error(t('delivery_price_calculation_error'))
                return;
            }
            let note = deliveryNote(cartItems)
            calcTotalSize(cartItems)
            delete note[1]['ns']
            post(PATH_ORDER_ADD, () => {
                clearCart()
                setCabinetHandleFilters()
                navigate('/?order')
            }, err => {
                toast.error(getErrorMessage(err))
            }, {
                items: cartItems,
                captcha: token,
                ...data.current,
                deliveryNote: note,
                deliveryPrice: delivery,
                lang: i18next.language
            }).finally(() => setLoading(false))
        }).catch(() => {
            toast.error(t('captcha_error'))
            setLoading(false)
        })
    }

    return <article className="col-12 col-xl-9 content cart">
        <h2 className="order--title">
            {t('cart')}
            <span>{t('site_order')}
                <Switch onChange={toggleSiteOrder} checked={siteOrder}/>
            </span>
        </h2>
        <ItemsTable items={cartItems} editable={true} showAll={false}
                    onChange={(items, remove) => remove ? removeItemsFromCart(items) : addToCart(items, false)}/>
        <div className="col-12 order--form-box">
            <div className="row">
                <div className="col-12">
                    <h2 className="order-title">{t('complete_your_order')}</h2>
                </div>
                <form onSubmit={submitOrder}>
                    <div className="col-12 mb-md-2">
                        <div className="row">
                            <div className="col-12 col-md-8">
                                <div className="row flex flex-wrap">
                                    <div className="col-12 col-sm-6">
                                        <label className="order-input--title">{t('your_name')}: *</label>
                                        <input readOnly={loggedIn} defaultValue={data.current['name']}
                                               onChange={setData}
                                               required minLength='3'
                                               name='name'
                                               className="order-input" type="text"/>
                                    </div>
                                    <div className="col-12 col-sm-6">
                                        <label className="order-input--title">{t('contact_phone')}: *</label>
                                        <PhoneInput
                                            isValid={val => phone('+' + val).isValid}
                                            onChange={val => data.current.phone = val}
                                            disabled={loggedIn}
                                            inputProps={{
                                                name: 'phone',
                                                id: 'phone',
                                                required: true,
                                                autoFocus: false,
                                                minLength: 5,
                                                readOnly: loggedIn
                                            }}
                                            value={data.current['phone']}
                                            country=''
                                            placeholder='+'
                                            containerClass='order-input'
                                        />
                                    </div>
                                </div>
                                <div className="row flex flex-wrap">
                                    <div className="col-12 col-sm-6">
                                        <label className="order-input--title">{t('email_address')}: *</label>
                                        <input readOnly={loggedIn} defaultValue={data.current['email']} name='email'
                                               onChange={setData} required className="order-input"
                                               type="email"/>
                                    </div>
                                    <div className="col-12 col-sm-6">
                                        <label className="order-input--title">{t('company_name')}: *</label>
                                        <input readOnly={loggedIn} defaultValue={data.current['company']} name='company'
                                               onChange={setData} className="order-input" type="text"/>
                                    </div>
                                </div>
                            </div>
                            <input ref={submitRef} hidden type='submit'/>
                            <div className="col-12 col-md-4">
                                <label
                                    className="order-input--title order-input_textarea--title">{t('comments')}:</label>
                                <textarea name='comment' onChange={setData}
                                          className="order-input order-input_textarea"/>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="row">
                            <div className="col-12 col-md-4">
                                <div className="order-btn" onClick={() => submitRef.current.click()}>
                                    <p>{t('complete_the_order')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </article>
}
export default Cart
