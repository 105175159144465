import {Dialog} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";

const ConfirmationDialog = ({title, text, close, accept, isOpen, denyText = 'No', acceptText = 'Yes'}) => {
    return <Dialog open={isOpen}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
            {text}
        </DialogContent>
        <DialogActions>
            <Button onClick={close}>{denyText}</Button>
            <Button onClick={accept}>{acceptText}</Button>
        </DialogActions>
    </Dialog>
}
export default ConfirmationDialog