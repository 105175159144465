import {max} from "lodash";
import Carousel from "nuka-carousel";
import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {getCabinetHandleFilters, getItemImage} from "../../common/commons";
import {get, PATH_PRODUCT_BY_CATEGORY_ID} from "../../common/backend";
import $ from "jquery";
import {useNavigate} from "react-router";
import {useTranslation} from "react-i18next";

const CategoryCarousel = ({categories, catalogNumber, handleLink}) => {
    let category = ""
    if (categories) category = max(Object.keys(categories))
    //category = max(categories) // <= old, changed categories inputs/backed responds ?

    //Для сравнения координат нажатия и поднятия кнопки мыши
    //В карусели собютие ondragstart вызывается сразу при клике, как и ondragend, если кликнуть 1 раз и не перемещать карусель
    const mouseDown = useRef('')
    const mouseUp = useRef('')

    const [index, setIndex] = useState(0)
    const [items, setItems] = useState([])
    const navigate = useNavigate()
    const {t} = useTranslation()

    const carouselRef = useRef(null)

    //для установки фокуса на карусели и активации управления кнопками
    const [carouseVisible, setCarouseVisible] = useState(false)

    const observer = new IntersectionObserver(([entry]) => setCarouseVisible(entry.isIntersecting))

    const slidesCount = () => {
        const width = parseInt(window.innerWidth);
        if (width >= 1200) {
            return 5
        }
        if (width <= 576 && width > 399) {
            return 3
        }
        if (width <= 400) {
            return 2
        }
        return 4
    }

    //ON USER NAVIGATION

    useEffect(() => {
        observer.observe(carouselRef.current)
        return () => {
            observer.disconnect()
        }
    }, [])

    useEffect(() => {
        if (carouseVisible) {
            // carouselRef.current.focus()
        } else {
            // carouselRef.current.blur()
        }
    }, [carouseVisible])

    useEffect(() => {
        if (category === undefined) {
            return;
        }
        let params = {
            fields: ['catalog_number', 'attachments'],
            page: 1,
            // limit: 70,
            require_filters: 1,
            // disable_filters: 1
        }
        params = $.extend(params, getCabinetHandleFilters())
        get(PATH_PRODUCT_BY_CATEGORY_ID + category + '?' + $.param(params), res => {
            setItems(res.data.data)
            setIndex(Math.max(res.data.data.findIndex(it => it.catalog_number === catalogNumber) - 2, 0))
        })
    }, [category])

    const scrollUp = () => {
        setIndex(Math.min(index + 1, items.length - 4))
    }
    const scrollDown = () => {
        setIndex(Math.max(index - 1, 0))
    }

    if (carouselRef.current) {
        carouselRef.current.onwheel = e => {
            e.preventDefault()
            if (e.deltaY > 0) {
                scrollUp()
            } else {
                scrollDown()
            }
        }
    }

    const onMouseDown = e => mouseDown.current = `${e.clientX}:${e.clientY}`
    const onMouseUp = e => mouseUp.current = `${e.clientX}:${e.clientY}`

    const selectProduct = cn => {
        if (mouseUp.current === mouseDown.current) {
            navigate('/' + cn)
        }
    }

    return <div key='category-items' className="category--list">
        <div className="category--list-items">
            <h2>{t('category_more')}</h2>
            <Carousel enableKeyboardControls={true}
                      dragging={true}
                      keyCodeConfig={{nextSlide: [39, 68, 38, 87], previousSlide: [37, 65, 40, 83]}}
                      innerRef={carouselRef}
                      adaptiveHeight={false}
                      slideIndex={index}
                      disableAnimation={true}
                      cellSpacing={5}
                      renderBottomCenterControls={false}
                      wrapAround={false}
                      slidesToShow={slidesCount()}>
                {items.map(it =>
                    <div onMouseDown={onMouseDown} onMouseUp={onMouseUp}
                         onClick={() => {
                             selectProduct(it.catalog_number)
                             handleLink()
                         }}
                         className="card card_category"
                         key={it.catalog_number}>
                        <img style={{maxHeight: '100%'}} draggable='false' src={getItemImage(it)} loading='lazy'
                             alt={it.catalog_number}/>
                        <span className='card_category--title'>{it.catalog_number}</span>
                    </div>
                )}
            </Carousel>
        </div>
    </div>
}
export default CategoryCarousel
