import {Modal} from "@mui/material";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'
import {useRef, useState} from "react";
import {toast} from "react-hot-toast";
import phone from "phone";
import {useAuth} from "../../../common/auth/AuthContext";
import {useLoader} from "../../../common/Loader";
import {useTranslation} from "react-i18next";
import {capitalize} from "lodash";
import {getErrorMessage} from "../../../common/backend";
import {useNavigate} from "react-router";

const mandatoryField = <span style={{color: 'red', marginLeft: '3px'}}>*</span>

const toastOptions = {duration: 3000, position: 'top-right'}

const RegisterForm = ({open, close}) => {

    const state = useRef({phone: ''})
    const submitBtn = useRef()

    const [errors, setErrors] = useState({})

    const {register} = useAuth()
    const {t} = useTranslation()
    const {setLoading} = useLoader()

    const nav = useNavigate()

    const onChange = e => {
        let name = 'phone'
        //PhoneInput сразу возвращает значение
        if (e instanceof Object) {
            name = e.target.name
            e = e.target.value
        }
        state.current[name] = e
    }

    const tryRegister = e => {
        e.preventDefault()
        const data = state.current
        if (!data.phone.includes('+')) {
            data.phone = `+${data.phone}`
        }
        if (!phone(data.phone).isValid) {
            toast.error(t('check_phone_number'), toastOptions)
            return
        }
        if (data.password !== data['password-repeat']) {
            toast.error(t('repeat_password'), toastOptions)
            return
        }
        setLoading(true)
        register(data).then(() => {
            close()
            nav('/cabinet')
            toast.success(t('register_email_sent'), {position: 'top-right', duration: 5500})
        }).catch(err => {
            if ('response' in err && 'data' in err.response) {
                toast.error(err.response.data.message, toastOptions)
                let errors = err.response.data.errors // {str: arr}
                const newErrors = {};
                for (let key in errors) {
                    if (Array.isArray(errors[key])) {
                        newErrors[key] = errors[key][0];
                    } else {
                        newErrors[key] = errors[key];
                    }
                }
                setErrors(newErrors)
            } else {
                toast.error(getErrorMessage(err), toastOptions)
            }
        }).finally(() => setLoading(false))
    }

    const getErrorText = field => (errors[field]) ? <p style={{color: 'red'}}>{errors[field]}</p> : null
    const getErrorStyle = field => (errors[field]) ? {border: '1px solid red'} : {}

    return <Modal open={open} onClose={close}>
        <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="Register">{t('register')}</h5>
                    <button onClick={close} type="button" className="close" data-dismiss="modal"
                            aria-label={t('close')}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <form onSubmit={tryRegister}>
                    <div className="modal-body register-container--inner">
                        <label htmlFor="reg-name">
                            <span>{t('full_name')}{mandatoryField}</span>
                            <input style={getErrorStyle('full_name')} onChange={onChange} type="text" name="full_name"
                                   id="reg-name" required
                                   maxLength='255'
                                   minLength='3'/>
                            {getErrorText('full_name')}
                        </label>
                        <label htmlFor="reg-mail">
                            <span>Email{mandatoryField}</span>
                            <input style={getErrorStyle('email')} onChange={onChange} type="email" name="email"
                                   id="reg-mail" required/>
                            {getErrorText('email')}
                        </label>
                        <label htmlFor='phone'>
                            <span>{capitalize(t('phone'))}{mandatoryField}</span>
                            <PhoneInput
                                isValid={val => phone('+' + val).isValid}
                                onChange={onChange}
                                inputProps={{
                                    name: 'phone',
                                    id: 'phone',
                                    required: true,
                                    autoFocus: false,
                                    minLength: 5
                                }}
                                country=''
                                placeholder='+'
                            />
                            {getErrorText('phone')}
                        </label>
                        <label htmlFor='password'>
                            <span>{capitalize(t('password'))}{mandatoryField}</span>
                            <input style={getErrorStyle('password')} required minLength='8' maxLength='255'
                                   onChange={onChange}
                                   type='password'
                                   name='password' id='password'/>
                            {getErrorText('password')}
                        </label>
                        <label htmlFor='password-repeat'>
                            <span>{t('repeat_password')}{mandatoryField}</span>
                            <input style={getErrorStyle('password-repeat')} required minLength='8' maxLength='255'
                                   onChange={onChange}
                                   type='password'
                                   name='password-repeat' id='password-repeat'/>
                            {getErrorText('password-repeat')}
                        </label>
                        <label htmlFor='country'>
                            <span>{capitalize(t('country'))}{mandatoryField}</span>
                            <input style={getErrorStyle('country')} required minLength='2' maxLength='255'
                                   onChange={onChange}
                                   type='text' name='country'
                                   id='country'/>
                            {getErrorText('country')}
                        </label>
                        <label htmlFor='city'>
                            <span>{capitalize(t('city'))}{mandatoryField}</span>
                            <input style={getErrorStyle('city')} required minLength='2' maxLength='255'
                                   onChange={onChange}
                                   type='text' name='city'
                                   id='city'/>
                            {getErrorText('city')}
                        </label>
                        <label htmlFor='company'>
                            <span>{capitalize(t('company'))}{mandatoryField}</span>
                            <input style={getErrorStyle('company')} required minLength='2' maxLength='255'
                                   onChange={onChange}
                                   type='text' name='company'
                                   id='company'/>
                            {getErrorText('company')}
                        </label>
                        <input hidden type='submit' ref={submitBtn}/>
                        {/*<label htmlFor="reg-offert">Agreed to <a href="src/desktop/common/auth/RegisterForm#" target="_blank">public offert</a>&nbsp;
                        <input className="cbx-hidden" type="checkbox" name="" id="reg-offert" required/>
                        <span className="cbx-pseudo"/>
                    </label>*/}
                        {/*<img src="/images/newCaptchaAnchor.gif"/>*/}
                    </div>
                </form>
                <div className="modal-footer">
                    <button type="button" className="button button-gray" data-dismiss="modal">{t('close')}</button>
                    <button type="button" className="button" onClick={() => submitBtn.current.click()}>
                        {t('register_me')}
                    </button>
                </div>
            </div>
        </div>
    </Modal>
}
export default RegisterForm
