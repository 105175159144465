import * as React from "react";
import {forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";
import {Checkbox, TableCell, TableRow, TextField} from "@mui/material";
import {getItemImage, numbersDifferenceFloat} from "../../../../common/commons";
import {capitalize, chain, clone, isEmpty, round} from "lodash";

const fields = ['size', 'net', 'gross', 'count']
/**
 * @name GetItem
 * @function
 * @return {{selected:boolean, skip: boolean}}
 */
const PackageItemRow = forwardRef((props, ref) => {
    /** @type GetItem */
    const item = props.item

    const rowRef = useRef()
    const [selected, setSelectedState] = useState(/** @type boolean */item().selected)
    const [skip, setSkipState] = useState(/** @type boolean */item().skip)
    //Вес и объем 1 товара
    const [one, setOne] = useState([0, 0]);

    const [invalid, setInvalid] = useState(chain(fields)
        .keyBy('name')
        .mapValues(() => false)
        .value())

    useImperativeHandle(ref, () => ({
        select: setSelected,
        selected: selected || item().selected,
        object: item(),
        valid: Object.values(invalid).every(it => !it),
        scroll: () => rowRef.current.scrollIntoView()
    }))

    useEffect(() => {
        //1 раз при открытии окна определить некорректные переменные
        fields.forEach(it => invalid[it] = !isValid(it, item()['new' + capitalize(it)]))
        setInvalid(clone(invalid))
        updateOne()
    }, [])

    useEffect(() => {
        item().oneWeight = round((item().gross / item().count) * 1000, 3) || -1
        item().oneVolume = round((item().size.split('x').map(it => parseFloat(it) / 1000).reduce((a, b) => a * b) / item().count), 5) || -1
        if (item().oneVolume !== -1) {
            item().oneVolume *= 1000000
        }
    }, [item])

    useEffect(() => {
        item().valid = Object.values(invalid).every(it => !it)
    }, [invalid])

    //Расчет веса и размера 1 товара
    const updateOne = () => {
        let volume = round((item().newSize.split('x').map(it => parseFloat(it) / 1000).reduce((a, b) => a * b) / item().newCount), 5) || -1
        if (volume !== -1) {
            volume *= 1000000
        }
        setOne([
            round((item().newGross / item().newCount) * 1000, 3) || -1,
            volume
        ])
    }

    const handleChange = (name, value) => {
        item()['new' + capitalize(name)] = value

        //Обводить красным некорректные значения
        const valid = !isValid(name, value)
        if (valid !== invalid[name]) {
            invalid[name] = valid
            setInvalid(clone(invalid))
        }
        updateOne()
    }

    const isValid = (name, value) => {
        if (name === 'size') {
            return value.split('x').length === 3 && !value.includes('xx') && !value.includes('xxx')
        }
        return value > 0
    }
    const setSelected = (val) => {
        console.log("selected " + val)
        setSelectedState(val)
        item().selected = val
        if (val) {
            setSkip(false)
        }
    }
    const setSkip = (val) => {
        console.log("skip " + val)
        setSkipState(val)
        item().skip = val
        if (val) {
            setSelected(false)
        }
    }

    return <TableRow ref={rowRef}>
        <TableCell>
            <Checkbox onChange={e => {
                setSkip(e.target.checked)
            }} checked={skip}/>
        </TableCell>
        <TableCell>
            <Checkbox onChange={e => {
                setSelected(e.target.checked)
            }} checked={selected}/>
        </TableCell>
        <TableCell>
            <a target='_blank' href={`/${encodeURIComponent(item().catalog_number)}`}>
                <img style={{maxHeight: '54px'}} src={getItemImage(item())} alt='img'/>
            </a>
        </TableCell>
        <TableCell>
            {item().name} => <span style={{fontWeight: 'bold'}}>{item().originalName}</span>
        </TableCell>
        <TableCell>
            {item().order} => <span style={{fontWeight: 'bold'}}>{item().newOrder}</span>
        </TableCell>
        {fields.map(it => {
            const style = {}
            if ((it === 'net' || it === 'gross') && numbersDifferenceFloat(item().newGross, item().newNet).toNumber() > 20) {
                style.backgroundColor = '#ff000061'
            }
            return <TableCell key={it} style={style}>
                {item()[it]} => <TextField error={!isEmpty(style)}
                                           variant='standard'
                                           onChange={e => handleChange(it, e.target.value)}
                                           key={it}
                                           type={it === 'size' ? 'text' : 'number'} size='small'
                                           defaultValue={item()['new' + capitalize(it)]}/>
            </TableCell>
        })}
        <TableCell sx={{
            color: numbersDifferenceFloat(item().oneWeight, one[0]).toNumber() >= 10 && numbersDifferenceFloat(item().oneWeight, one[0]).toNumber() < 50 ? 'red' : 'inherit',
            backgroundColor: numbersDifferenceFloat(item().oneWeight, one[0]).toNumber() >= 50 ? 'rgba(234,69,69,0.5)' : 'none'
        }}>
            {item().oneWeight}=>{one[0]}
        </TableCell>
        <TableCell sx={{color: numbersDifferenceFloat(item().oneVolume, one[1]).toNumber() >= 10 ? 'red' : 'inherit'}}>
            {item().oneVolume}=>{one[1]}
        </TableCell>
    </TableRow>
})
export default PackageItemRow
