import {Box, Fab, Grid, IconButton, TextField} from "@mui/material";
import Typography from "@mui/material/Typography";
import {useLoader} from "../../../common/Loader";
import {useEffect, useState} from "react";
import {get, getErrorMessage, PATH_MAIN_PAGE_ITEMS, PATH_SET_MAIN_PAGE_ITEMS, post} from "../../../common/backend";
import {toast} from "react-hot-toast";
import {Add, ArrowDownward, ArrowUpward, Remove, Save} from "@mui/icons-material";
import {clone, max} from "lodash";
import {getAdminPageTitle, swapArrayLocs} from "../../../common/commons";

const MainPageStatic = () => {
    document.title = getAdminPageTitle('Main Page')
    const {setLoading} = useLoader()

    const [items, setItems] = useState([])

    useEffect(() => {
        get(PATH_MAIN_PAGE_ITEMS, res => {
            res.data.forEach((it, i) => it.id = i)
            setItems(res.data)
        }, () => toast.error('Try again')).finally(() => setLoading(false))
    }, [])

    const save = () => {
        setLoading(true)
        post(PATH_SET_MAIN_PAGE_ITEMS,
            () => toast.success('Done'),
            res => toast.error(getErrorMessage(res)),
            {items}
        ).finally(() => setLoading(false))
    }

    const addItem = () => {
        const newItems = clone(items)
        const newId = max(items.map(it => it.id))
        newItems.push({cn: '', text: '', img: '', id: newId})
        setItems(newItems)
    }

    const removeItem = i => {
        const newItems = clone(items)
        newItems.splice(i, 1);
        setItems(newItems)
    }

    const swapUp = (item) => {
        const arr = clone(items);
        swapArrayLocs(arr, arr.findIndex(it => it.id === item.id), arr.findIndex(it => it.id === item.id) - 1)
        setItems(arr)
    }
    const swapDown = (item) => {
        const arr = clone(items);
        swapArrayLocs(arr, arr.findIndex(it => it.id === item.id), arr.findIndex(it => it.id === item.id) + 1);
        setItems(arr);
    }

    return <Box key='main-container' display='flex' m='auto'>
        <Grid spacing={5} alignItems="center" direction="column" container>
            <Grid item>
                <Typography variant='h4'>Main page slider items</Typography>
            </Grid>
            {items.map((it, i) => <Grid key={it.id} item>
                <IconButton disabled={i === 0} onClick={() => swapUp(it)}><ArrowUpward/></IconButton>
                <IconButton disabled={i + 1 === items.length} onClick={() => swapDown(it)}><ArrowDownward/></IconButton>
                <TextField onChange={e => {
                    it.cn = e.target.value
                    items[items.findIndex(item => it.id === item.id)] = it
                }} defaultValue={it.cn} placeholder='Catalog Number'/>
                <TextField style={{marginLeft: '6px', marginRight: '6px'}} onChange={e => {
                    it.text = e.target.value
                    items[items.findIndex(item => it.id === item.id)] = it
                }}
                           defaultValue={it.text} placeholder='Text'/>
                <TextField onChange={e => {
                    it.img = e.target.value
                    items[items.findIndex(item => it.id === item.id)] = it
                }} defaultValue={it.img}
                           placeholder='Image name (with extension)'/>
                <IconButton onClick={() => removeItem(i)} style={{marginTop: '6px'}}><Remove/></IconButton>
            </Grid>)}
            <Grid item>
                <IconButton onClick={addItem}><Add/></IconButton>
            </Grid>
        </Grid>
        <Fab sx={{
            position: "fixed",
            bottom: (theme) => theme.spacing(2),
            right: (theme) => theme.spacing(2)
        }} onClick={save} color="primary">
            <Save/>
        </Fab>
    </Box>
}
export default MainPageStatic
