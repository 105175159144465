import * as React from "react";
import {useRef, useState} from "react";
import {useLoader} from "../../../../common/Loader";
import {Refresh} from "@mui/icons-material";
import Button from "@mui/material/Button";
import {toast} from "react-hot-toast";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import {get, PATH_GET_PACKAGE_INFO, PATH_UPDATE_PACKAGE_INFO, post} from "../../../../common/backend";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import {flatten, orderBy, sortedUniqBy, trim} from "lodash";
import TablePagination from "@mui/material/TablePagination";
import PackageItemRow from "./PackageItemRow";

/**
 * @typedef Item
 * @property {boolean} selected
 * @property {boolean} skip
 * @property {string} originalName
 */

const UpdatePackage = ({items, refreshItems}) => {
    const {setLoading} = useLoader()

    const [open, setOpen] = useState(false)

    const [page, setPage] = useState(0)
    const [perPage, setPerPage] = useState(25)

    const [rows, setRows] = useState(/** @type Item[] */[])
    const refs = useRef({})
    //Для скролла на самый верх, иначе верхнего элемента не будет видно
    const dialogRef = useRef()

    const matchItems = (name) => items.filter(it => it.name === name || it.name === `${name}E` || it.name.startsWith(`${name} `))

    const openWindow = () => {
        setLoading(true)
        setOpen(true)
        get(PATH_GET_PACKAGE_INFO + '?s=1', res => {
            refs.current = {}
            let newData = orderBy(res.data, it => it.art + it.print_date, 'desc')

            newData = sortedUniqBy(newData, it => it.art)
                .map(it => {
                    it.lwh = (it.lwh || '').replaceAll(',', '.').replaceAll(/[^\d.]/g, 'x')
                    it.lwh = trim(it.lwh, 'x')
                    if (it.lwh.split('x').map(it => parseFloat(it)).reduce((a, b) => a * b) / 1e+9 <= 0.01) {
                        it.lwh = it.lwh.split('x').map(it => parseFloat(it) * 10).join('x');
                    }
                    it.gw = (it.gw || 0)
                    it.nw = (it.nw || 0)
                    it.qty = (it.qty || 0)
                    const match = matchItems(it.art).filter(original => (it.name === 'CABINET HANDLE / РУЧКА МЕБЕЛЬНАЯ' || it.name === 'CABINET HOOK / КРЮЧОК МЕБЕЛЬНЫЙ')
                        && it.gw && it.nw && it.qty && it.lwh !== ''
                        && it.order_number !== original.order_number
                        && (it.lwh !== original.box_size ||
                            it.qty !== original.box_count ||
                            it.gw !== original.box_gross ||
                            it.nw !== original.box_net
                        ))
                    if (match.length === 0) {
                        return [];
                    }
                    return match.map(original => {
                        const newObj = {}
                        newObj.name = it.art
                        newObj.code = original.code
                        newObj.originalName = original.name
                        newObj.attachments = original.attachments;
                        newObj.catalog_number = original.catalog_number
                        newObj.size = original.box_size
                        newObj.count = original.box_count
                        newObj.net = original.box_net
                        newObj.gross = original.box_gross
                        newObj.order = original.order_number
                        newObj.oneWeight = original.one_weight
                        newObj.oneVolume = original.one_volume
                        newObj.newOrder = it.order_number
                        newObj.newNet = it.nw || 0
                        newObj.newGross = it.gw || 0
                        newObj.newSize = it.lwh
                        newObj.newCount = it.qty || 0
                        newObj.selected = false
                        newObj.skip = false
                        return newObj
                    })
                })
            newData = flatten(newData)
            setRows(newData)
        }, () => {
            setOpen(false)
            toast.error('Cant load information')
        }).finally(() => setLoading(false))
    }

    const getRows = () => rows.slice(page * perPage, page * perPage + perPage)

    const getRefs = () => Object.values(refs.current).filter(it => !!it)

    const closeWindow = () => {
        setRows([])
        setOpen(false)
        setPage(0)
        setPerPage(25)
        refs.current = {}
    }

    const save = () => {
        const data = rows.filter(it => it.selected || it.skip)
        if (data.length === 0) {
            toast.error('Nothing selected')
            return;
        }
        const incorrect = data.find(it => !it.valid)
        if (incorrect) {
            toast.error('Invalid data at ' + incorrect.originalName)
            //Нужно скроллить на предыдущий элемент, иначе строку не будет видно под хедером, т.к. он не двигается при скролле
            const scrollTarget = refs.current[Object.keys(refs.current).indexOf(incorrect.originalName) - 1] || {scroll: () => dialogRef.current.scrollTo(0, 0)}
            scrollTarget.scroll()
            return;
        }
        setLoading(true)
        post(PATH_UPDATE_PACKAGE_INFO, () => {
            closeWindow()
            refreshItems()
            toast.success('Saved')
        }, () => {
            toast.error('Error')
        }, data).finally(() => setLoading(false))
    }

    return [
        <Dialog key='dialog' open={open} onClose={closeWindow} maxWidth='lg'>
            <DialogTitle>Package Updates</DialogTitle>
            <DialogContent ref={dialogRef}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                Skip
                            </TableCell>
                            <TableCell>
                                Update
                            </TableCell>
                            {['Image (link)', 'Description (Name)', 'Cart Number', 'Carton Size', 'Net weight', 'Gross weight',
                                'Carton Quantity', 'Weight 1pcs', 'Vol 1pcs']
                                .map(it => <TableCell key={it}>{it}</TableCell>)}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {getRows().map(it => {
                            return <PackageItemRow key={it.originalName} item={() => it}
                                                   ref={ref => refs.current[it.originalName] = ref}/>
                        })}
                    </TableBody>
                </Table>
                <TablePagination component='div' count={rows.length} page={page} rowsPerPage={perPage}
                                 onRowsPerPageChange={(event) => {
                                     setPerPage(parseInt(event.target.value, 10))
                                     setPage(0)
                                 }}
                                 onPageChange={(_, page) => setPage(page)}/>
            </DialogContent>
            <DialogActions>
                <Button onClick={closeWindow}>Cancel</Button>
                <Button onClick={save}>Save</Button>
            </DialogActions>
        </Dialog>,
        <Button key='button' onClick={openWindow} endIcon={<Refresh fontSize='large'/>}
                color='primary'
                variant='contained' sx={{
            float: 'right',
            marginTop: '10px',
            marginRight: '10px'
        }}>
            Package
        </Button>
    ];
}
export default UpdatePackage
