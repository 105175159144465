import axios from 'axios'
import {getAPIKey} from "./auth/AuthContext";
import {toast} from "react-hot-toast";
import {lowerCase} from "lodash";
import i18next from "i18next";

// noinspection HttpUrlsUsage
export const backendAddress = process.env.REACT_APP_BACKEND_ADDRESS//'http://rimet-backend.com/api/'
// export const backendAddress = 'https://dev.admin.rimet.biz/api/'
export const attachmentsAddress = backendAddress.replace('/api/', '/') + 'storage/attachment/'
export const downloadAddress = backendAddress.replace('/api/', '/') + 'storage/download/'
export const patternAddress = backendAddress.replace('/api/', '/') + 'storage/attachment/pattern/'
export const downloadAttachmentAddress = attachmentsAddress + 'download.php'
export const DOWNLOAD_FILE = 'https://file.rimet.cn/' //+filename GET
export const PATH_DOWNLOAD_FILE_DIRECT = backendAddress + 'downloads/download/' //+filename or id GET

export const ROLE_ADMIN = 1;
export const ROLE_SUPER_ADMIN = 2;

const DEBUG = true
export {DEBUG}

export const PATH_GET_ALL_SPECS = 'specs' //GET
export const PATH_SAVE_SPECS = 'save-specs' //POST
export const PATH_SET_PATTERN_IMAGE = 'pattern-set-image' //POST
export const PATH_GET_ALL_SCHEMAS = 'schema' //GET
export const PATH_SCHEMA = 'schema/' //GET
export const PATH_SAVE_SCHEMAS = 'save-schemas' //POST
export const PATH_GET_SCHEMA_VALUES = 'get-schema-values/' //POST
export const PATH_SAVE_SCHEMA_VALUES = 'save-schema-values/' //POST
export const PATH_VIEW_PRODUCT_BY_CODE = 'product/code/' //GET
export const PATH_VIEW_PRODUCTS_BY_CATALOG_NUMBER = 'product/catalog-number/' //GET
export const PATH_GET_ALL_PRODUCTS = 'product' //GET
export const PATH_PRODUCT_RECOMMENDED = 'product/recommended/' //GET
export const PATH_PRODUCT_SITE_ORDER = 'product/item-cart' //GET
export const PATH_ADD_PRODUCT_ATTACHMENT = 'product/{cn}/add-attachment' //POST
export const PATH_CREATE_ATTACHMENT = 'attachment/' //POST
export const PATH_RENAME_ATTACHMENTS = 'attachment/rename' //POST
export const PATH_PRODUCT_SET_NEW = 'product/set-new' //POST
export const PATH_CATEGORIES_ALL = 'category' //GET
export const PATH_CATEGORY = 'category/' //GET
export const PATH_CATEGORIES_SET_ORDER = 'category/set-order' //POST
export const PATH_SET_PRODUCT_ATTACHMENT_ORDER = 'product/{cn}/set-attachment-order' //POST
export const PATH_REMOVE_PRODUCT_ATTACHMENT = 'product/{cn}/remove-attachment/{id}' //DELETE
export const PATH_PRODUCT_SEARCH = 'product/search?limit=50&query=' //GET
export const PATH_PRODUCT_BY_CATEGORY = 'product/category/' //GET
export const PATH_PRODUCT_BY_CATEGORY_ID = 'product/category/id/' //GET
export const PATH_IMPORT = 'product/import' //POST
export const PATH_ORDER_ADD = 'order' //POST
export const PATH_ORDER_GET_ALL = 'order/all' //GET
export const PATH_ORDER_DOWNLOAD = 'order/download/' //GET
export const PATH_ORDER_DELETE = 'order/' //DELETE
export const PATH_ORDER_MY = 'order/my' //GET
export const PATH_ORDER_UPDATE = 'order/' //PUT
export const PATH_ATTACHMENT_GET_ALL = 'attachment' //GET
export const PATH_ATTACHMENT_SHOW_DOWNLOADS = 'downloads' //GET
export const PATH_ATTACHMENT_ADD = 'attachment' //POST
export const PATH_ATTACHMENT_REMOVE = 'attachment' //DELETE
export const PATH_DOWNLOAD_REMOVE = 'downloads/' //DELETE
export const PATH_DOWNLOAD_ADD = 'downloads' //POST
export const PATH_DOWNLOAD_UPDATE = 'downloads/' //+id POST
export const PATH_GET_PACKAGE_INFO = 'print/1' //POST
export const PATH_GET_IMPORT_HISTORY = 'print/2' //GET
export const PATH_UPDATE_PACKAGE_INFO = 'product/update-package' //POST
export const PATH_IMPORT_PRICE = 'product/import-price' //POST из файла xlsx
export const PATH_SAVE_PRICE = 'product/save-price' //POST данные со страницы SupplierPrice
export const PATH_LOGIN = 'login' //POST
export const PATH_REGISTER = 'register' //POST
export const PATH_RESEND_EMAIL_VERIFICATION_CODE = 'resend-email-verification' //GET
export const PATH_SEND_PASSWORD_RESET_TOKEN = 'send-password-reset?email=' //GET
export const PATH_USER = 'user' //GET
export const PATH_USER_SET_LANGUAGE = 'user/set-lang?lang=' //PUT
export const PATH_USERS = 'users' //GET список всех пользователей
export const PATH_USERS_CREATE = 'users' //POST создать пользователя
export const PATH_USERS_UPDATE = 'users/' //POST обновить данные пользователя
export const PATH_USERS_LOGOUT = 'users/logout/' //GET отозвать все токены пользователя
export const PATH_USERS_DELETE = 'users/' //DELETE удалить пользователя
export const PATH_2FA_SECRET = 'generate_2fa_secret' //GET
export const PATH_2FA_APPLY = 'apply_2fa' //POST
export const PATH_2FA_REMOVE = 'remove_2fa' //POST
export const PATH_EXCHANGE_RATE = 'exchange-rate' //GET
export const PATH_UPDATE_EXCHANGE_RATE = 'update-exchange-rate' //GET
export const PATH_BACKUP_DATE = 'backup-date' //GET
export const PATH_MAIN_PAGE_ITEMS = 'main-page-items' //GET
export const PATH_SET_MAIN_PAGE_ITEMS = 'set-page-items' //PUT
export const PATH_GET_OLD_ORDERS = 'old-orders' //get
export const PATH_INFO_ALL = 'info' //get
export const PATH_INFO_LANG = 'info/' //get
export const PATH_INFO_SET = 'info' //post
export const PATH_SETTINGS_SET = 'settings' //post

export const googleDocsGetIdFromUrl = (url) => url.match(/[-\w]{25,}/)

export const getDownloadLink = (file) => {
    if (file.link) {
        if (file.link.includes('https://docs.google.com/document/')) {
            return 'https://docs.google.com/document/u/0/export?format=docx&id=' + googleDocsGetIdFromUrl(file.link)
        }
        if (file.link.includes('https://docs.google.com/spreadsheet')) {
            return 'https://docs.google.com/spreadsheet/u/0/export?format=xlsx&id=' + googleDocsGetIdFromUrl(file.link)
        }
        return '404'
    } else {
        return DOWNLOAD_FILE + file.name + '?download'
    }
}

export const getViewLink = (file) => {
    if (file.link) {
        return file.link
    }
    if (file.extension === 'pdf') {
        return DOWNLOAD_FILE + file.name
    }
    return null
}

export const getFileIcon = (file) => `${downloadAddress}${file.id}-icon.${file.icon}`

export const get = (path, onsuccess, onerror = () => false, data = undefined) => {
    return apiRequest(path, onsuccess, onerror, data, 'GET')
}

export const post = (path, onsuccess, onerror, data = undefined) => {
    return apiRequest(path, onsuccess, onerror, data)
}

export const deleteR = (path, onsuccess, onerror, data = undefined) => {
    return apiRequest(path, onsuccess, onerror, data, 'delete')
}

export const put = (path, onsuccess, onerror, data = undefined) => {
    return apiRequest(path, onsuccess, onerror, data, 'put')
}

export const patch = (path, onsuccess, onerror, data = undefined) => {
    return apiRequest(path, onsuccess, onerror, data, 'patch')
}

export const getErrorMessage = obj => {
    if (obj instanceof String) {
        return obj.substring(0, 10);
    }
    if ('request' in obj) {
        if (!obj.request || !obj.request.response) {
            return 'Request error'
        }
    }
    if ('response' in obj) {
        obj = obj.response
        if ('data' in obj) {
            obj = obj.data
        }
    }
    if ('message' in obj && obj.message.length > 0) {
        return obj.message
    }
    if (obj.request && obj.request.response && 'message' in obj.request.response && obj.request.response.message.length > 0) {
        return obj.request.response.message
    }
    if ('error' in obj) {
        const err = obj.error
        if (typeof err === 'object' && 'message' in err) {
            return err.message
        }
        return err
    }
    if ('exception' in obj) {
        return obj.exception
    }
    return obj.toString()
}

export const apiRequest = (path, onsuccess, onerror = (res) => {
    if (DEBUG) console.log(res)
}, data = undefined, method = 'post') => {
    const headers = {}
    if (data instanceof FormData) {
        headers['Content-Type'] = 'multipart/form-data'
    }
    if (getAPIKey() && (!path.includes(PATH_INFO_ALL) || lowerCase(method) === 'post')) {
        headers['Authorization'] = 'Bearer ' + getAPIKey()
    }

    const config = {
        method: method,
        url: backendAddress + path,
        headers: headers,
        withCredentials: false
    }
    if (path !== PATH_EXCHANGE_RATE) {
        if (data) {
            data['lang'] = i18next.language
        } else {
            data = {lang: i18next.language}
        }
    }
    if (lowerCase(method) === 'get') {
        config.params = data
    } else {
        config.data = data
    }

    return axios(config).then(response => {
        if ([200, 201, 204].includes(response.status) && !('error' in response.data)) {
            if (DEBUG) console.log(backendAddress + path + ' ' + method + ' success')
            onsuccess(response)
        } else {
            if (DEBUG) console.log(backendAddress + path + ' failed')
            onerror(response)
        }
    }).catch(e => {
        if (DEBUG) console.log(backendAddress + path + ' request failed')
        if (DEBUG) console.log(e)
        onerror(e)
    })
}

export const err = msg => {
    if (typeof msg === 'string') {
        toast.error(msg, {duration: 3000})
    }
}
