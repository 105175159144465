import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {last as lodash_last, range} from "lodash";

const Pagination = ({total, current, perPage, setPage, handleLinkClick}) => {
    const {t} = useTranslation()
    current = parseInt(current)
    let totalPages = Math.ceil(total / perPage)
    if (totalPages === Infinity) {
        totalPages = 1
    }
    const changePage = (page, e) => {
        e.preventDefault()
        if (page < 1 || page > totalPages) {
            return
        }
        setPage(page)
    }
    const pages = () => {
        let pages = []
        let start = current - 4
        if (start < 1) start = 1
        pages.push(...range(start, current))
        pages.push(...range(current, current + 4))
        let last = lodash_last(pages)
        pages.push(...range(last + 1, last + 10 - pages.length))
        pages = pages.filter(it => it <= totalPages)
        last = lodash_last(pages)
        pages = pages.map(it =>
            <Link key={`p-${it}`} to={`?page=${it}`} className={`page-1 ${it === current ? 'active' : ''}`}
                  onClick={e => {
                      handleLinkClick();
                      changePage(it, e)
                  }}>{it}</Link>
        )
        if (last < totalPages) {
            pages.push(<span key='...'>...</span>)
        }
        return pages
    }

    return <div className={"pagination col-12" + (totalPages === 1 ? " hide-pagination" : null)}>
        <div className="pagination-stack-1">
            <Link to='?page=1' onClick={e => {
                handleLinkClick();
                changePage(1, e)
            }} className="to-start">|
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                    <path
                        d="M447.1 256C447.1 273.7 433.7 288 416 288H109.3l105.4 105.4c12.5 12.5 12.5 32.75 0 45.25C208.4 444.9 200.2 448 192 448s-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L109.3 224H416C433.7 224 447.1 238.3 447.1 256z"/>
                </svg>
            </Link>
            <Link to={`?page=${current - 1 || 1}`} className="page-prev"
                  onClick={e => {
                      handleLinkClick();
                      changePage(current - 1 || 1, e)
                  }}>{t('previous')}</Link>
        </div>

        <div className="pagination-stack-2">
            {pages()}
        </div>

        <div className="pagination-stack-3">
            <Link to={`?page=${(current + 1) > totalPages ? totalPages : current + 1}`} className="page-next"
                  onClick={e => {
                      handleLinkClick();
                      changePage(current + 1, e)
                  }}>{t('next')}</Link>
            <Link to={`?page=${totalPages}`} onClick={e => {
                handleLinkClick();
                changePage(totalPages, e)
            }} className="to-end">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                    <path
                        d="M438.6 278.6l-160 160C272.4 444.9 264.2 448 256 448s-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L338.8 288H32C14.33 288 .0016 273.7 .0016 256S14.33 224 32 224h306.8l-105.4-105.4c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160C451.1 245.9 451.1 266.1 438.6 278.6z"/>
                </svg>
                |</Link>
        </div>
    </div>
}

export default Pagination
