import {useNavigate, useParams} from "react-router";
import {useEffect, useRef, useState} from "react";
import {attachmentsAddress, get, PATH_PRODUCT_BY_CATEGORY} from "../../common/backend";
import {isEmpty} from "lodash";
import {Link, useSearchParams} from "react-router-dom";
import {useLoader} from "../../common/Loader";
import Pagination from "../common/Pagination";
import Filters from "./Filters";
import {getCabinetHandleFilters, getItemImage, setLastCategory} from "../../common/commons";
import $ from "jquery"
import {useTranslation} from "react-i18next";
import {useTheme} from "../../common/ThemeProvider";
import NotFoundPage from "../common/NotFoundPage";
import {useCurrentCategory} from "../common/context/CurrentCategoryContext";

const Category = () => {
    const categoryLink = useParams().cat_link
    if (categoryLink === undefined) {
        document.title = 'Products | R!met.CN'
    }
    const [searchParams, setSearchParams] = useSearchParams()
    let page = searchParams.get('page') || 1
    if (page < 1) page = 1
    const prevCategoryLink = useRef(categoryLink)

    const {t} = useTranslation(['translation', 'category'])
    const setCurrentCategories = useCurrentCategory().setCurrent

    const {setLoading} = useLoader()
    const [items, setItems] = useState(null)

    const [perPage, setPerPage] = useState(30)

    const [total, setTotal] = useState(0)

    const [filters, setFilters] = useState({})

    const navigate = useNavigate()

    const {color} = useTheme()

    const [category, setCategory] = useState({})
    const notFound = useRef(false)

    useEffect(() => {
        if (categoryLink === '1' || categoryLink === '2') {
            return;
        }
        if (categoryLink === null || categoryLink === undefined) {
            setItems([])
            return;
        }
        if (categoryLink !== prevCategoryLink.current) {
            notFound.current = false
        }
        if (!notFound.current && categoryLink !== prevCategoryLink.current) {
            prevCategoryLink.current = categoryLink
            searchParams.set('page', '1')
        }
        if (!notFound.current) {
            loadItems()
        }
    }, [categoryLink, perPage, page])

    function handleLinkClick() {
        document.querySelector('#root').scrollIntoView({behavior: 'smooth', block: 'start'})
    }

    const loadItems = () => {
        setLoading(true)
        let params = {
            fields: ['catalog_number', 'attachments'],
            page: page,
            limit: perPage,
            require_filters: 1
        }
        if (!isEmpty(filters) || categoryLink.includes('cabinet-handle') || items === null) {
            params = $.extend(params, getCabinetHandleFilters())
        }
        get(PATH_PRODUCT_BY_CATEGORY + categoryLink + '?' + $.param(params), res => {
            document.title = t(res.data.category.name, {ns: 'category'}) + ' | R!met.CN'
            setTotal(res.data.total)
            setItems(res.data.data)
            setCategory(res.data.category)
            setLastCategory(res.data.category.factory, res.data.category.link)
            if (res.data.filters) {
                setFilters(res.data.filters)
            } else {
                setFilters({})
            }
        }, e => {
            if (e.response.status === 404) {
                notFound.current = true
            }
        }).finally(() => setLoading(false))
    }

    useEffect(() => {
        if (!!category.link) {
            if (category.parent) {
                setCurrentCategories([category.parent, category.id])
            } else {
                setCurrentCategories([category.id])
            }
        }
    }, [category.id])

    useEffect(() => {
        const hash = document.location.hash.substring(1)
        if (items === null || !items.some(it => it.catalog_number === hash)) {
            return
        }
        const target = document.querySelector(`a.product-item[href$="${hash}"]`)
        if (!target) {
            return;
        }
        target.scrollIntoView()
    }, [items])

    const bannerStyle = {}
    // if (isEmpty(category.description) || category.banner === null) {
    //     bannerStyle.padding = '30px 38px'
    // }
    bannerStyle['--clr'] = color

    if (notFound.current) {
        return <NotFoundPage/>
    }

    return <article className="col-12 col-xl-9 content">
        <div className="row">
            <div className="col-12 content-header">
                {isEmpty(filters)
                    ? <div style={bannerStyle} className="banner">
                        {category.name ? <h2 className="banner--title">{t(category.name, {ns: 'category'})}</h2> : null}
                        {category.banner ?
                            <img className="banner--image"
                                 src={`${attachmentsAddress}${category.banner.id}/full.${category.banner.extension}`}
                                 alt=""/> : null}
                        {category.description ?
                            <p className="banner--descr">{t(category.description, {ns: 'category'})}</p> : null}
                        {/*<p className="banner--descr">{t('category_banner')}</p>*/}
                        {/*<img className="banner--image" src="/images/banner.png" alt=""/>*/}
                        <label htmlFor="rpp" className="results-per-page">{t('results_per_page')}:
                            <select value={perPage} onChange={e => {
                                setPerPage(e.target.value)
                                setSearchParams({page: '1'})
                            }} name="results-per-page"
                                    id="rpp">
                                <option value="15">15</option>
                                <option value="30">30</option>
                                <option value="120">120</option>
                                <option value="0">{t('all')}</option>
                            </select>
                        </label>
                    </div>
                    : <Filters filters={filters} reload={() => {
                        setSearchParams({page: '1'})
                        loadItems()
                    }}/>}
            </div>
            <div className="products-wrap">
                {(items || []).map(it =>
                    <Link to={`/${encodeURIComponent(it.catalog_number)}`} onClick={e => {
                        e.preventDefault();
                        handleLinkClick();
                        navigate(`/${encodeURIComponent(it.catalog_number)}`);
                    }} className="product-item" key={it.catalog_number}>
                        <div className="product-item--image flex">
                            <img src={getItemImage(it)} alt="item-image"/>
                        </div>
                        <div className="product-item--info">
                            <h4 className="product-item--name">{it.catalog_number}</h4>
                        </div>
                    </Link>
                )}
            </div>
            <Pagination
                current={page}
                total={total}
                perPage={perPage}
                setPage={p => setSearchParams({page: p})}
                handleLinkClick={() => handleLinkClick()}
            />
        </div>
    </article>
}
export default Category
